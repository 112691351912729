import TableSortHeader from 'components/table/TableSortHeader';
import { CommonConstant, ProjectItemTypeNameConstant } from 'constants/code.constant';
import { ModalNameConstant } from 'constants/modal.constant';
import useModal from 'hooks/useModal';
import useProjectItem from 'hooks/useProjectItem';
import useSortTable from 'hooks/useSortTable';
import React, { useState } from 'react';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';

const headCells = [
  { label: '#', width: '5%' },
  { label: '과업명', width: '20%' },
  { label: '유형', width: '10%' },
  { label: '컨설턴트명', width: '15%' },
  { label: '참여 기업 수', width: '10%' },
  { label: '담당자명', width: '10%' },
  { label: '파일 업로드', width: '15%' },
  { label: '비고', width: '10%' },
];

const AdminProjectItemList = React.memo(({ projectId }) => {
  const { openModal } = useModal();

  const [page, setPage] = useState(1);
  const limit = 10;

  const { sortBy, sortOrder, handleRequestSort } = useSortTable();

  const { useGetProjectItemList } = useProjectItem();

  const { list: projectItemList, totalCount } = useGetProjectItemList({ page, limit, projectId });

  const handlePageChange = (value) => {
    setPage(value);
  };

  const openCalendarModal = (schedules) => {
    if (schedules.length === 0) {
      alert('일정이 없습니다');
      return;
    }

    openModal(ModalNameConstant.CALENDAR, { schedules });
  };

  return (
    <>
      <div className="page-header">과업 목록</div>
      <Link to={`/admin/project/project-item/detail?projectId=${projectId}`}>
        <button className="btn add-btn">등록</button>
      </Link>
      <br />
      <br />
      <div className="page-card">
        <div className="table-wrap">
          <p>
            총 과업 수: <strong>{totalCount}</strong>개
          </p>
          <table>
            <TableSortHeader
              sortBy={sortBy}
              sortOrder={sortOrder}
              handleRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <tbody>
              {totalCount === 0 && (
                <tr>
                  <td colSpan={headCells.length}>검색 결과가 없습니다.</td>
                </tr>
              )}
              {projectItemList.map((item, index) => (
                <tr key={index}>
                  <td>{totalCount - (page - 1) * limit - index}</td>
                  <td>{item.itemName}</td>
                  <td>{ProjectItemTypeNameConstant[item.type]}</td>
                  <td>
                    {item.consultantName}
                    {item.consultantCount > 1 ? ` (외 ${item.consultantCount - 1}명)` : ''}
                  </td>
                  <td>{item.assignedClientCount}</td>
                  <td>{item.adminName || '없음'}</td>
                  <td>{item.isFileUpload === CommonConstant.YES_NUMBER ? '가능' : '불가'}</td>
                  <td>
                    {/* <button
                      className={`btn ${item.schedule.length > 0 ? 'success-btn' : 'disabled-btn'}`}
                      onClick={() => openCalendarModal(item.schedule)}
                    >
                      일정 보기
                    </button>
                    &nbsp; &nbsp; */}
                    <Link
                      to={`/admin/project/project-item/detail?projectId=${projectId}&projectItemId=${item.projectItemId}`}
                    >
                      <button className="btn edit-btn">수정</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalCount > 0 && (
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </>
  );
});

export default AdminProjectItemList;
