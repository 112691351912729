import { useQuery } from '@tanstack/react-query';
import { CommonConstant } from 'constants/code.constant';
import ConsultingAssignRepository from 'repositories/ConsultingAssignRepository';
import CommonUtil from 'utils/common.util';

const useConsultingAssign = () => {
  const useGetConsultingAssignList = ({ projectItemId }) => {
    const getConsultingAssignList = () => {
      return ConsultingAssignRepository.getConsultingAssignList({ projectItemId })
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const consultingAssignListQuery = useQuery({
      queryKey: ['consultingAssignList', projectItemId],
      queryFn: getConsultingAssignList,
      // projectItemId가 있을 때만 쿼리 실행
      enabled: !!projectItemId,
    });

    return {
      list: consultingAssignListQuery.data?.list || [],
      totalCount: consultingAssignListQuery.data?.totalCount || 0,
      refetch: consultingAssignListQuery.refetch,
    };
  };

  /**
   * 기업 로그인 시 할당된 컨설팅 목록
   * @param {*} param0
   * @returns
   */
  const useGetClientConsultingAssignList = ({ page = 1, limit = 10, projectName, consultantName, consultingName }) => {
    const getClientConsultingAssignList = () => {
      const params = {
        offset: (page - 1) * limit,
        limit,
      };

      if (projectName) {
        params.projectName = projectName;
      }

      if (consultantName) {
        params.consultantName = consultantName;
      }

      if (consultingName) {
        params.consultingName = consultingName;
      }

      return ConsultingAssignRepository.getClientConsultingAssignList(params)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const clientConsultingAssignListQuery = useQuery({
      queryKey: ['clientConsultingAssignList', { page }],
      queryFn: getClientConsultingAssignList,
    });

    return {
      list: clientConsultingAssignListQuery.data?.list || [],
      totalCount: clientConsultingAssignListQuery.data?.totalCount || 0,
      refetch: clientConsultingAssignListQuery.refetch,
    };
  };

  const useGetConsultantConsultingAssignList = ({ page = 1, limit = 10, projectName, projectItemName }) => {
    const getConsultantConsultingAssignList = () => {
      const params = {
        offset: (page - 1) * limit,
        limit,
      };

      if (projectName) {
        params.projectName = projectName;
      }

      if (projectItemName) {
        params.projectItemName = projectItemName;
      }

      return ConsultingAssignRepository.getConsultantConsultingAssignList(params)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const consultantConsultingAssignListQuery = useQuery({
      queryKey: ['consultantConsultingAssignList', { page }],
      queryFn: getConsultantConsultingAssignList,
    });

    return {
      list: consultantConsultingAssignListQuery.data?.list || [],
      totalCount: consultantConsultingAssignListQuery.data?.totalCount || 0,
      refetch: consultantConsultingAssignListQuery.refetch,
    };
  };

  const useGetConsultingAssign = (consultantAssignId) => {
    const getConsultingAssign = () => {
      return ConsultingAssignRepository.getConsultingAssign(consultantAssignId)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const consultingAssignQuery = useQuery({
      queryKey: ['consultingAssign', consultantAssignId],
      queryFn: getConsultingAssign,
      enabled: !!consultantAssignId,
    });

    return {
      consultingAssignInfo: consultingAssignQuery.data || null,
    };
  };

  return {
    useGetConsultingAssignList,
    useGetClientConsultingAssignList,
    useGetConsultantConsultingAssignList,
    useGetConsultingAssign,
  };
};

export default useConsultingAssign;
