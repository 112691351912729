import MainLayout from 'layouts/MainLayout';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useModal from 'hooks/useModal';
import { ModalNameConstant } from 'constants/modal.constant';
import ScheduleRepository from 'repositories/ScheduleRepository';
import { CommonConstant } from 'constants/code.constant';
import CommonUtil from 'utils/common.util';
import useSchedule from 'hooks/useSchedule';
import { useEffect } from 'react';

const AdminScheduleDetail = () => {
  const navigation = useNavigate();

  const { openModal, closeModal } = useModal();

  const [searchParams] = useSearchParams();
  const scheduleId = searchParams.get('scheduleId');

  const { useGetSchedule } = useSchedule();
  const { scheduleInfo, refetch } = useGetSchedule(scheduleId);

  const defaultValues = {
    title: '',
    content: '',
    scheduleAt: [],
  };

  let schemeObject = {
    title: yup.string().trim().required('일정 제목을 입력해 주세요'),
    scheduleAt: yup.array().min(1, '일정 날짜를 선택해 주세요'),
  };

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const openScheduleAtSelectModal = () => {
    openModal(ModalNameConstant.CALENDAR, {
      selectedCalendarDates: getValues('scheduleAt'),
      selectDateCallback: scheduleAtSelectCallback,
    });
  };

  const scheduleAtSelectCallback = (dates = []) => {
    setValue('scheduleAt', dates, { shouldValidate: true });
    closeModal();
  };

  const saveSchedule = (formData) => {
    if (!window.confirm('저장하시겠습니까?')) {
      return;
    }

    ScheduleRepository.saveSchedule(formData)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('저장하였습니다');
          if (scheduleId) {
            refetch();
          } else {
            navigation(`/admin/schedule/detail?scheduleId=${res.data.scheduleId}`, { replace: true });
          }
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  const removeSchedule = () => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return;
    }

    ScheduleRepository.removeSchedule(scheduleId)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('삭제하였습니다');
          navigation(`/admin/schedule`, { replace: true });
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  useEffect(() => {
    if (scheduleInfo) {
      reset({ ...scheduleInfo, scheduleAt: [scheduleInfo.scheduleAt] });
    }
  }, [scheduleId, scheduleInfo]);

  return (
    <MainLayout>
      <div className="page-header">일정 {scheduleId ? '수정' : '등록'}</div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(saveSchedule)}>
        <div className="page-card">
          <div className="table-search-wrap detail-form-wrap">
            <Controller
              name={'title'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onBlur, value, onChange } }) => (
                <div>
                  <label>일정 제목</label>
                  <input
                    type="text"
                    placeholder="일정 제목"
                    maxLength={100}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                  />
                  {errors.title && <p className="error">{errors.title.message}</p>}
                </div>
              )}
            />
            <div>
              <label>일정 날짜</label>
              <input
                type="text"
                placeholder="클릭하여 선택"
                value={getValues('scheduleAt').join(', ')}
                readOnly
                onClick={openScheduleAtSelectModal}
              />
              {errors.scheduleAt && <p className="error">{errors.scheduleAt.message}</p>}
            </div>
            <Controller
              name={'content'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onBlur, value, onChange } }) => (
                <div>
                  <label>내용</label>
                  <textarea type="text" placeholder="내용" onBlur={onBlur} value={value} onChange={onChange} />
                </div>
              )}
            />
          </div>
        </div>
        <br />
        <div className="display-flex space-between">
          <div>
            <Link to={'/admin/schedule'}>
              <button className="btn success-btn" type="button">
                일정 목록
              </button>
            </Link>
          </div>
          <div>
            {scheduleId && (
              <>
                <button className="btn error-btn" type="button" onClick={removeSchedule}>
                  삭제
                </button>
                &nbsp;&nbsp;
              </>
            )}
            <button className="btn add-btn" type="submit">
              저장
            </button>
          </div>
        </div>
      </form>
    </MainLayout>
  );
};

export default AdminScheduleDetail;
