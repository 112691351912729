import TableSortHeader from 'components/table/TableSortHeader';
import useConsultingAssign from 'hooks/useConsultingAssign';
import useSortTable from 'hooks/useSortTable';
import MainLayout from 'layouts/MainLayout';
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';

const headCells = [
  { label: '#', width: '10%' },
  { label: '프로젝트명' },
  { label: '컨설팅명', width: '15%' },
  { label: '컨설턴트명', width: '15%' },
  { label: '예약 정보', width: '25%' },
  { label: '비고', width: '10%' },
];

const ClientConsultingList = () => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const [projectName, setProjectName] = useState('');
  const [consultantName, setConsultantName] = useState('');
  const [consultingName, setConsultingName] = useState('');

  const { sortBy, sortOrder, handleRequestSort } = useSortTable();

  const { useGetClientConsultingAssignList } = useConsultingAssign();
  const {
    list: consultings,
    totalCount,
    refetch,
  } = useGetClientConsultingAssignList({ page, limit, projectName, consultantName, consultingName });

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    refetch();
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  return (
    <MainLayout>
      <div className="page-header">컨설팅 목록</div>
      <div className="page-card">
        <form>
          <div className="table-search-wrap">
            <div>
              <label>프로젝트명</label>
              <input
                type="text"
                placeholder="프로젝트명"
                maxLength={100}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </div>
            <div>
              <label>컨설턴트명</label>
              <input
                type="text"
                placeholder="컨설팅명"
                maxLength={100}
                onChange={(e) => setConsultantName(e.target.value)}
              />
            </div>
            <div>
              <label>컨설팅명</label>
              <input
                type="text"
                placeholder="컨설팅명"
                maxLength={100}
                onChange={(e) => setConsultingName(e.target.value)}
              />
            </div>
            <div className="btn">
              <button className="btn" onClick={handleSearch}>
                검색
              </button>
            </div>
          </div>
        </form>
      </div>
      <br />
      <div className="page-card">
        <div className="table-wrap">
          <p>
            조회된 수: <strong>{totalCount}</strong>개
          </p>
          <table>
            <TableSortHeader
              sortBy={sortBy}
              sortOrder={sortOrder}
              handleRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <tbody>
              {totalCount === 0 && (
                <tr>
                  <td colSpan={headCells.length}>검색 결과가 없습니다.</td>
                </tr>
              )}
              {consultings.map((item, index) => (
                <tr key={index}>
                  <td>{totalCount - (page - 1) * limit - index}</td>
                  <td>{item.projectName}</td>
                  <td>{item.consultingName}</td>
                  <td>{item.consultantName}</td>
                  <td>
                    [전체: {item.statusPenddingCount + item.statusApprovedCount + item.statusFinishedCount}] [신청:{' '}
                    {item.statusPenddingCount}] [확정: {item.statusApprovedCount}] [완료: {item.statusFinishedCount}]
                  </td>
                  <td>
                    <Link
                      to={`/client/consulting/detail?consultantId=${item.consultantId}&consultantAssignId=${item.consultantAssignId}&consultantClientAssignId=${item.consultantClientAssignId}`}
                    >
                      <button className="btn edit-btn">상세</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalCount > 0 && (
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default ClientConsultingList;
