import TableSortHeader from 'components/table/TableSortHeader';
import { AccountStatusConstant, CommonConstant, RoleTypeNameConstant } from 'constants/code.constant';
import { format } from 'date-fns';
import useAccount from 'hooks/useAccount';
import useSortTable from 'hooks/useSortTable';
import MainLayout from 'layouts/MainLayout';
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import AccountRepository from 'repositories/AccountRepository';
import CommonUtil from 'utils/common.util';

const headCells = [
  { id: '', label: '#', width: '5%', isSort: false },
  { id: 'code', label: '고유코드', width: '10%', isSort: false },
  { id: 'company', label: '기업명 | 소속명', width: '20%', isSort: false },
  { id: 'email', label: '이메일', isSort: false },
  { id: 'name', label: '이름', width: '15%', isSort: true },
  { id: 'roleType', label: '권한', width: '10%' },
  { id: 'createdAt', label: '가입일', width: '10%' },
  { id: '', label: '비고', width: '10%' },
];

const AdminSignupRequestList = () => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const { sortBy, sortOrder, handleRequestSort } = useSortTable();

  const { useGetAccountList } = useAccount();
  const {
    list: signupRequests,
    totalCount,
    refetch,
  } = useGetAccountList({
    page,
    limit,
    sortBy,
    sortOrder,
    company,
    email,
    name,
    status: AccountStatusConstant.PENDING,
  });

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    refetch();
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handleApproval = (accountId) => {
    if (!window.confirm('승인하시겠습니까?')) {
      return;
    }

    const data = {
      accountId,
      status: AccountStatusConstant.ACTIVE,
    };

    AccountRepository.saveAccount(data)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('승인이 되었습니다');
          refetch();
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  return (
    <MainLayout>
      <div className="page-header">회원가입 신청 목록</div>
      <div className="page-card">
        <form>
          <div className="table-search-wrap">
            <div>
              <label>기업명 | 소속명</label>
              <input
                type="text"
                placeholder="기업명 | 소속명"
                maxLength={100}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>
            <div>
              <label>이메일</label>
              <input type="text" placeholder="이메일" maxLength={100} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
              <label>이름</label>
              <input type="text" placeholder="이름" maxLength={100} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="btn">
              <button className="btn" onClick={handleSearch}>
                검색
              </button>
            </div>
          </div>
        </form>
      </div>
      <br />
      <p>권한이 기업 또는 보육사인 경우 담당자 이메일, 담당자 이름으로 표시</p>
      <p>권한이 전문가 또는 파트너인 경우 전문가 이메일, 전문가 이름으로 표시</p>
      <br />
      <div className="page-card">
        <div className="table-wrap">
          <p>
            조회된 수: <strong>{totalCount}</strong>개
          </p>
          <table>
            <TableSortHeader
              sortBy={sortBy}
              sortOrder={sortOrder}
              handleRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <tbody>
              {totalCount === 0 && (
                <tr>
                  <td colSpan={headCells.length}>검색 결과가 없습니다.</td>
                </tr>
              )}
              {signupRequests.map((item, index) => (
                <tr key={index}>
                  <td>{totalCount - (page - 1) * limit - index}</td>
                  <td>{item.code}</td>
                  <td>{item.company}</td>
                  <td>{item.email}</td>
                  <td>{item.name}</td>
                  <td>{RoleTypeNameConstant[item.roleType]}</td>
                  <td>{format(item.createdAt, 'yyyy-MM-dd')}</td>
                  <td>
                    <button className="btn add-btn" onClick={() => handleApproval(item.accountId)}>
                      승인
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalCount > 0 && (
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default AdminSignupRequestList;
