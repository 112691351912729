import { CommonConstant } from 'constants/code.constant';
import useCompanyEvaluation from 'hooks/useCompanyEvaluation';
import MainLayout from 'layouts/MainLayout';
import { numToKorean } from 'num-to-korean';
import { useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import CompanyEvaluationRepository from 'repositories/CompanyEvaluationRepository';
import CommonUtil from 'utils/common.util';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels);

const MarketChart = ({ marketData }) => {
  const { unit, records } = marketData;

  const data = {
    labels: records.map((item) => `${item.label}년`),
    datasets: [
      {
        data: records.map((item) => item.data),
        backgroundColor: ['#71c4ef', '#00668c'],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'top',
        // formatter: (value) => {
        //   return `$${value}`;
        // },
      },
      legend: {
        display: false,
      },
      // tooltip: {
      //   callbacks: {
      //     label: (tooltipItem) => {
      //       const value = tooltipItem.raw;
      //       return `$${value}`;
      //     },
      //   },
      // },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: Math.max(...data.datasets[0].data) * 1.3,
        // ticks: {
        //   display: false,
        // },
      },
    },
  };

  return (
    <div className="page-card">
      <p>단위: {unit}</p>
      <br />
      <Bar data={data} options={options} />
    </div>
  );
};

const AdminCompanyEvaluation = () => {
  const selectRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [companyEvaluationInfo, setCompanyEvaluationInfo] = useState(null);

  const { useGetCompanyEvaluationList } = useCompanyEvaluation();
  const { list } = useGetCompanyEvaluationList();

  const handleEvaluateClick = () => {
    if (isLoading) {
      alert('평가 중입니다. 잠시만 기다려주세요');
      return;
    }

    const selectedCompanyEvaluationId = selectRef.current.value;

    if (!selectedCompanyEvaluationId) {
      alert('기업을 선택해 주세요');
      return;
    }

    setIsLoading(true);
    setCompanyEvaluationInfo(null);

    CompanyEvaluationRepository.getCompanyEvaluation(selectedCompanyEvaluationId)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          setCompanyEvaluationInfo(res.data);
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc)
      .finally(() => setIsLoading(false));
  };

  return (
    <MainLayout>
      <div className="page-header">기업 선택</div>
      <div className="page-card">
        <div className="table-search-wrap">
          <select ref={selectRef} disabled={isLoading}>
            <option value="">선택</option>
            {list.map((item, index) => (
              <option key={index} value={item.companyEvaluationId}>
                {item.company}
              </option>
            ))}
          </select>
          <div className="btn">
            <button onClick={handleEvaluateClick}>평가하기</button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      {isLoading && (
        <div
          className="loading"
          style={{
            textAlign: 'center',
          }}
        >
          <ClipLoader />
        </div>
      )}
      {companyEvaluationInfo && (
        <>
          <div className="company-evaluation-wrap">
            <div className="left">
              <div className="page-header">기업 정보</div>
              <div className="page-card">
                <div>
                  <label>기업명</label>
                  <p>{companyEvaluationInfo.company}</p>
                </div>
                <div>
                  <label>아이템</label>
                  <p>{companyEvaluationInfo.item}</p>
                </div>
                <div>
                  <label>사업화단계</label>
                  <p>{companyEvaluationInfo.commercializationStage}</p>
                </div>
                <div>
                  <label>분야</label>
                  <p>{companyEvaluationInfo.field}</p>
                </div>
                <div>
                  <label>연매출</label>
                  <p>{numToKorean(companyEvaluationInfo.revenueAmount, 'mixed')}원</p>
                </div>
                <div>
                  <label>투자금액</label>
                  <p>{numToKorean(companyEvaluationInfo.investmentAmount, 'mixed')}원</p>
                </div>
                {companyEvaluationInfo.certification && (
                  <div>
                    <label>인증</label>
                    <p>{companyEvaluationInfo.certification}</p>
                  </div>
                )}
                {companyEvaluationInfo.patent && (
                  <div>
                    <label>특허</label>
                    <p>{companyEvaluationInfo.patent}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="right">
              <div className="page-header">분석 정보</div>
              <div className="page-card">
                <div>
                  <label>5년 뒤 예상 기업 가치</label>
                  <p>
                    {CommonUtil.tsCommaFormat(companyEvaluationInfo.estimatedAmount)}(
                    {numToKorean(parseInt(companyEvaluationInfo.estimatedAmount), 'mixed')})원
                  </p>
                </div>
                <div className="full-grid">
                  <label>이유</label>
                  <p className="reason">{companyEvaluationInfo.reason}</p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="company-evaluation-wrap">
            <div />
            <div className="right">
              <div className="page-header">{companyEvaluationInfo.field} 국내 시장 규모 및 전망</div>
              <MarketChart marketData={companyEvaluationInfo.marketData} />
            </div>
          </div>
        </>
      )}
    </MainLayout>
  );
};

export default AdminCompanyEvaluation;
