import MainLayout from 'layouts/MainLayout';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CommonConstant, RegexConstant } from 'constants/code.constant';
import ProjectRepository from 'repositories/ProjectRepository';
import CommonUtil from 'utils/common.util';
import { ModalNameConstant } from 'constants/modal.constant';
import useModal from 'hooks/useModal';
import { useEffect } from 'react';
import useProject from 'hooks/useProject';
import { format } from 'date-fns';
import AdminProjectItemList from './project-item';

const AdminProjectDetail = () => {
  const navigation = useNavigate();

  const { openModal, closeModal } = useModal();

  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');

  const { useGetProject } = useProject();
  const { projectInfo, refetch: reloadProject } = useGetProject(projectId);

  const defaultValues = {
    name: '',
    orderer: '',
    executionYear: '',
    startAt: [],
    endAt: [],
  };

  let schemeObject = {
    name: yup.string().trim().required('프로젝트명을 입력해 주세요'),
    orderer: yup.string().trim().required('발주처를 입력해 주세요'),
    executionYear: yup
      .string()
      .trim()
      .required('수행연도를 입력해 주세요')
      .matches(RegexConstant.year, '날짜 형식이 올바르지 않습니다'),
  };

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const openStartAtSelectModal = () => {
    openModal(ModalNameConstant.CALENDAR, {
      selectedCalendarDates: getValues('startAt'),
      selectDateCallback: startAtSelectCallback,
    });
  };

  const startAtSelectCallback = (dates = []) => {
    setValue('startAt', dates, { shouldValidate: true });
    closeModal();
  };

  const openEndAtSelectModal = () => {
    openModal(ModalNameConstant.CALENDAR, {
      selectedCalendarDates: getValues('endAt'),
      selectDateCallback: endAtSelectCallback,
    });
  };

  const endAtSelectCallback = (dates = []) => {
    setValue('endAt', dates, { shouldValidate: true });
    closeModal();
  };

  const saveProject = (formData) => {
    if (!window.confirm('저장하시겠습니까?')) {
      return;
    }

    ProjectRepository.saveProject(formData)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('저장하였습니다');
          navigation(`/admin/project/detail?projectId=${res.data.projectId}`, { replace: true });
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  const removeProject = () => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return;
    }

    ProjectRepository.removeProject(projectId)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('삭제하였습니다');
          navigation(`/admin/project`, { replace: true });
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  useEffect(() => {
    if (projectInfo) {
      const startAtArray = projectInfo.startAt ? [projectInfo.startAt] : [];
      const endAtArray = projectInfo.endAt ? [projectInfo.endAt] : [];

      reset({ ...projectInfo, startAt: startAtArray, endAt: endAtArray });
    }
  }, [projectId, projectInfo]);

  return (
    <MainLayout>
      <div className="page-header">프로젝트 {projectId ? '수정' : '등록'}</div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(saveProject)}>
        <div className="page-card">
          <div className="table-search-wrap detail-form-wrap">
            <Controller
              name={'name'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onBlur, value, onChange } }) => (
                <div>
                  <label>프로젝트명</label>
                  <input
                    type="text"
                    placeholder="프로젝트명"
                    maxLength={100}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                  />
                  {errors.name && <p className="error">{errors.name.message}</p>}
                </div>
              )}
            />
            <Controller
              name={'orderer'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onBlur, value, onChange } }) => (
                <div>
                  <label>발주처</label>
                  <input
                    type="text"
                    placeholder="발주처"
                    maxLength={100}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                  />
                  {errors.orderer && <p className="error">{errors.orderer.message}</p>}
                </div>
              )}
            />
            <Controller
              name={'executionYear'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onBlur, value, onChange } }) => (
                <div>
                  <label>수행연도</label>
                  <input
                    type="text"
                    placeholder="수행연도 (yyyy 형식으로 입력)"
                    maxLength={10}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                  />
                  {errors.executionYear && <p className="error">{errors.executionYear.message}</p>}
                </div>
              )}
            />
            <div />
            <div>
              <label>시작일</label>
              <input
                type="text"
                placeholder="클릭하여 선택"
                value={getValues('startAt').join(', ')}
                readOnly
                onClick={openStartAtSelectModal}
              />
            </div>
            <div>
              <label>종료일</label>
              <input
                type="text"
                placeholder="클릭하여 선택"
                value={getValues('endAt').join(', ')}
                readOnly
                onClick={openEndAtSelectModal}
              />
            </div>
            {projectId && projectInfo && (
              <>
                <div>
                  <label>예산</label>
                  <input type="text" value={`${CommonUtil.tsCommaFormat(projectInfo.budgetAmount)}원`} disabled />
                </div>
                <div>
                  <label>등록일시</label>
                  <input type="text" value={format(projectInfo.createdAt, 'yyyy-MM-dd HH:mm:ss')} disabled />
                </div>
                <div>
                  <button
                    className="btn edit-btn"
                    type="button"
                    onClick={() => openModal(ModalNameConstant.PROJECT_BUDGET_EDIT, { projectId, reloadProject })}
                  >
                    예산 수정
                  </button>
                </div>
                <div>
                  <button
                    className="btn add-btn"
                    type="button"
                    onClick={() => openModal(ModalNameConstant.PROJECT_CLIENT_SELECT, { projectId })}
                  >
                    기업 선택
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <br />
        <div className="display-flex space-between">
          <div>
            <Link to={'/admin/project'}>
              <button className="btn success-btn" type="button">
                프로젝트 목록
              </button>
            </Link>
          </div>
          <div>
            {projectId && (
              <>
                <button className="btn error-btn" type="button" onClick={removeProject}>
                  삭제
                </button>
                &nbsp;&nbsp;
              </>
            )}
            <button className="btn add-btn" type="submit">
              저장
            </button>
          </div>
        </div>
        <br />
      </form>
      {projectId ? (
        <AdminProjectItemList projectId={projectId} />
      ) : (
        <h4>기업 선택, 과업 등록, 예산수정은 프로젝트 등록 이후에 가능합니다</h4>
      )}
    </MainLayout>
  );
};

export default AdminProjectDetail;
