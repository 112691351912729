import { useCallback, useState } from 'react';

const useSortTable = () => {
  const [sortBy, setSortBy] = useState('id');
  const [sortOrder, setSortOrder] = useState('desc');

  const handleRequestSort = useCallback(
    (property) => {
      const isDesc = sortBy === property && sortOrder === 'desc';

      setSortOrder(isDesc ? 'asc' : 'desc');
      setSortBy(property);
    },
    [sortBy, sortOrder],
  );

  return { sortBy, sortOrder, handleRequestSort };
};

export default useSortTable;
