import { API_RESERVATION_TIME_SLOT_ROUTE } from 'constants/api.constant';
import AxiosUtil from 'utils/axios.util';

const ReservationTimeSlotRepository = {
  getReservationTimeSlotList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_RESERVATION_TIME_SLOT_ROUTE}/list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  /**
   * 컨설턴트 예약 시간대 목록 조회
   * @param {*} data
   * @returns
   */
  getClientReservationTimeSlotList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_RESERVATION_TIME_SLOT_ROUTE}/client/list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  saveReservationTimeSlot(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_RESERVATION_TIME_SLOT_ROUTE}`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default ReservationTimeSlotRepository;
