import axios from 'axios';
import queryString from 'query-string';
import cookie from 'react-cookies';
import { CommonConstant, TokenConstant } from 'constants/code.constant';
import { ErrorCodeConstant } from 'constants/errorCode.constant';
import AuthRepository from 'repositories/AuthRepository';

const commonErrorAction = (error, errorCallback) => {
  const data = error?.response?.data;

  if (!data) {
    console.error('commonErrorAction', error);
  }

  return errorCallback(data);
};

const baseHeader = {
  'Content-Type': 'application/json',
};

const removeCookie = () => {
  if (cookie.load(TokenConstant.ACCESS_TOKEN_COOKIE) || cookie.load(TokenConstant.REFRESH_TOKEN_COOKIE)) {
    alert('다시 로그인해 주세요');
  }

  cookie.remove(TokenConstant.ACCESS_TOKEN_COOKIE, { path: '/' });
  cookie.remove(TokenConstant.REFRESH_TOKEN_COOKIE, { path: '/' });

  window.location.href = '/login';
};

const AxiosUtil = {
  get: ({ url, submitData }, successCallback, errorCallback) => {
    const qs = queryString.stringify(submitData);
    const newUrl = url + (qs ? `?${qs}` : '');

    const axiosGetSubmit = (newUrl) => {
      return axios
        .get(newUrl, {
          headers: {
            ...baseHeader,
            [TokenConstant.ACCESS_TOKEN_HEADER_KEY]: cookie.load(TokenConstant.ACCESS_TOKEN_COOKIE) || '',
            [TokenConstant.REFRESH_TOKEN_HEADER_KEY]: cookie.load(TokenConstant.REFRESH_TOKEN_COOKIE) || '',
          },
          withCredentials: true,
        })
        .then(async ({ data }) => {
          if (data.result === CommonConstant.ERROR) {
            // 엑세스 토큰의 만료됨
            if (data.errorCode === ErrorCodeConstant.ERROR_CODE.TOKEN_EXPIRED) {
              try {
                // 엑세스 토큰 재발급
                await renewRefreshToken();
                return axiosGetSubmit(newUrl);
              } catch (err) {
                console.error(err);
              }
            }

            // 토큰이 유효하지 않음
            if (data.errorCode === ErrorCodeConstant.ERROR_CODE.INVALID_TOKEN) {
              removeCookie();
            }
          }
          return successCallback(data);
        })
        .catch((error) => {
          return commonErrorAction(error, errorCallback);
        });
    };

    return axiosGetSubmit(newUrl);
  },

  post: ({ url, submitData }, successCallback, errorCallback) => {
    const axiosPostSubmit = (url, submitData) => {
      return axios
        .post(url, submitData, {
          headers: {
            ...baseHeader,
            [TokenConstant.ACCESS_TOKEN_HEADER_KEY]: cookie.load(TokenConstant.ACCESS_TOKEN_COOKIE) || '',
            [TokenConstant.REFRESH_TOKEN_HEADER_KEY]: cookie.load(TokenConstant.REFRESH_TOKEN_COOKIE) || '',
          },
          withCredentials: true,
        })
        .then(async ({ data }) => {
          if (data.result === CommonConstant.ERROR) {
            // 엑세스 토큰의 만료됨
            if (data.errorCode === ErrorCodeConstant.ERROR_CODE.TOKEN_EXPIRED) {
              try {
                await renewRefreshToken();
                return axiosPostSubmit(url, submitData);
              } catch (err) {
                console.error(err);
              }
            }

            // 토큰이 유효하지 않음
            if (data.errorCode === ErrorCodeConstant.ERROR_CODE.INVALID_TOKEN) {
              removeCookie();
            }
          }
          return successCallback(data);
        })
        .catch((error) => {
          return commonErrorAction(error, errorCallback);
        });
    };

    return axiosPostSubmit(url, submitData);
  },

  put: ({ url, submitData }, successCallback, errorCallback, from) => {
    const axiosPutSubmit = (url, submitData) => {
      return axios
        .put(url, submitData, {
          headers: {
            ...baseHeader,
            [TokenConstant.ACCESS_TOKEN_HEADER_KEY]: cookie.load(TokenConstant.ACCESS_TOKEN_COOKIE) || '',
            [TokenConstant.REFRESH_TOKEN_HEADER_KEY]: cookie.load(TokenConstant.REFRESH_TOKEN_COOKIE) || '',
          },
          withCredentials: true,
        })
        .then(async ({ data }) => {
          if (data.result === CommonConstant.ERROR) {
            if (from === 'renew') {
              removeCookie();
            } else {
              // 엑세스 토큰의 만료됨
              if (data.errorCode === ErrorCodeConstant.ERROR_CODE.TOKEN_EXPIRED) {
                try {
                  await renewRefreshToken();
                  return axiosPutSubmit(url, submitData);
                } catch (err) {
                  console.error(err);
                }
              }

              // 토큰이 유효하지 않음
              if (data.errorCode === ErrorCodeConstant.ERROR_CODE.INVALID_TOKEN) {
                removeCookie();
              }
            }
          }
          return successCallback(data);
        })
        .catch((error) => {
          return commonErrorAction(error, errorCallback);
        });
    };

    return axiosPutSubmit(url, submitData);
  },

  delete: ({ url, submitData }, successCallback, errorCallback) => {
    const axiosDeleteSubmit = (url, submitData) => {
      return axios
        .delete(url, {
          submitData,
          headers: {
            ...baseHeader,
            [TokenConstant.ACCESS_TOKEN_HEADER_KEY]: cookie.load(TokenConstant.ACCESS_TOKEN_COOKIE) || '',
            [TokenConstant.REFRESH_TOKEN_HEADER_KEY]: cookie.load(TokenConstant.REFRESH_TOKEN_COOKIE) || '',
          },
          withCredentials: true,
        })
        .then(async ({ data }) => {
          if (data.result === CommonConstant.ERROR) {
            // 엑세스 토큰의 만료됨
            if (data.errorCode === ErrorCodeConstant.ERROR_CODE.TOKEN_EXPIRED) {
              try {
                await renewRefreshToken();
                return axiosDeleteSubmit(url, submitData);
              } catch (err) {
                console.error(err);
              }
            }

            // 토큰이 유효하지 않음
            if (data.errorCode === ErrorCodeConstant.ERROR_CODE.INVALID_TOKEN) {
              removeCookie();
            }
          }
          return successCallback(data);
        })
        .catch((error) => {
          return commonErrorAction(error, errorCallback);
        });
    };

    return axiosDeleteSubmit(url, submitData);
  },

  mixedDataUpload: ({ url, submitData }, successCallback, errorCallback) => {
    const formData = new FormData();

    for (let key in submitData) {
      formData.append(key, submitData[key]);
    }

    const axiosUploadSubmit = (url, formData) => {
      return axios
        .post(url, formData, {
          headers: {
            ...baseHeader,
            [TokenConstant.ACCESS_TOKEN_HEADER_KEY]: cookie.load(TokenConstant.ACCESS_TOKEN_COOKIE) || '',
            [TokenConstant.REFRESH_TOKEN_HEADER_KEY]: cookie.load(TokenConstant.REFRESH_TOKEN_COOKIE) || '',
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        })
        .then(async ({ data }) => {
          if (data.result === CommonConstant.ERROR) {
            // 엑세스 토큰의 만료됨
            if (data.errorCode === ErrorCodeConstant.ERROR_CODE.TOKEN_EXPIRED) {
              try {
                await renewRefreshToken();
                return axiosUploadSubmit(url, submitData);
              } catch (err) {
                console.error(err);
              }
            }

            // 토큰이 유효하지 않음
            if (data.errorCode === ErrorCodeConstant.ERROR_CODE.INVALID_TOKEN) {
              removeCookie();
            }
          }
          return successCallback(data);
        })
        .catch((error) => {
          return commonErrorAction(error, errorCallback);
        });
    };

    return axiosUploadSubmit(url, formData);
  },

  multipartFormData: ({ url, submitData }, successCallback, errorCallback) => {
    const axiosUploadSubmit = (url, formData) => {
      return axios
        .post(url, formData, {
          headers: {
            ...baseHeader,
            [TokenConstant.ACCESS_TOKEN_HEADER_KEY]: cookie.load(TokenConstant.ACCESS_TOKEN_COOKIE) || '',
            [TokenConstant.REFRESH_TOKEN_HEADER_KEY]: cookie.load(TokenConstant.REFRESH_TOKEN_COOKIE) || '',
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        })
        .then(async ({ data }) => {
          if (data.result === CommonConstant.ERROR) {
            // 엑세스 토큰의 만료됨
            if (data.errorCode === ErrorCodeConstant.ERROR_CODE.TOKEN_EXPIRED) {
              try {
                await renewRefreshToken();
                return axiosUploadSubmit(url, submitData);
              } catch (err) {
                console.error(err);
              }
            }

            // 토큰이 유효하지 않음
            if (data.errorCode === ErrorCodeConstant.ERROR_CODE.INVALID_TOKEN) {
              removeCookie();
            }
          }
          return successCallback(data);
        })
        .catch((error) => {
          return commonErrorAction(error, errorCallback);
        });
    };

    return axiosUploadSubmit(url, submitData);
  },
};

const renewRefreshToken = () => {
  return new Promise(async (resolve, reject) => {
    if (!cookie.load(TokenConstant.REFRESH_TOKEN_COOKIE)) {
      window.location.href = '/login';
    } else {
      const res = await AuthRepository.renewRefreshToken();
      if (res) {
        const data = res.data;
        // console.log('call axios refreshToken', data);
        if (res.result === CommonConstant.OK) {
          cookie.save(TokenConstant.ACCESS_TOKEN_COOKIE, data.accessToken, {
            path: '/',
          });
          resolve(res);
        } else {
          removeCookie();
        }
      } else {
        reject('refresh token response error');
      }
    }
  });
};

export default AxiosUtil;
