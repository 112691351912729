import TableSortHeader from 'components/table/TableSortHeader';
import { CommonConstant } from 'constants/code.constant';
import useProjectClientSelect from 'hooks/useProjectClientSelect';
import useSortTable from 'hooks/useSortTable';
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import ProjectClientSelectRepository from 'repositories/ProjectClientSelectRepository';
import CommonUtil from 'utils/common.util';

const headCells = [
  { label: '#', width: '10%' },
  { label: '기업명' },
  { label: '대표자명', width: '15%' },
  { label: '담당자명', width: '15%' },
  { label: '비고', width: '10%' },
];

const ProjectClientSelectModal = ({ projectId }) => {
  const [company, setCompany] = useState('');
  const [ceoName, setCeoName] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [page, setPage] = useState(1);
  const limit = 10;

  const { sortBy, sortOrder, handleRequestSort } = useSortTable();

  const { useGetProjectClientSelectList } = useProjectClientSelect();

  const {
    list: clients,
    totalCount,
    refetch,
  } = useGetProjectClientSelectList({
    page,
    limit,
    projectId,
    company,
    ceoName,
    name,
    type,
  });

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    refetch();
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  const addProjectClientSelect = (accountId) => {
    const data = {
      accountId,
      projectId,
    };

    ProjectClientSelectRepository.addProjectClientSelect(data)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          refetch();
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  const removeProjectClientSelect = (projectClientSelectId) => {
    ProjectClientSelectRepository.removeProjectClientSelect(projectClientSelectId)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          refetch();
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  return (
    <div className="project-client-select-modal">
      <p className="title">기업 선택</p>
      <form>
        <div className="table-search-wrap">
          <div>
            <label>기업명</label>
            <input type="text" placeholder="기업명" maxLength={100} onChange={(e) => setCompany(e.target.value)} />
          </div>
          <div>
            <label>대표자명</label>
            <input type="text" placeholder="대표자명" maxLength={100} onChange={(e) => setCeoName(e.target.value)} />
          </div>
          <div>
            <label>담당자명</label>
            <input type="text" placeholder="담당자명" maxLength={100} onChange={(e) => setName(e.target.value)} />
          </div>
          <div>
            <label>유형</label>
            <select onChange={(e) => setType(e.target.value)}>
              <option value={''}>전체</option>
              <option value={'selected'}>선택됨</option>
              <option value={'notSelected'}>선택되지 않음</option>
            </select>
          </div>
          <div className="btn text-left">
            <button className="btn" onClick={handleSearch}>
              검색
            </button>
          </div>
        </div>
      </form>
      <br />
      <div className="table-wrap">
        <p className="text-left">
          조회된 수: <strong>{totalCount}</strong>개
        </p>
        <table>
          <TableSortHeader
            sortBy={sortBy}
            sortOrder={sortOrder}
            handleRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <tbody>
            {totalCount === 0 && (
              <tr>
                <td colSpan={headCells.length}>검색 결과가 없습니다.</td>
              </tr>
            )}
            {clients.map((item, index) => (
              <tr key={index}>
                <td>{totalCount - (page - 1) * limit - index}</td>
                <td>{item.company}</td>
                <td>{item.ceoName}</td>
                <td>{item.name}</td>
                <td>
                  {item.isSelected ? (
                    <button
                      className="btn error-btn"
                      onClick={() => removeProjectClientSelect(item.projectClientSelectId)}
                    >
                      해제
                    </button>
                  ) : (
                    <button className="btn add-btn" onClick={() => addProjectClientSelect(item.accountId)}>
                      추가
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {totalCount > 0 && (
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={totalCount}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectClientSelectModal;
