import AdminDashboard from 'pages/admin/dashboard';

import AdminList from 'pages/admin/admin-management';
import AdminDetail from 'pages/admin/admin-management/detail';

import AdminProjectList from 'pages/admin/project';
import AdminProjectDetail from 'pages/admin/project/detail';
import AdminProjectItemDetail from 'pages/admin/project/project-item/detail';
import AdminConsultantList from 'pages/admin/consultant-management';
import AdminClientList from 'pages/admin/client-management';
import AdminSignupRequestList from 'pages/admin/signup-request-management';
import AdminConsultingReportList from 'pages/admin/consulting-report';
import AdminConsultingReportDetail from 'pages/admin/consulting-report/detail';
import AdminSchedule from 'pages/admin/schedule';
import AdminScheduleDetail from 'pages/admin/schedule/detail';
import AdminIRDeckGenerate from 'pages/admin/ir-deck-generate';
import AdminCompanyEvaluation from 'pages/admin/company-evaluation';

export const AdminRoute = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    element: <AdminDashboard />,
  },
  {
    sectionTitle: '프로젝트 관리',
  },
  {
    path: '/project',
    title: '프로젝트',
    element: <AdminProjectList />,
  },
  {
    path: '/project/detail',
    element: <AdminProjectDetail />,
  },
  {
    path: '/project/project-item/detail',
    element: <AdminProjectItemDetail />,
  },
  {
    path: '/consulting-report',
    title: '컨설팅 보고서',
    element: <AdminConsultingReportList />,
  },
  {
    path: '/consulting-report/detail',
    element: <AdminConsultingReportDetail />,
  },
  {
    sectionTitle: '일정 관리',
  },
  {
    path: '/schedule',
    title: '일정',
    element: <AdminSchedule />,
  },
  {
    path: '/schedule/detail',
    element: <AdminScheduleDetail />,
  },
  {
    sectionTitle: '회원 관리',
  },
  {
    path: '/client',
    title: '기업',
    element: <AdminClientList />,
  },
  {
    path: '/consultant',
    title: '컨설턴트',
    element: <AdminConsultantList />,
  },
  {
    path: '/signup-request',
    title: '회원가입 신청',
    element: <AdminSignupRequestList />,
  },
  {
    sectionTitle: '관리자 관리',
  },
  {
    path: '/admin',
    title: '관리자',
    element: <AdminList />,
  },
  {
    path: '/admin/detail',
    element: <AdminDetail />,
  },
  {
    sectionTitle: 'AI',
  },
  {
    path: '/ir-deck-generate',
    title: 'IR 덱 생성',
    element: <AdminIRDeckGenerate />,
  },
  {
    path: '/company-evaluation',
    title: '기업 가치 평가',
    element: <AdminCompanyEvaluation />,
  },
];
