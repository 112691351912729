import Calendar from 'components/calendar';
import ClientReservationTimeSlot from 'components/reservation/ClientReservationTimeSlot';
import TableSortHeader from 'components/table/TableSortHeader';
import { ReservationStatusConstant, ReservationStatusNameConstant } from 'constants/code.constant';
import { format } from 'date-fns';
import useConsultingAssign from 'hooks/useConsultingAssign';
import useReservation from 'hooks/useReservation';
import useReservationTimeSlot from 'hooks/useReservationTimeSlot';
import useSortTable from 'hooks/useSortTable';
import MainLayout from 'layouts/MainLayout';
import React, { useState } from 'react';
import Pagination from 'react-js-pagination';
import { useSearchParams } from 'react-router-dom';

const headCells = [
  { label: '#', width: '5%' },
  { label: '예약 일자', width: '15%' },
  { label: '예약 시간', width: '15%' },
  { label: '총 시간', width: '10%' },
  { label: '상태', width: '10%' },
  { label: '등록일', width: '10%' },
  { label: '비고', width: '10%' },
];

const ReservationListTable = React.memo(({ consultantClientAssignId, successCallback }) => {
  const [page, setPage] = useState(1);
  const limit = 10;

  const { sortBy, sortOrder, handleRequestSort } = useSortTable();

  const { useGetReservationList, changeReservationStatus } = useReservation();
  const { list: reservations, totalCount } = useGetReservationList({ page, limit, consultantClientAssignId });

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handleReservationCancel = (reservationId, status) => {
    if (status !== ReservationStatusConstant.PENDING) {
      alert('예약 취소는 상태가 신청 중일 때만 가능합니다 예약 취소를 하려면 컨설턴트에게 문의하세요');
      return;
    }

    if (!window.confirm('취소하시겠습니까?')) {
      return;
    }

    changeReservationStatus({
      reservationId,
      status: ReservationStatusConstant.CANCELLED,
      changeSuccessCallback: () => {
        successCallback();
        alert('취소되었습니다');
      },
    });
  };

  return (
    <>
      <div className="page-header">컨설팅 예약 정보</div>
      <div className="page-card">
        <div className="table-wrap">
          <p>
            조회된 수: <strong>{totalCount}</strong>개
          </p>
          <table>
            <TableSortHeader
              sortBy={sortBy}
              sortOrder={sortOrder}
              handleRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <tbody>
              {totalCount === 0 && (
                <tr>
                  <td colSpan={headCells.length}>검색 결과가 없습니다.</td>
                </tr>
              )}
              {reservations.map((item, index) => (
                <tr key={index}>
                  <td>{totalCount - (page - 1) * limit - index}</td>
                  <td>{item.date}</td>
                  <td>{item.time}</td>
                  <td>{item.totalTime}</td>
                  <td>{ReservationStatusNameConstant[item.status]}</td>
                  <td>{format(item.createdAt, 'yyyy-MM-dd')}</td>
                  <td>
                    <button
                      className="btn error-btn"
                      onClick={() => handleReservationCancel(item.reservationId, item.status)}
                    >
                      예약 취소
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalCount > 0 && (
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </>
  );
});

const ClientConsultingDetail = () => {
  const [searchParams] = useSearchParams();
  const consultantId = searchParams.get('consultantId');
  const consultantAssignId = searchParams.get('consultantAssignId');
  const consultantClientAssignId = searchParams.get('consultantClientAssignId');

  const nowDate = format(new Date(), 'yyyy-MM-dd');

  const [selectedDate, setSelectedDate] = useState(nowDate);

  const handleSelectDateCallback = (date) => {
    setSelectedDate(date.join());
  };

  const { useGetConsultingAssign } = useConsultingAssign();
  const { consultingAssignInfo } = useGetConsultingAssign(consultantAssignId);

  const { useGetClientReservationTimeSlotList } = useReservationTimeSlot();
  const { list: clientReservationTimeSlots, refetch: refetchTimeSlot } = useGetClientReservationTimeSlotList({
    consultantId,
    selectedDate,
  });

  const { useGetReservationList } = useReservation();
  const { refetch: refetchReservation } = useGetReservationList({ consultantClientAssignId });

  const successCallback = () => {
    refetchReservation();
    refetchTimeSlot();
  };

  return (
    <MainLayout>
      <div className="page-header">컨설팅 정보</div>
      <div className="page-card">
        <div className="table-search-wrap detail-form-wrap">
          <div>
            <label>컨설팅명</label>
            <input type="text" value={consultingAssignInfo?.consultingName || ''} disabled />
          </div>
          <div>
            <label>컨설턴트명</label>
            <input type="text" value={consultingAssignInfo?.consultantName || ''} disabled />
          </div>
        </div>
      </div>
      <br />
      <p>예약 정보는 아래에서 확인이 가능합니다</p>
      <br />
      <div className="page-card">
        <div className="consultant-schedule-wrap">
          <div
            style={{
              width: '65%',
            }}
          >
            <Calendar selectDateCallback={handleSelectDateCallback} selectedCalendarDates={[selectedDate]} />
          </div>
          <ClientReservationTimeSlot
            selectedDate={selectedDate}
            clientReservationTimeSlots={clientReservationTimeSlots}
            consultantAssignId={consultantAssignId}
            consultantClientAssignId={consultantClientAssignId}
            successCallback={successCallback}
          />
        </div>
      </div>
      <br />
      <br />
      <ReservationListTable consultantClientAssignId={consultantClientAssignId} successCallback={successCallback} />
    </MainLayout>
  );
};

export default ClientConsultingDetail;
