import { useQuery } from '@tanstack/react-query';
import { CommonConstant } from 'constants/code.constant';
import EventAssignRepository from 'repositories/EventAssignRepository';
import CommonUtil from 'utils/common.util';

const useEventAssign = () => {
  const useGetEventAssignList = ({ projectItemId }) => {
    const getEventAssignList = () => {
      return EventAssignRepository.getEventAssignList({ projectItemId })
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const eventAssignListQuery = useQuery({
      queryKey: ['eventAssignList', projectItemId],
      queryFn: getEventAssignList,
      // projectItemId가 있을 때만 쿼리 실행
      enabled: !!projectItemId,
    });

    return {
      list: eventAssignListQuery.data?.list || [],
      totalCount: eventAssignListQuery.data?.totalCount || 0,
      refetch: eventAssignListQuery.refetch,
    };
  };

  const useGetMyEventList = ({ page = 1, limit = 10, eventName, place }) => {
    const getMyEventList = () => {
      const params = {
        offset: (page - 1) * limit,
        limit,
      };

      if (eventName) {
        params.eventName = eventName;
      }

      if (place) {
        params.place = place;
      }

      return EventAssignRepository.getMyEventList(params)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const myEventListQuery = useQuery({
      queryKey: ['myEventList', { page }],
      queryFn: getMyEventList,
    });

    return {
      list: myEventListQuery.data?.list || [],
      totalCount: myEventListQuery.data?.totalCount || 0,
      refetch: myEventListQuery.refetch,
    };
  };

  return { useGetEventAssignList, useGetMyEventList };
};

export default useEventAssign;
