import CalenderHeader from 'components/calendar/CalenderHeader';
import AdminScheduleCalendarBody from './CalendarBody';
import React, { useEffect, useState } from 'react';
import { addMonths, subMonths, format, getMonth, getYear, setMonth, setYear } from 'date-fns';
import { getCalendarDate } from 'components/calendar';

const AdminScheduleCalendar = React.memo(({ handleSelectDateCallback, schedules = [] }) => {
  const nowDate = new Date();

  const [currentDate, setCurrentDate] = useState(nowDate);
  const [days, setDays] = useState([]);

  const handleToday = () => {
    setCurrentDate(nowDate);
  };
  const handlePreviousMonth = () => setCurrentDate(subMonths(currentDate, 1));
  const handleNextMonth = () => setCurrentDate(addMonths(currentDate, 1));
  const handleYearChange = (year) => setCurrentDate(setYear(currentDate, year));
  const handleMonthChange = (month) => setCurrentDate(setMonth(currentDate, month));

  useEffect(() => {
    setDays(getCalendarDate(getYear(currentDate), getMonth(currentDate) + 1));
    handleSelectDateCallback(format(currentDate, 'yyyy-MM-dd'));
  }, [currentDate]);

  return (
    <div className="calender-wrap">
      <CalenderHeader
        currentYear={getYear(currentDate)}
        currentMonth={getMonth(currentDate)}
        handleToday={handleToday}
        handlePreviousMonth={handlePreviousMonth}
        handleNextMonth={handleNextMonth}
        handleYearChange={handleYearChange}
        handleMonthChange={handleMonthChange}
      />
      <AdminScheduleCalendarBody days={days} nowDate={nowDate} schedules={schedules} />
    </div>
  );
});

export default AdminScheduleCalendar;
