import { useQuery } from '@tanstack/react-query';
import { CommonConstant } from 'constants/code.constant';
import ProjectClientSelectRepository from 'repositories/ProjectClientSelectRepository';
import CommonUtil from 'utils/common.util';

const useProjectClientSelect = () => {
  const useGetProjectClientSelectList = ({ page = 1, limit = 10, projectId, company, ceoName, name, type }) => {
    const getProjectClientSelectList = () => {
      const params = {
        offset: (page - 1) * limit,
        limit,
        projectId,
        company,
        ceoName,
        name,
        type,
      };

      return ProjectClientSelectRepository.getProjectClientSelectList(params)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const projectClientSelectListQuery = useQuery({
      queryKey: ['projectClientSelectList', { projectId, page }],
      queryFn: getProjectClientSelectList,
      enabled: !!projectId,
    });

    return {
      list: projectClientSelectListQuery.data?.list || [],
      totalCount: projectClientSelectListQuery.data?.totalCount || 0,
      refetch: projectClientSelectListQuery.refetch,
    };
  };

  return { useGetProjectClientSelectList };
};

export default useProjectClientSelect;
