import { format } from 'date-fns';
import React from 'react';

const weekNames = ['일', '월', '화', '수', '목', '금', '토'];

const isSameDate = (date1, date2) => {
  return format(date1, 'yyyy-MM-dd') === format(date2, 'yyyy-MM-dd');
};

const RenderSchedule = React.memo(({ scheduleFilter }) => {
  return (
    <div>
      {scheduleFilter.map((item, index) => (
        <p key={index} className="schedule-title">
          {item.title}
        </p>
      ))}
    </div>
  );
});

const CalendarBody = React.memo(({ days = [], nowDate, selectedDates = [], schedules = [], handleSelectData }) => {
  const dayClassName = ({ date, disabled }) => {
    let resultClassName = '';

    if (selectedDates.includes(date)) {
      resultClassName = ' selected';
    }

    if (isSameDate(nowDate, date)) {
      resultClassName += ' now';
    }

    if (disabled) {
      resultClassName += ' disabled';
    }

    return resultClassName;
  };

  return (
    <div className="calender-body">
      <div className="week-name unselectable">
        {weekNames.map((day) => (
          <p key={day}>{day}</p>
        ))}
      </div>
      <div className="day">
        {days.map((item, index) => (
          <div
            key={index}
            className={`item${dayClassName(item)}`}
            onClick={() => handleSelectData && handleSelectData(item.date)}
          >
            <p>{item.day}</p>
            <RenderSchedule scheduleFilter={schedules.filter((schedule) => schedule['date'] === item.date)} />
          </div>
        ))}
      </div>
    </div>
  );
});

export default CalendarBody;
