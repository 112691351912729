import axios from 'axios';
import { saveAs } from 'file-saver';
import { RegexConstant } from 'constants/code.constant';

const CommonUtil = {
  repoErrFunc: (err) => {
    console.error(err);
    alert('서버 통신 중 오류가 발생했습니다');
  },

  tsCommaFormat: (value) => {
    return value ? value.toString().replace(RegexConstant.thousandsComma, ',') : 0;
  },

  fileDownload: (url, fileName) => {
    axios
      .get(url + '?' + new Date().toTimeString, {
        responseType: 'blob',
      })
      .then((res) => {
        const blob = res.data;

        saveAs(blob, fileName);
      })
      .catch((err) => {
        console.error(err.message);
        alert('파일 다운로드 중 오류가 발생했습니다');
      });
  },
};

export default CommonUtil;
