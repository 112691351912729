import { API_HELP_ROUTE } from 'constants/api.constant';
import AxiosUtil from 'utils/axios.util';

const HelpRepository = {
  findEmail(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_HELP_ROUTE}/find-email`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  sendResetPasswordCode(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_HELP_ROUTE}/send-reset-password-code`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  verifyResetPasswordCode(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_HELP_ROUTE}/verify-reset-password-code`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  resetPassword(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_HELP_ROUTE}/reset-password`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default HelpRepository;
