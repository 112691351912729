import { API_PROJECT_ITEM_ROUTE } from 'constants/api.constant';
import AxiosUtil from 'utils/axios.util';

const ProjectItemRepository = {
  getProjectItemList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_PROJECT_ITEM_ROUTE}/list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  getProjectItem(projectItemId) {
    try {
      return AxiosUtil.get(
        { url: `${API_PROJECT_ITEM_ROUTE}/${projectItemId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  saveProjectItem(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_PROJECT_ITEM_ROUTE}`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  removeProjectItem(projectItemId) {
    try {
      return AxiosUtil.delete(
        { url: `${API_PROJECT_ITEM_ROUTE}/${projectItemId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default ProjectItemRepository;
