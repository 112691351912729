import { useQuery } from '@tanstack/react-query';
import { CommonConstant } from 'constants/code.constant';
import ReservationTimeSlotRepository from 'repositories/ReservationTimeSlotRepository';
import CommonUtil from 'utils/common.util';

const useReservationTimeSlot = () => {
  const useGetReservationTimeSlotList = ({ selectedDate }) => {
    const getReservationTimeSlotList = () => {
      return ReservationTimeSlotRepository.getReservationTimeSlotList({ selectedDate })
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const reservationTimeSlotListQuery = useQuery({
      queryKey: ['reservationTimeSlotList', selectedDate],
      queryFn: getReservationTimeSlotList,
      enabled: !!selectedDate,
    });

    return {
      list: reservationTimeSlotListQuery.data?.list || [],
      schedules: reservationTimeSlotListQuery.data?.schedules || [],
      totalCount: reservationTimeSlotListQuery.data?.totalCount || 0,
      refetch: reservationTimeSlotListQuery.refetch,
    };
  };

  const useGetClientReservationTimeSlotList = ({ consultantId, selectedDate }) => {
    const clientReservationTimeSlotList = () => {
      return ReservationTimeSlotRepository.getClientReservationTimeSlotList({ consultantId, selectedDate })
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const clientReservationTimeSlotListQuery = useQuery({
      queryKey: ['clientReservationTimeSlotList', { consultantId, selectedDate }],
      queryFn: clientReservationTimeSlotList,
      enabled: !!consultantId && !!selectedDate,
    });

    return {
      list: clientReservationTimeSlotListQuery.data?.list || [],
      totalCount: clientReservationTimeSlotListQuery.data?.totalCount || 0,
      refetch: clientReservationTimeSlotListQuery.refetch,
    };
  };

  return { useGetReservationTimeSlotList, useGetClientReservationTimeSlotList };
};

export default useReservationTimeSlot;
