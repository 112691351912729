import { CommonConstant, FileUploadKey } from 'constants/code.constant';
import MainLayout from 'layouts/MainLayout';
import CustomEditor from 'components/editor';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useModal from 'hooks/useModal';
import { ModalNameConstant } from 'constants/modal.constant';
import useReservation from 'hooks/useReservation';
import React, { useEffect, useRef, useState } from 'react';
import ConsultingReportRepository from 'repositories/ConsultingReportRepository';
import CommonUtil from 'utils/common.util';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useConsultingReport from 'hooks/useConsultingReport';
import parse from 'html-react-parser';

const FILE_MAX_SIZE = 1024 * 1024 * 50;

export const FileUploadButton = React.memo(({ className, label, accept = '', disabled, onChange }) => {
  const fileRef = useRef(null);

  const handleButtonClick = () => {
    if (disabled) {
      // alert('제출이 완료된 상태입니다. 수정이 필요한 경우 관리자에게 문의해 주세요');
      return;
    }

    fileRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    e.target.value = '';

    if (!file) return;

    if (file.size > FILE_MAX_SIZE) {
      alert('최대 파일 크기 50MB');

      return;
    }

    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => onChange(file, reader.result);
      reader.readAsDataURL(file);
    } else {
      onChange(file);
    }
  };

  return (
    <>
      <button className={`btn ${className}`} onClick={handleButtonClick}>
        {label}
      </button>
      <input ref={fileRef} type="file" accept={accept} onChange={handleFileChange} hidden />
    </>
  );
});

const ConsultingReport = ({ reservationId }) => {
  const navigation = useNavigate();

  const { openModal, closeModal } = useModal();

  const [searchParams] = useSearchParams();
  const consultingReportId = searchParams.get('consultingReportId');

  const { useGetReservation } = useReservation();
  const { reservationInfo } = useGetReservation(reservationId);

  const { useGetConsultingReport } = useConsultingReport();
  const { consultingReportInfo, refetch: refetchConsultingReport } = useGetConsultingReport(consultingReportId);

  // 이미지 파일 정보
  const [reportAttachmentImages, setReportAttachmentImages] = useState([]);

  // 첨부 파일 정보
  const [reportAttachmentFiles, setReportAttachmentFiles] = useState([]);

  const defaultValues = {
    [FileUploadKey.CONSULTING_REPORT_FILE]: '',
    [FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE]: [],
    [FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE]: [],
    date: [],
    time: '',
    subject: '',
    place: '',
    content: '',
    isSubmit: CommonConstant.NO_NUMBER,
  };

  let schemeObject = {};

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const downloadReportFile = () => {
    const fileUrl = require('assets/file/컨설팅일지양식.hwp');
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', '컨설팅일지양식.hwp');
    link.click();
  };

  const consultingDateSelectCallback = (dates = []) => {
    setValue('date', dates, { shouldValidate: true });
    closeModal();
  };

  const openConsultingDateSelectModal = () => {
    openModal(ModalNameConstant.CALENDAR, {
      selectedCalendarDates: getValues('date'),
      selectDateCallback: consultingDateSelectCallback,
    });
  };

  const saveConsultingReport = (isSubmit) => {
    if (
      isSubmit === CommonConstant.YES_NUMBER &&
      !window.confirm('제출하시겠습니까? 제출 후에는 수정이 불가능합니다. 수정이 필요한 경우 관리자에게 문의해 주세요')
    ) {
      return;
    }

    if (isSubmit === CommonConstant.NO_NUMBER && !window.confirm('저장하시겠습니까?')) {
      return;
    }

    const saveData = { ...getValues(), isSubmit };

    const formData = new FormData();

    for (let key in saveData) {
      formData.append(key, saveData[key]);
    }

    // 이미지 파일
    const reportAttachmentImages = saveData[FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE];

    for (let i = 0; i < reportAttachmentImages.length; i++) {
      formData.append([FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE], reportAttachmentImages[i]);
    }

    // 첨부 파일
    const reportAttachmentFiles = saveData[FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE];

    for (let i = 0; i < reportAttachmentFiles.length; i++) {
      formData.append([FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE], reportAttachmentFiles[i]);
    }

    ConsultingReportRepository.saveConsultingReport(formData)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          if (isSubmit === CommonConstant.YES_NUMBER) {
            alert('제출하였습니다');
          } else {
            alert('저장하였습니다');
          }

          if (res.data.consultingReportId) {
            navigation(
              `/consultant/consulting/detail/report?reservationId=${reservationId}&consultingReportId=${res.data.consultingReportId}`,
              {
                replace: true,
              },
            );
          }

          reset();
          refetchConsultingReport();
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  const removeConsultingReportAttachment = (consultingReportAttachmentId) => {
    if (getValues('isSubmit') === CommonConstant.YES_NUMBER) {
      return;
    }

    if (!window.confirm('파일을 삭제하시겠습니까?')) {
      return false;
    }

    ConsultingReportRepository.removeConsultingReportAttachment(consultingReportAttachmentId)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          const filterImages = reportAttachmentImages.filter(
            (item) => item.consultingReportAttachmentId !== consultingReportAttachmentId,
          );
          setReportAttachmentImages(filterImages);

          const filterFiles = reportAttachmentFiles.filter(
            (item) => item.consultingReportAttachmentId !== consultingReportAttachmentId,
          );
          setReportAttachmentFiles(filterFiles);

          alert('파일을 삭제하였습니다');
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  useEffect(() => {
    if (reservationInfo) {
      reset({ ...getValues(), ...reservationInfo });
    }

    if (consultingReportInfo) {
      reset({ ...getValues(), ...consultingReportInfo });

      setReportAttachmentImages(consultingReportInfo.reportAttachmentImages);
      setReportAttachmentFiles(consultingReportInfo.reportAttachmentFiles);
    }
  }, [reservationId, reservationInfo, consultingReportId, consultingReportInfo]);

  return (
    <MainLayout>
      <div className="page-header">보고서 작성하기</div>
      <h4>아래 2가지 작성 방법 중 하나를 선택하여 제출해 주세요</h4>
      <p>1. 양식 다운로드 후 파일 업로드 후 제출하기</p>
      <p>2. 아래 입력 폼 작성 후 제출하기</p>
      <br />
      <div>
        <button className="btn add-btn" onClick={downloadReportFile}>
          보고서 양식 다운로드
        </button>
        &nbsp;&nbsp;
        <FileUploadButton
          fileKey={FileUploadKey.CONSULTING_REPORT_FILE}
          className={'success-btn'}
          label={'보고서 파일 업로드'}
          accept={'application/pdf'}
          disabled={getValues('isSubmit')}
          onChange={(file) => setValue(FileUploadKey.CONSULTING_REPORT_FILE, file)}
        />
        &nbsp;&nbsp;
        <span>
          <b>
            {watch(FileUploadKey.CONSULTING_REPORT_FILE)
              ? watch(FileUploadKey.CONSULTING_REPORT_FILE).name
              : consultingReportInfo?.fileName && (
                  <Link to={consultingReportInfo.orgFileUrl} target="_blank" className="link">
                    {consultingReportInfo.fileName}
                  </Link>
                )}
          </b>
        </span>
      </div>
      <br />
      {getValues('isSubmit') ? (
        <div className="page-header error">
          현재는 제출이 완료된 상태입니다. 수정이 필요한 경우 관리자에게 문의해 주세요
        </div>
      ) : (
        ''
      )}
      <form noValidate autoComplete="off">
        <div className="page-card">
          <h3 className="text-center">{reservationInfo?.projectName}</h3>
          <br />
          <div className="table-search-wrap detail-form-wrap">
            <div>
              <label>기업 | 소속</label>
              <input type="text" value={reservationInfo?.clientCompany || ''} disabled />
            </div>
            <div>
              <label>이름</label>
              <input type="text" value={reservationInfo?.clientName || ''} disabled />
            </div>
            <div>
              <label>컨설팅 일자</label>
              <input
                type="text"
                value={getValues('date').join(', ')}
                readOnly
                onClick={openConsultingDateSelectModal}
                disabled={getValues('isSubmit')}
              />
            </div>
            <Controller
              name={'time'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onBlur, value, onChange } }) => (
                <div>
                  <label>컨설팅 시간</label>
                  <input
                    type="text"
                    placeholder="00:00 ~ 00:00, 00:00 ~ 00:00"
                    maxLength={100}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    disabled={getValues('isSubmit')}
                  />
                  {errors.time && <p className="error">{errors.time.message}</p>}
                </div>
              )}
            />
            <Controller
              name={'subject'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onBlur, value, onChange } }) => (
                <div>
                  <label>컨설팅 주제</label>
                  <input
                    type="text"
                    placeholder="컨설팅 주제"
                    maxLength={100}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    disabled={getValues('isSubmit')}
                  />
                  {errors.subject && <p className="error">{errors.subject.message}</p>}
                </div>
              )}
            />
            <Controller
              name={'place'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onBlur, value, onChange } }) => (
                <div>
                  <label>장소</label>
                  <input
                    type="text"
                    placeholder="장소"
                    maxLength={100}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    disabled={getValues('isSubmit')}
                  />
                  {errors.place && <p className="error">{errors.place.message}</p>}
                </div>
              )}
            />
            <div>
              <label>상담자 | 소속</label>
              <input type="text" value={reservationInfo?.consultantCompany || ''} disabled />
            </div>
            <div>
              <label>이름</label>
              <input type="text" value={reservationInfo?.consultantName || ''} disabled />
            </div>
          </div>
          <br />
          <h3 className="text-center">주요 컨설팅 내용</h3>
          <br />
          {getValues('isSubmit') ? (
            <div className="ql-editor" style={{ border: '1px solid #888888' }}>
              {parse(getValues('content'))}
            </div>
          ) : (
            <CustomEditor
              value={getValues('content')}
              onChange={(value) => {
                setValue('content', value);
              }}
            />
          )}
        </div>
      </form>
      <br />
      <br />
      <div>
        <FileUploadButton
          className={'success-btn'}
          label={'이미지 파일 업로드'}
          accept={'image/jpg, image/jpeg, image/png'}
          disabled={getValues('isSubmit')}
          onChange={(file, url) => {
            if (reportAttachmentImages.length === 10) {
              return;
            }

            setValue(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE, [
              ...getValues(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE),
              file,
            ]);

            setReportAttachmentImages([
              ...reportAttachmentImages,
              {
                index: getValues(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE).length - 1,
                url,
              },
            ]);
          }}
        />
      </div>
      <br />
      <div className="page-card">
        <div className="image-upload-list-wrap">
          {reportAttachmentImages.map((item, index) => (
            <div key={index} className="item">
              <img
                className={`${item.consultingReportAttachmentId ? 'server-file' : ''}`}
                src={item.url}
                alt=""
                onClick={() => {
                  if (item.consultingReportAttachmentId) {
                    window.open(item.orgFileUrl, '_blank');
                  }
                }}
              />
              <p
                className="error"
                onClick={() => {
                  if (item.consultingReportAttachmentId) {
                    removeConsultingReportAttachment(item.consultingReportAttachmentId);
                  } else {
                    const filterImages = reportAttachmentImages.filter((filter) => filter.url !== item.url);
                    setReportAttachmentImages(filterImages);

                    const formImages = getValues(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE);
                    formImages.splice(item.index, 1);

                    setValue(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE, formImages);
                  }
                }}
              >
                &times;
              </p>
            </div>
          ))}
        </div>
      </div>
      <br />
      <br />
      <div>
        <FileUploadButton
          className={'success-btn'}
          label={'첨부 파일 업로드'}
          disabled={getValues('isSubmit')}
          onChange={(file) => {
            if (reportAttachmentFiles.length === 10) {
              return;
            }

            setValue(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE, [
              ...getValues(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE),
              file,
            ]);

            setReportAttachmentFiles([
              ...reportAttachmentFiles,
              {
                index: getValues(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE).length - 1,
                fileName: file.name,
              },
            ]);
          }}
        />
      </div>
      <br />
      <div className="page-card">
        <div className="file-upload-list-wrap">
          {reportAttachmentFiles.map((item, index) => (
            <div key={index}>
              <span
                className={`title ${item.consultingReportAttachmentId ? 'link' : ''}`}
                onClick={() => {
                  if (item.consultingReportAttachmentId) {
                    CommonUtil.fileDownload(item.orgFileUrl, item.fileName);
                  }
                }}
              >
                {item.fileName}
              </span>
              <span
                className="delete error"
                onClick={() => {
                  if (item.consultingReportAttachmentId) {
                    removeConsultingReportAttachment(item.consultingReportAttachmentId);
                  } else {
                    const filterFiles = reportAttachmentFiles.filter((filter) => filter.fileName !== item.fileName);
                    setReportAttachmentFiles(filterFiles);

                    const formFiles = getValues(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE);
                    formFiles.splice(item.index, 1);

                    setValue(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE, formFiles);
                  }
                }}
              >
                &times;
              </span>
            </div>
          ))}
        </div>
      </div>
      <br />
      {!getValues('isSubmit') && (
        <div className="display-flex space-between">
          <div></div>
          <div>
            <button
              className="btn edit-btn"
              type="button"
              onClick={() => saveConsultingReport(CommonConstant.NO_NUMBER)}
            >
              저장
            </button>
            &nbsp;&nbsp;
            <button
              className="btn add-btn"
              type="button"
              onClick={() => saveConsultingReport(CommonConstant.YES_NUMBER)}
            >
              제출
            </button>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default ConsultingReport;
