import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Error404 = () => {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsShow(true), 500);

    return () => clearTimeout(timer);
  }, []);

  if (!isShow) {
    return null;
  }

  return (
    <div
      className="login-wrap"
      style={{
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <h2>페이지를 찾을 수 없습니다.</h2>
        <br />
        <Link to={'/login'}>로그인 페이지로 이동</Link>
      </div>
    </div>
  );
};

export default Error404;
