import { CommonConstant, RegexConstant, AccountTypeConstant } from 'constants/code.constant';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AuthRepository from 'repositories/AuthRepository';
import CommonUtil from 'utils/common.util';
import { ErrorCodeConstant } from 'constants/errorCode.constant';
import useAuthStore from 'store/auth';
import { useEffect } from 'react';

const Login = () => {
  const navigation = useNavigate();
  const { zstAccountInfo } = useAuthStore();

  const defaultValues = {
    email: '',
    password: '',
  };

  const schemeObject = {
    email: yup
      .string()
      .trim()
      .required('이메일을 입력해 주세요')
      .matches(RegexConstant.email, '이메일 형식이 올바르지 않습니다'),
    password: yup.string().trim().required('비밀번호를 입력해 주세요'),
  };

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const submitLogin = (formData) => {
    AuthRepository.login(formData)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          moveDashboard(res.data.accountType);
        }

        if (res.result === CommonConstant.ERROR) {
          // 계정이 일치하지 않는 경우
          if (res.errorCode === ErrorCodeConstant.ERROR_CODE.NOT_USER) {
            setError('email', { message: '이메일 또는 비밀번호가 일치하지 않습니다' });
          }

          // 가입 신청 중인 경우
          if (res.errorCode === ErrorCodeConstant.ERROR_CODE.SIGNUP_PENDING_STATUS) {
            alert('해당 계정은 신청 중인 상태입니다. 관리자에게 문의해 주세요');
          }
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  const moveDashboard = (accountType) => {
    if (accountType.split(', ').length > 1 || accountType.includes(AccountTypeConstant.ADMIN)) {
      navigation('/admin/dashboard');
    }

    if (accountType.includes(AccountTypeConstant.CLIENT)) {
      navigation('/client/dashboard');
    }

    if (accountType.includes(AccountTypeConstant.CONSULTANT)) {
      navigation('/consultant/dashboard');
    }
  };

  useEffect(() => {
    if (zstAccountInfo.accountId) {
      if (zstAccountInfo.accountId) {
        moveDashboard(zstAccountInfo.accountType);
      }
    }
  }, [zstAccountInfo]);

  return (
    <div className="login-wrap">
      <div className="login-container">
        <h2>로그인</h2>
        <br />
        <form noValidate onSubmit={handleSubmit(submitLogin)}>
          <Controller
            name={'email'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <>
                <label>이메일</label>
                <input
                  type="text"
                  placeholder="이메일"
                  maxLength={100}
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                />
                {errors.email && <p className="error">{errors.email.message}</p>}
              </>
            )}
          />
          <Controller
            name={'password'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <>
                <label>비밀번호</label>
                <input
                  type="password"
                  placeholder="비밀번호"
                  autoComplete="off"
                  maxLength={100}
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                />
                {errors.password && <p className="error">{errors.password.message}</p>}
              </>
            )}
          />
          <button type="submit">로그인</button>
        </form>
        <br />
        <br />
        <div className="bottom-wrap">
          <Link to={'/signup'}>회원가입</Link>
          <Link to={'/help/email-inquiry'}>이메일 찾기</Link>
          <Link to={'/help/pw-inquiry'}>비밀번호 찾기</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
