import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CommonConstant } from 'constants/code.constant';
import ProjectBudgetRepository from 'repositories/ProjectBudgetRepository';
import CommonUtil from 'utils/common.util';
import { useQuery } from '@tanstack/react-query';

const SaveForm = ({ initData, projectId, saveSuccessCallback }) => {
  const defaultValues = {
    description: '',
    amount: '',
  };

  let schemeObject = {
    description: yup.string().trim().required('설명을 입력해 주세요'),
    amount: yup.string().trim().required('금액을 입력해 주세요').max(18, '최대 18자리까지 입력 가능합니다'),
  };

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  useEffect(() => {
    if (initData) {
      reset({ ...initData, amount: parseFloat(initData.amount) });
    }
  }, [initData]);

  const saveProjectBudget = (formData) => {
    if (initData && !window.confirm('저장하시겠습니까?')) {
      return;
    }

    const data = {
      ...formData,
      projectId,
    };

    ProjectBudgetRepository.saveProjectBudget(data)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('저장하였습니다');
          saveSuccessCallback();

          if (!initData) {
            reset();
          }
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  const removeProjectBudget = () => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return;
    }

    ProjectBudgetRepository.removeProjectBudget(initData.projectBudgetId)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('삭제하였습니다');
          saveSuccessCallback();
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(saveProjectBudget)}>
      <div className="table-search-wrap detail-form-wrap">
        <Controller
          name={'description'}
          control={control}
          rules={{ required: true }}
          render={({ field: { onBlur, value, onChange } }) => (
            <div>
              <input type="text" placeholder="설명" maxLength={100} onBlur={onBlur} value={value} onChange={onChange} />
              {errors.description && <p className="error">{errors.description.message}</p>}
            </div>
          )}
        />
        <Controller
          name={'amount'}
          control={control}
          rules={{ required: true }}
          render={({ field: { onBlur, value, onChange } }) => (
            <div>
              <input type="number" placeholder="금액" onBlur={onBlur} value={value} onChange={onChange} />
              {errors.amount && <p className="error">{errors.amount.message}</p>}
            </div>
          )}
        />
        <div className="text-left">
          {initData ? (
            <>
              <button className="btn edit-btn">수정</button>
              &nbsp; &nbsp;
              <button className="btn error-btn" type="button" onClick={removeProjectBudget}>
                삭제
              </button>
            </>
          ) : (
            <button className="btn add-btn">등록</button>
          )}
        </div>
      </div>
    </form>
  );
};

const getProjectBudgetList = (projectId) => {
  return ProjectBudgetRepository.getProjectBudgetList({ projectId })
    .then((res) => {
      if (res.result === CommonConstant.OK) {
        return res.data;
      } else {
        console.error(res);
      }
    })
    .catch(CommonUtil.repoErrFunc);
};

const ProjectBudgetEditModal = ({ projectId, reloadProject }) => {
  const { data = {}, refetch: reloadProjectBudgetList } = useQuery({
    queryKey: ['projectBudgetList', projectId],
    queryFn: () => getProjectBudgetList(projectId),
    enabled: !!projectId,
  });

  const { list: projectBudgetList = [] } = data;

  const saveSuccessCallback = () => {
    reloadProject();
    reloadProjectBudgetList();
  };

  return (
    <div className="project-budget-edit-modal">
      <p className="title">예산 수정</p>
      <SaveForm projectId={projectId} saveSuccessCallback={saveSuccessCallback} />
      <p className="sub-title">예산 정보</p>
      {projectBudgetList.length > 0 ? (
        projectBudgetList.map((item, index) => (
          <SaveForm key={index} initData={item} projectId={projectId} saveSuccessCallback={saveSuccessCallback} />
        ))
      ) : (
        <p>조회된 예산 정보가 없습니다</p>
      )}
    </div>
  );
};

export default ProjectBudgetEditModal;
