export const CommonConstant = {
  OK: 'OK',
  FAIL: 'FAIL',
  ERROR: 'ERROR',
  Y: 'Y',
  N: 'N',
  YES_NUMBER: 1,
  NO_NUMBER: 0,
};

export const TokenConstant = {
  ACCESS_TOKEN_HEADER_KEY: 'x-pg-token',
  REFRESH_TOKEN_HEADER_KEY: 'x-pg-rtoken',
  ACCESS_TOKEN_COOKIE: 'PG-AT',
  REFRESH_TOKEN_COOKIE: 'PG-RT',
};

export const RegexConstant = {
  email: /^[\da-zA-Z]([-_.]?[\da-zA-Z])*@[\da-zA-Z]([-_.]?[\da-zA-Z])*\.[a-zA-Z]{2,5}$/,
  phone: /^\d{2,3}-\d{3,4}-\d{4}$/,
  year: /^\d{4}$/,
  yearMonth: /^\d{4}-(0[1-9]|1[0-2])$/,
  thousandsComma: /\B(?=(\d{3})+(?!\d))/g,
};

export const AccountTypeConstant = {
  ADMIN: 1, // 관리자
  CLIENT: 2, // 고객
  CONSULTANT: 3, // 컨설턴트
};

export const AccountTypeNameConstant = {
  [AccountTypeConstant.ADMIN]: '관리자',
  [AccountTypeConstant.CLIENT]: '기업',
  [AccountTypeConstant.CONSULTANT]: '컨설턴트',
};

export const RoleTypeConstant = {
  SUPER_ADMIN: 1, // 최고 관리자
  ADMIN: 2, // 일반 관리자
  COMPANY: 3, // 기업
  CAREGIVER: 4, // 보육사
  EXPERT: 5, // 전문가
  PARTNER: 6, // 파트너
};

export const RoleTypeNameConstant = {
  [RoleTypeConstant.SUPER_ADMIN]: '최고 관리자',
  [RoleTypeConstant.ADMIN]: '관리자',
  [RoleTypeConstant.COMPANY]: '기업',
  [RoleTypeConstant.CAREGIVER]: '보육사',
  [RoleTypeConstant.EXPERT]: '전문가',
  [RoleTypeConstant.PARTNER]: '파트너',
};

export const AccountStatusConstant = {
  ACTIVE: 1,
  PENDING: 2,
  DELETE: 99,
};

export const AccountStatusNameConstant = {
  [AccountStatusConstant.ACTIVE]: '정상',
  [AccountStatusConstant.PENDING]: '신청중',
  [AccountStatusConstant.DELETE]: '삭제',
};

export const ProjectItemTypeConstant = {
  CONSULTING: 1,
  EVENT: 2,
};

export const ProjectItemTypeNameConstant = {
  [ProjectItemTypeConstant.CONSULTING]: '컨설팅',
  [ProjectItemTypeConstant.EVENT]: '행사',
};

export const ReservationStatusConstant = {
  PENDING: 1,
  APPROVED: 2,
  FINISHED: 3,
  CANCELLED: 4,
};

export const ReservationStatusNameConstant = {
  [ReservationStatusConstant.PENDING]: '신청',
  [ReservationStatusConstant.APPROVED]: '확정',
  [ReservationStatusConstant.FINISHED]: '완료',
  [ReservationStatusConstant.CANCELLED]: '취소',
};

export const FileUploadKey = {
  CONSULTING_REPORT_FILE: 'consulting/report',
  CONSULTING_REPORT_ATTACHMENT_IMAGE: 'consulting/report/attachment/image',
  CONSULTING_REPORT_ATTACHMENT_FILE: 'consulting/report/attachment/file',
};
