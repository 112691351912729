import TableSortHeader from 'components/table/TableSortHeader';
import useProjectClientSelect from 'hooks/useProjectClientSelect';
import useSortTable from 'hooks/useSortTable';
import { useState } from 'react';
import Pagination from 'react-js-pagination';

const headCells = [
  { label: '#', width: '10%' },
  { label: '기업명' },
  { label: '대표자명', width: '15%' },
  { label: '담당자명', width: '15%' },
  { label: '선택', width: '10%' },
];

const ProjectItemClientSelectModal = ({ projectId, selectedClientIds = [], clientSelectCallback }) => {
  const [company, setCompany] = useState('');
  const [ceoName, setCeoName] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const limit = 10;

  const [selectedIds, setSelectedIds] = useState(selectedClientIds);

  const { sortBy, sortOrder, handleRequestSort } = useSortTable();

  const { useGetProjectClientSelectList } = useProjectClientSelect();

  const {
    list: clientList,
    totalCount,
    refetch,
  } = useGetProjectClientSelectList({
    page,
    limit,
    projectId,
    company,
    ceoName,
    name,
    type: 'selected',
  });

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    refetch();
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handleSelect = (accountId) => {
    if (!clientSelectCallback) return;

    let updatedIds = [];

    if (selectedIds.includes(accountId)) {
      // 같은 id가 이미 있는 경우 제거
      updatedIds = selectedIds.filter((id) => id !== accountId);
    } else {
      // id가 없는 경우 추가
      updatedIds = [...selectedIds, accountId];
    }

    setSelectedIds(updatedIds);
    clientSelectCallback(updatedIds);
  };

  return (
    <div className="project-item-client-select-modal">
      <p className="title">참여 기업 선택</p>
      <form>
        <div className="table-search-wrap">
          <div>
            <label>기업명</label>
            <input type="text" placeholder="기업명" maxLength={100} onChange={(e) => setCompany(e.target.value)} />
          </div>
          <div>
            <label>대표자명</label>
            <input type="text" placeholder="대표자명" maxLength={100} onChange={(e) => setCeoName(e.target.value)} />
          </div>
          <div>
            <label>담당자명</label>
            <input type="text" placeholder="담당자명" maxLength={100} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="btn text-left">
            <button className="btn" onClick={handleSearch}>
              검색
            </button>
          </div>
        </div>
      </form>
      <br />
      <div className="table-wrap">
        <p className="text-left">
          조회된 수: <strong>{totalCount}</strong>개
        </p>
        <table>
          <TableSortHeader
            sortBy={sortBy}
            sortOrder={sortOrder}
            handleRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <tbody>
            {totalCount === 0 && (
              <tr>
                <td colSpan={headCells.length}>검색 결과가 없습니다.</td>
              </tr>
            )}
            {clientList.map((item, index) => (
              <tr key={index}>
                <td>{totalCount - (page - 1) * limit - index}</td>
                <td>{item.company}</td>
                <td>{item.ceoName}</td>
                <td>{item.name}</td>
                <td>
                  {selectedIds.includes(item.accountId) ? (
                    <button className="btn error-btn" onClick={() => handleSelect(item.accountId)}>
                      해제
                    </button>
                  ) : (
                    <button className="btn add-btn" onClick={() => handleSelect(item.accountId)}>
                      선택
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {totalCount > 0 && (
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={totalCount}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectItemClientSelectModal;
