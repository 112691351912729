import AdminScheduleCalendar from 'components/admin-schedule-calendar';
import TableSortHeader from 'components/table/TableSortHeader';
import { format } from 'date-fns';
import useSchedule from 'hooks/useSchedule';
import useSortTable from 'hooks/useSortTable';
import MainLayout from 'layouts/MainLayout';
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';

const headCells = [
  { label: '#', width: '10%' },
  { label: '제목' },
  { label: '일정 날짜', width: '30%' },
  { label: '비고', width: '10%' },
];

const AdminMyScheduleList = () => {
  const [page, setPage] = useState(1);
  const limit = 10;

  const { sortBy, sortOrder, handleRequestSort } = useSortTable();

  const { useGetMyScheduleList } = useSchedule();
  const { list: schedules, totalCount } = useGetMyScheduleList({ page, limit });

  const handlePageChange = (value) => {
    setPage(value);
  };

  return (
    <>
      <div className="page-header">나의 일정 목록</div>
      <Link to={'/admin/schedule/detail'}>
        <button className="btn add-btn">일정 등록</button>
      </Link>
      <br />
      <br />
      <div className="page-card">
        <div className="table-wrap">
          <p>
            총 일정 수: <strong>{totalCount}</strong>개
          </p>
          <table>
            <TableSortHeader
              sortBy={sortBy}
              sortOrder={sortOrder}
              handleRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <tbody>
              {totalCount === 0 && (
                <tr>
                  <td colSpan={headCells.length}>검색 결과가 없습니다.</td>
                </tr>
              )}
              {schedules.map((item, index) => (
                <tr key={index}>
                  <td>{(page - 1) * limit + index + 1}</td>
                  <td>{item.title}</td>
                  <td>{format(item.scheduleAt, 'yyyy-MM-dd')}</td>
                  <td>
                    <Link to={`/admin/schedule/detail?scheduleId=${item.scheduleId}`}>
                      <button className="btn edit-btn">수정</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalCount > 0 && (
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

const AdminSchedule = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleSelectDateCallback = (date) => {
    setSelectedDate(date);
  };

  const { useGetScheduleList } = useSchedule();
  const { list } = useGetScheduleList({
    selectedDate,
  });

  return (
    <MainLayout>
      <div className="page-card admin-schedule">
        <AdminScheduleCalendar schedules={list} handleSelectDateCallback={handleSelectDateCallback} />
      </div>
      <br />
      <br />
      <AdminMyScheduleList />
    </MainLayout>
  );
};

export default AdminSchedule;
