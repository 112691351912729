import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useModal from 'hooks/useModal';
import { ModalNameConstant } from 'constants/modal.constant';
import EventAssignRepository from 'repositories/EventAssignRepository';
import { CommonConstant } from 'constants/code.constant';
import CommonUtil from 'utils/common.util';
import { ErrorCodeConstant } from 'constants/errorCode.constant';
import useEventAssign from 'hooks/useEventAssign';
import { format } from 'date-fns';

const SaveForm = ({ projectId, projectItemId, initData, refetch }) => {
  const { openModal } = useModal();

  const defaultValues = {
    projectItemId: projectItemId,
    eventName: '',
    place: '',
    period: [],
    assignedClientIds: [],
  };

  let schemeObject = {
    eventName: yup.string().trim().required('행사명을 입력해 주세요'),
    period: yup.array().min(1, '기간을 선택해 주세요'),
  };

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const openClientSelectModal = () => {
    openModal(ModalNameConstant.PROJECT_ITEM_CLIENT_SELECT, {
      projectId,
      selectedClientIds: getValues('assignedClientIds'),
      clientSelectCallback,
    });
  };

  const clientSelectCallback = (accountIds = []) => {
    setValue('assignedClientIds', accountIds, { shouldValidate: true });
  };

  const openPeriodSelectModal = () => {
    openModal(ModalNameConstant.CALENDAR, {
      multipleSelect: true,
      selectedCalendarDates: getValues('period'),
      selectDateCallback: periodSelectCallback,
    });
  };

  const periodSelectCallback = (dates = []) => {
    // 오름차순 정렬
    const datesAsc = dates.sort((a, b) => new Date(a) - new Date(b));

    setValue('period', datesAsc, { shouldValidate: true });
  };

  const saveEventAssign = (formData) => {
    if (!window.confirm('저장하시겠습니까?')) {
      return;
    }

    EventAssignRepository.saveEventAssign(formData)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('저장하였습니다');
          refetch();

          if (!initData) {
            reset();
          }
        }

        if (res.errorCode === ErrorCodeConstant.ERROR_CODE.EXCEEDED_REQUIRED_SCHEDULE_COUNT) {
          alert('전체 일정 횟수를 초과합니다');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  const removeConsultantAssign = () => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return;
    }

    EventAssignRepository.removeEventAssign(initData.eventAssignId)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('삭제하였습니다');
          refetch();
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  useEffect(() => {
    if (initData) {
      reset({ ...initData, projectItemId });
    }
  }, [initData]);

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(saveEventAssign)}>
      <div className="page-card">
        <div className="table-search-wrap detail-form-wrap">
          <Controller
            name={'eventName'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <div>
                <label>행사명</label>
                <input
                  type="text"
                  placeholder="행사명"
                  maxLength={100}
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                />
                {errors.eventName && <p className="error">{errors.eventName.message}</p>}
              </div>
            )}
          />
          <Controller
            name={'place'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <div>
                <label>장소</label>
                <input
                  type="text"
                  placeholder="장소"
                  maxLength={100}
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                />
              </div>
            )}
          />
          <div>
            <label>참여 기업 선택</label>
            <input
              type="text"
              placeholder="클릭하여 선택"
              value={
                getValues('assignedClientIds').length > 0
                  ? `선택된 기업 개수: ${getValues('assignedClientIds').length}`
                  : ''
              }
              readOnly
              onClick={openClientSelectModal}
            />
          </div>
          <div>
            <label>기간</label>
            <input
              type="text"
              placeholder="클릭하여 선택"
              value={getValues('period').join(', ')}
              readOnly
              onClick={openPeriodSelectModal}
            />
            {errors.period && <p className="error">{errors.period.message}</p>}
          </div>
          {initData && (
            <>
              <div />
              <div>
                <label>등록일시</label>
                <input type="text" value={format(initData.createdAt, 'yyyy-MM-dd HH:mm:ss')} disabled />
              </div>
            </>
          )}
        </div>
      </div>
      <br />
      <div className="display-flex space-between">
        <div />
        <div>
          {initData && (
            <>
              <button className="btn error-btn" type="button" onClick={removeConsultantAssign}>
                삭제
              </button>
              &nbsp;&nbsp;
            </>
          )}
          <button className="btn add-btn" type="submit">
            저장
          </button>
        </div>
      </div>
    </form>
  );
};

const ProjectItemDetailEventForm = React.memo(({ projectId, projectItemId }) => {
  const { useGetEventAssignList } = useEventAssign();

  const { list: eventAssignList, totalCount, refetch } = useGetEventAssignList({ projectItemId });

  const saveFormProps = {
    projectId,
    projectItemId,
    refetch,
  };

  return (
    <>
      <div className="page-header">행사 등록</div>
      <SaveForm {...saveFormProps} />
      {totalCount > 0 && (
        <>
          <div className="page-header">행사 목록 {totalCount}개</div>
          {eventAssignList.map((item, index) => (
            <div key={index}>
              <SaveForm {...saveFormProps} initData={item} />
              <br />
            </div>
          ))}
        </>
      )}
    </>
  );
});

export default ProjectItemDetailEventForm;
