import React from 'react';
import { format } from 'date-fns';
import { Tooltip } from 'react-tooltip';

const weekNames = ['일', '월', '화', '수', '목', '금', '토'];

const isSameDate = (date1, date2) => {
  return format(date1, 'yyyy-MM-dd') === format(date2, 'yyyy-MM-dd');
};

const RenderSchedule = React.memo(({ scheduleFilter }) => {
  return (
    <div>
      {scheduleFilter.map((item, index) => (
        <div key={index}>
          <p
            className={`schedule-title ${item.isOwner ? 'owner' : ''}`}
            data-tooltip-id={`${index}`}
            data-tooltip-content={item.detail}
          >
            {item.title}
          </p>
          <Tooltip id={`${index}`} />
        </div>
      ))}
    </div>
  );
});

const AdminScheduleCalendarBody = React.memo(({ days, nowDate, schedules = [] }) => {
  const dayClassName = ({ date, disabled }) => {
    let resultClassName = '';

    if (isSameDate(nowDate, date)) {
      resultClassName += ' now';
    }

    if (disabled) {
      resultClassName += ' disabled';
    }

    return resultClassName;
  };

  return (
    <div className="calender-body">
      <div className="week-name unselectable">
        {weekNames.map((day) => (
          <p key={day}>{day}</p>
        ))}
      </div>
      <div className="day">
        {days.map((item, index) => (
          <div key={index} className={`item${dayClassName(item)}`}>
            <p>{item.day}</p>
            <RenderSchedule scheduleFilter={schedules.filter((schedule) => schedule['date'] === item.date)} />
          </div>
        ))}
      </div>
    </div>
  );
});

export default AdminScheduleCalendarBody;
