import { useEffect, useState } from 'react';
import CalendarBody from './CalendarBody';
import CalenderHeader from './CalenderHeader';
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  addMonths,
  subMonths,
  startOfWeek,
  endOfWeek,
  format,
  getMonth,
  getYear,
  setMonth,
  setYear,
} from 'date-fns';

export const getCalendarDate = (year, month) => {
  const currentMonthDate = new Date(year, month - 1);

  // 1. 현재 달의 시작일과 마지막일
  const currentStartDate = startOfMonth(currentMonthDate);
  const currentEndDate = endOfMonth(currentMonthDate);

  // 2. 달력에 표시할 첫 번째 날짜(이전 달 포함)
  const prevMonthStartDate = startOfWeek(currentStartDate);
  const nextMonthEndDate = endOfWeek(currentEndDate);

  // 3. 달력에 표시할 모든 날짜 계산 (이전 달, 현재 달, 다음 달 포함)
  const allDates = eachDayOfInterval({
    start: prevMonthStartDate,
    end: nextMonthEndDate,
  });

  // 4. 날짜와 해당 월 정보를 포함하여 반환
  return allDates.map((date) => ({
    date: format(date, 'yyyy-MM-dd'),
    day: format(date, 'd'),
    disabled: date < currentStartDate || date > currentEndDate,
  }));
};

const Calendar = ({
  isToday = true,
  multipleSelect = false,
  isSelectable = true,
  selectedCalendarDates = [],
  schedules = [],
  selectDateCallback,
}) => {
  const nowDate = new Date();

  const [currentDate, setCurrentDate] = useState(nowDate);
  const [selectedDates, setSelectedDates] = useState(selectedCalendarDates);
  const [days, setDays] = useState([]);

  const handleToday = () => {
    setCurrentDate(nowDate);
    handleSelectData(format(nowDate, 'yyyy-MM-dd'));
  };
  const handlePreviousMonth = () => setCurrentDate(subMonths(currentDate, 1));
  const handleNextMonth = () => setCurrentDate(addMonths(currentDate, 1));
  const handleYearChange = (year) => setCurrentDate(setYear(currentDate, year));
  const handleMonthChange = (month) => setCurrentDate(setMonth(currentDate, month));

  useEffect(() => {
    setDays(getCalendarDate(getYear(currentDate), getMonth(currentDate) + 1));
  }, [currentDate]);

  const handleSelectData = (date) => {
    // 선택 여부가 false면 막기
    if (!isSelectable) return;

    let updatedDates = [];

    if (multipleSelect) {
      if (selectedDates.includes(date)) {
        updatedDates = selectedDates.filter((item) => item !== date);
      } else {
        updatedDates = [...selectedDates, date];
      }
    } else {
      updatedDates = [date];
    }

    setSelectedDates(updatedDates);
    selectDateCallback && selectDateCallback(updatedDates);
  };

  return (
    <div className="calender-wrap">
      <CalenderHeader
        currentYear={getYear(currentDate)}
        currentMonth={getMonth(currentDate)}
        handleToday={handleToday}
        handlePreviousMonth={handlePreviousMonth}
        handleNextMonth={handleNextMonth}
        handleYearChange={handleYearChange}
        handleMonthChange={handleMonthChange}
      />
      <CalendarBody
        days={days}
        nowDate={isToday ? nowDate : null}
        selectedDates={selectedDates}
        schedules={schedules}
        handleSelectData={handleSelectData}
      />
    </div>
  );
};

export default Calendar;
