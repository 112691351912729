import TableSortHeader from 'components/table/TableSortHeader';
import { format } from 'date-fns';
import useProject from 'hooks/useProject';
import useSortTable from 'hooks/useSortTable';
import MainLayout from 'layouts/MainLayout';
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import CommonUtil from 'utils/common.util';

const headCells = [
  { label: '#', width: '5%' },
  { label: '프로젝트명' },
  { id: 'orderer', label: '발주처', width: '20%', isSort: true },
  { id: 'executionYear', label: '수행연도', width: '10%', isSort: true },
  { label: '시작일', width: '10%' },
  { label: '종료일', width: '10%' },
  { id: 'budgetAmount', label: '예산(단위: 원)', width: '15%', isSort: true },
  { label: '등록일', width: '10%' },
  { label: '비고', width: '10%' },
];

const AdminProjectList = () => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const [name, setName] = useState('');
  const [orderer, setOrderer] = useState('');
  const [executionYear, setExecutionYear] = useState('');

  const { sortBy, sortOrder, handleRequestSort } = useSortTable();

  const { useGetProjectList } = useProject();
  const {
    list: projects,
    totalCount,
    refetch,
  } = useGetProjectList({
    page,
    limit,
    sortBy,
    sortOrder,
    name,
    orderer,
    executionYear,
  });

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    refetch();
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  return (
    <MainLayout>
      <div className="page-header">프로젝트 목록</div>
      <div className="page-card">
        <form>
          <div className="table-search-wrap">
            <div>
              <label>프로젝트명</label>
              <input type="text" placeholder="프로젝트명" maxLength={100} onChange={(e) => setName(e.target.value)} />
            </div>
            <div>
              <label>발주처</label>
              <input type="text" placeholder="발주처" maxLength={100} onChange={(e) => setOrderer(e.target.value)} />
            </div>
            <div>
              <label>수행연도</label>
              <input type="number" placeholder="수행연도" onChange={(e) => setExecutionYear(e.target.value)} />
            </div>
            <div className="btn">
              <button className="btn" onClick={handleSearch}>
                검색
              </button>
            </div>
          </div>
        </form>
      </div>
      <br />
      <Link to={'/admin/project/detail'}>
        <button className="btn add-btn">등록</button>
      </Link>
      <br />
      <br />
      <div className="page-card">
        <div className="table-wrap">
          <p>
            조회된 수: <strong>{totalCount}</strong>개
          </p>
          <table>
            <TableSortHeader
              sortBy={sortBy}
              sortOrder={sortOrder}
              handleRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <tbody>
              {totalCount === 0 && (
                <tr>
                  <td colSpan={headCells.length}>검색 결과가 없습니다.</td>
                </tr>
              )}
              {projects.map((item, index) => (
                <tr key={index}>
                  <td>{totalCount - (page - 1) * limit - index}</td>
                  <td>{item.name}</td>
                  <td>{item.orderer}</td>
                  <td>{item.executionYear}</td>
                  <td>{item.startAt}</td>
                  <td>{item.endAt}</td>
                  <td>{CommonUtil.tsCommaFormat(parseFloat(item.budgetAmount))}원</td>
                  <td>{format(item.createdAt, 'yyyy-MM-dd')}</td>
                  <td>
                    <Link to={`/admin/project/detail?projectId=${item.projectId}`}>
                      <button className="btn edit-btn">상세보기</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalCount > 0 && (
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default AdminProjectList;
