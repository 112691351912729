import { API_PROJECT_BUDGET_ROUTE } from 'constants/api.constant';
import AxiosUtil from 'utils/axios.util';

const ProjectBudgetRepository = {
  /**
   * 예산 목록 조회
   * @param {*} data
   * @returns
   */
  getProjectBudgetList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_PROJECT_BUDGET_ROUTE}/list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  /**
   * 프로젝트 예산 저장
   * @param {*} data
   * @returns
   */
  saveProjectBudget(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_PROJECT_BUDGET_ROUTE}`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  /**
   * 프로젝트 예산 삭제
   * @param {*} projectBudgetId
   * @returns
   */
  removeProjectBudget(projectBudgetId) {
    try {
      return AxiosUtil.delete(
        { url: `${API_PROJECT_BUDGET_ROUTE}/${projectBudgetId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default ProjectBudgetRepository;
