import { API_IR_DECK_ROUTE } from 'constants/api.constant';
import AxiosUtil from 'utils/axios.util';

const IrRepository = {
  IrDeckGenerate(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_IR_DECK_ROUTE}/generate`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default IrRepository;
