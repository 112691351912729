import Gnb from 'components/gnb';
import Header from 'components/slice/Header';
import React from 'react';

const MainLayout = ({ children }) => {
  return (
    <div className="main-layout-wrap">
      <Gnb />
      <div className="page-body">
        <Header />
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
