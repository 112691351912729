import { API_COMPANY_EVALUATION_ROUTE } from 'constants/api.constant';
import AxiosUtil from 'utils/axios.util';

const CompanyEvaluationRepository = {
  getCompanyEvaluationList() {
    try {
      return AxiosUtil.get(
        { url: `${API_COMPANY_EVALUATION_ROUTE}/list`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  getCompanyEvaluation(companyEvaluationId) {
    try {
      return AxiosUtil.get(
        { url: `${API_COMPANY_EVALUATION_ROUTE}/${companyEvaluationId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default CompanyEvaluationRepository;
