import TableSortHeader from 'components/table/TableSortHeader';
import useConsultingReport from 'hooks/useConsultingReport';
import useSortTable from 'hooks/useSortTable';
import MainLayout from 'layouts/MainLayout';
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';

const headCells = [
  { label: '#', width: '5%' },
  { label: '프로젝트명' },
  { label: '과업명', width: '10%' },
  { label: '컨설팅명', width: '20%' },
  // { label: '컨설턴트명', width: '10%' },
  // { label: '기업명', width: '10%' },
  // { label: '담당자명', width: '10%' },
  { label: '예약 일자', width: '15%' },
  { label: '예약 시간', width: '15%' },
  { label: '총 시간', width: '10%' },
  { label: '비고', width: '10%' },
];

const AdminConsultingReportList = () => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const [projectName, setProjectName] = useState('');
  const [projectItemName, setProjectItemName] = useState('');
  const [consultingName, setConsultingName] = useState('');
  const [consultantName, setConsultantName] = useState('');
  const [clientCompany, setClientCompany] = useState('');

  const { sortBy, sortOrder, handleRequestSort } = useSortTable();

  const { useGetConsultingReportList } = useConsultingReport();
  const {
    list: reports,
    totalCount,
    refetch,
  } = useGetConsultingReportList({
    page,
    limit,
    projectName,
    projectItemName,
    consultingName,
    consultantName,
    clientCompany,
  });

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    refetch();
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  return (
    <MainLayout>
      <div className="page-header">컨설팅 보고서 목록</div>
      <div className="page-card">
        <form>
          <div className="table-search-wrap">
            <div>
              <label>프로젝트명</label>
              <input
                type="text"
                placeholder="프로젝트명"
                maxLength={100}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </div>
            <div>
              <label>과업명</label>
              <input
                type="text"
                placeholder="과업명"
                maxLength={100}
                onChange={(e) => setProjectItemName(e.target.value)}
              />
            </div>
            <div>
              <label>컨설팅명</label>
              <input
                type="text"
                placeholder="컨설팅명"
                maxLength={100}
                onChange={(e) => setConsultingName(e.target.value)}
              />
            </div>
            <div />
            <div />
            <div>
              <label>컨설턴트명</label>
              <input
                type="text"
                placeholder="컨설턴트명"
                maxLength={100}
                onChange={(e) => setConsultantName(e.target.value)}
              />
            </div>
            <div>
              <label>기업명</label>
              <input
                type="text"
                placeholder="기업명"
                maxLength={100}
                onChange={(e) => setClientCompany(e.target.value)}
              />
            </div>
            <div className="btn">
              <button className="btn" onClick={handleSearch}>
                검색
              </button>
            </div>
          </div>
        </form>
      </div>
      <br />
      <div className="page-card">
        <div className="table-wrap">
          <p>
            조회된 수: <strong>{totalCount}</strong>개
          </p>
          <table>
            <TableSortHeader
              sortBy={sortBy}
              sortOrder={sortOrder}
              handleRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <tbody>
              {totalCount === 0 && (
                <tr>
                  <td colSpan={headCells.length}>검색 결과가 없습니다.</td>
                </tr>
              )}
              {reports.map((item, index) => (
                <tr key={index}>
                  <td>{totalCount - (page - 1) * limit - index}</td>
                  <td>{item.projectName}</td>
                  <td>{item.projectItemName}</td>
                  <td>{item.consultingName}</td>
                  <td>{item.date}</td>
                  <td>{item.time}</td>
                  <td>{item.totalTime}</td>
                  <td>
                    <Link
                      to={`/admin/consulting-report/detail?reservationId=${item.reservationId}&consultingReportId=${item.consultingReportId}`}
                    >
                      <button className="btn edit-btn">상세</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalCount > 0 && (
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default AdminConsultingReportList;
