import useAccessibleRoute from 'hooks/useAccessibleRoute';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useAuthStore from 'store/auth';

const Gnb = React.memo(() => {
  const location = useLocation();
  const { zstAccountInfo } = useAuthStore();

  const accessibleRoutes = useAccessibleRoute(zstAccountInfo.accountType);
  const menuRoute = accessibleRoutes.filter((route) => route.accountType);

  return (
    <div className="gnb-wrap unselectable">
      <div className="gnb">
        <ul>
          {menuRoute.map((route) =>
            route.children.map((childRoute, index) => {
              const fullPath = `${route.path}${childRoute.path}`;
              const isActive =
                childRoute.path === '/' ? location.pathname === fullPath : location.pathname.startsWith(fullPath);

              if (childRoute.title) {
                return (
                  childRoute.title && (
                    <li key={index} className={isActive ? 'active' : ''}>
                      <Link to={fullPath}>{childRoute.title}</Link>
                    </li>
                  )
                );
              }

              if (childRoute.sectionTitle) {
                return (
                  <li key={index} className="section-title">
                    <p>{childRoute.sectionTitle}</p>
                  </li>
                );
              }
            }),
          )}
        </ul>
      </div>
    </div>
  );
});

export default Gnb;
