import MainLayout from 'layouts/MainLayout';

const ClientDashboard = () => {
  return (
    <MainLayout>
      <div>기업 대시보드</div>
    </MainLayout>
  );
};

export default ClientDashboard;
