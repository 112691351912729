import React from 'react';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';

const CalenderHeader = React.memo(
  ({
    handleToday,
    currentYear,
    currentMonth,
    handlePreviousMonth,
    handleNextMonth,
    handleYearChange,
    handleMonthChange,
  }) => {
    return (
      <div className="calender-header unselectable">
        <button className="btn disabled-btn" onClick={handleToday}>
          오늘
        </button>
        <div className="right">
          <p className="controller" onClick={handlePreviousMonth}>
            <SlArrowLeft size={20} />
          </p>
          <div className="select-date">
            <div>
              <select value={currentYear} onChange={(e) => handleYearChange(e.target.value)}>
                {Array.from({ length: 25 }, (_, i) => 2020 + i).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              년
            </div>
            <div>
              <select value={currentMonth} onChange={(e) => handleMonthChange(e.target.value)}>
                {Array.from({ length: 12 }, (_, i) => i).map((month, index) => (
                  <option key={index} value={month}>
                    {month + 1}
                  </option>
                ))}
              </select>
              월
            </div>
          </div>
          <p className="controller" onClick={handleNextMonth}>
            <SlArrowRight size={20} />
          </p>
        </div>
      </div>
    );
  },
);

export default CalenderHeader;
