import useModal from 'hooks/useModal';
import useModalStore from 'store/modal';
import { ModalConstant } from 'constants/modal.constant';
import React from 'react';

const ModalContainer = () => {
  const { zstIsOpen, zstModalName, zstProps } = useModalStore();

  const { closeModal } = useModal();

  const findModal = ModalConstant.find((modal) => {
    return modal.modalName === zstModalName;
  });

  const renderModal = (props) => {
    return React.cloneElement(findModal.element, props);
  };

  const handleClickOutside = (e) => {
    if (e.target.className === 'modal-overlay') {
      closeModal();
    }
  };

  return (
    zstIsOpen && (
      <div className="modal-overlay" onClick={handleClickOutside}>
        <div className="modal-container">
          <div className="close" onClick={closeModal}>
            &times;
          </div>
          <div className="modal-inner">{renderModal(zstProps)}</div>
        </div>
      </div>
    )
  );
};

export default ModalContainer;
