import React, { useEffect } from 'react';
import { CommonConstant, ProjectItemTypeConstant, ProjectItemTypeNameConstant } from 'constants/code.constant';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useModal from 'hooks/useModal';
import useProjectItem from 'hooks/useProjectItem';
import { format } from 'date-fns';
import { ModalNameConstant } from 'constants/modal.constant';
import ProjectItemRepository from 'repositories/ProjectItemRepository';
import CommonUtil from 'utils/common.util';
import { Link, useNavigate } from 'react-router-dom';

const selectOptionList = {
  type: [
    {
      value: ProjectItemTypeConstant.CONSULTING,
      label: ProjectItemTypeNameConstant[ProjectItemTypeConstant.CONSULTING],
    },
    {
      value: ProjectItemTypeConstant.EVENT,
      label: ProjectItemTypeNameConstant[ProjectItemTypeConstant.EVENT],
    },
  ],
  fileUpload: [
    {
      value: CommonConstant.NO_NUMBER,
      label: '불가',
    },
    {
      value: CommonConstant.YES_NUMBER,
      label: '가능',
    },
  ],
};

const ProjectItemDetailForm = React.memo(({ projectId, projectItemId, setProjectItemType }) => {
  const navigation = useNavigate();

  const { openModal } = useModal();

  const { useGetProjectItem } = useProjectItem();
  const { projectItemInfo } = useGetProjectItem(projectItemId);

  const defaultValues = {
    projectId: projectId,
    itemName: '',
    type: ProjectItemTypeConstant.CONSULTING,
    maxScheduleCount: '',
    accountId: '',
    accountName: '',
    isFileUpload: CommonConstant.NO_NUMBER,
  };

  let schemeObject = {
    itemName: yup.string().trim().required('과업명을 입력해 주세요'),
    maxScheduleCount: yup.string().trim().required('전체 일정 횟수').max(10, '최대 10자리까지 입력 가능합니다'),
  };

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const managerSelectCallback = ({ accountId = '', name = '' }) => {
    setValue('accountId', accountId, { shouldValidate: true });
    setValue('accountName', name);
  };

  const saveProjectItem = (formData) => {
    if (!window.confirm('저장하시겠습니까?')) {
      return;
    }

    if (projectItemId) {
      formData.projectItemId = projectItemId;
    }

    ProjectItemRepository.saveProjectItem(formData)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('저장하였습니다');
          navigation(
            `/admin/project/project-item/detail?projectId=${projectId}&projectItemId=${res.data.projectItemId}`,
            { replace: true },
          );
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  const removeProjectItem = () => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return;
    }

    ProjectItemRepository.removeProjectItem(projectItemId)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('삭제하였습니다');
          navigation(`/admin/project/detail?projectId=${projectId}`);
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  useEffect(() => {
    if (projectItemInfo) {
      reset({ ...projectItemInfo, accountName: projectItemInfo.name });
      setProjectItemType(projectItemInfo.type);
    }
  }, [projectItemId, projectItemInfo]);

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(saveProjectItem)}>
      <div className="page-card">
        <div className="table-search-wrap detail-form-wrap">
          <Controller
            name={'itemName'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <div>
                <label>과업명</label>
                <input
                  type="text"
                  placeholder="과업명"
                  maxLength={100}
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                />
                {errors.itemName && <p className="error">{errors.itemName.message}</p>}
              </div>
            )}
          />
          <Controller
            name={'type'}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <div>
                <label>유형 {!projectItemId && '(최초 등록 이후 수정 하실 수 없습니다)'}</label>
                <select value={value} onChange={onChange} disabled={projectItemId}>
                  {selectOptionList.type.map((role, index) => (
                    <option key={index} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
          />
          <Controller
            name={'maxScheduleCount'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <div>
                <label>전체 일정 횟수</label>
                <input type="number" placeholder="전체 일정 횟수" onBlur={onBlur} value={value} onChange={onChange} />
                {errors.maxScheduleCount && <p className="error">{errors.maxScheduleCount.message}</p>}
              </div>
            )}
          />
          <div>
            <label>담당자</label>
            <div
              onClick={() =>
                openModal(ModalNameConstant.PROJECT_ITEM_MANAGER_SELECT, {
                  managerSelectCallback,
                  selectedAccountId: getValues('accountId'),
                })
              }
            >
              <input type="text" placeholder="담당자 선택" value={getValues('accountName') || ''} readOnly />
            </div>
          </div>
          <Controller
            name={'isFileUpload'}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <div>
                <label>파일 업로드 여부</label>
                <select value={value} onChange={onChange}>
                  {selectOptionList.fileUpload.map((role, index) => (
                    <option key={index} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
          />
          {projectItemId && projectItemInfo && (
            <div>
              <label>등록일시</label>
              <input type="text" value={format(projectItemInfo.createdAt, 'yyyy-MM-dd HH:mm:ss')} disabled />
            </div>
          )}
        </div>
      </div>
      <br />
      <div className="display-flex space-between">
        <div>
          <Link to={`/admin/project/detail?projectId=${projectId}`}>
            <button className="btn success-btn" type="button">
              과업 목록
            </button>
          </Link>
        </div>
        <div>
          {projectItemId && (
            <>
              <button className="btn error-btn" type="button" onClick={removeProjectItem}>
                삭제
              </button>
              &nbsp;&nbsp;
            </>
          )}
          <button className="btn add-btn" type="submit">
            저장
          </button>
        </div>
      </div>
      <br />
    </form>
  );
});

export default ProjectItemDetailForm;
