import { API_CONSULTING_REPORT_ROUTE } from 'constants/api.constant';
import AxiosUtil from 'utils/axios.util';

const ConsultingReportRepository = {
  getConsultingReportList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_CONSULTING_REPORT_ROUTE}/list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  getConsultingReport(consultingReportId) {
    try {
      return AxiosUtil.get(
        { url: `${API_CONSULTING_REPORT_ROUTE}/${consultingReportId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  saveConsultingReport(data) {
    try {
      return AxiosUtil.multipartFormData(
        { url: `${API_CONSULTING_REPORT_ROUTE}`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  removeConsultingReportAttachment(consultingReportAttachmentId) {
    try {
      return AxiosUtil.delete(
        { url: `${API_CONSULTING_REPORT_ROUTE}/${consultingReportAttachmentId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default ConsultingReportRepository;
