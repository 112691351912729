import { useQuery } from '@tanstack/react-query';
import { CommonConstant } from 'constants/code.constant';
import ProjectItemRepository from 'repositories/ProjectItemRepository';
import CommonUtil from 'utils/common.util';

const useProjectItem = () => {
  const useGetProjectItemList = ({ page = 1, limit = 10, projectId }) => {
    const getProjectItemList = () => {
      const params = {
        offset: (page - 1) * limit,
        limit,
        projectId,
      };

      return ProjectItemRepository.getProjectItemList(params)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const projectItemListQuery = useQuery({
      queryKey: ['projectItemList', { projectId, page }],
      queryFn: getProjectItemList,
      enabled: !!projectId,
    });

    return {
      list: projectItemListQuery.data?.list || [],
      totalCount: projectItemListQuery.data?.totalCount || 0,
      refetch: projectItemListQuery.refetch,
    };
  };

  const useGetProjectItem = (projectItemId) => {
    const getProjectItem = () => {
      return ProjectItemRepository.getProjectItem(projectItemId)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const projectItemQuery = useQuery({
      queryKey: ['projectItem', projectItemId],
      queryFn: getProjectItem,
      // projectItemId가 있을 때만 쿼리 실행
      enabled: !!projectItemId,
    });

    return {
      projectItemInfo: projectItemQuery.data || null,
      refetch: projectItemQuery.refetch,
    };
  };

  return { useGetProjectItemList, useGetProjectItem };
};

export default useProjectItem;
