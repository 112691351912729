import { useQuery } from '@tanstack/react-query';
import { CommonConstant } from 'constants/code.constant';
import AccountRepository from 'repositories/AccountRepository';
import CommonUtil from 'utils/common.util';

const useAccount = () => {
  const useGetAccountList = ({ page = 1, limit = 10, company, email, name, accountType, roleType, status }) => {
    const getAccountList = () => {
      const params = {
        offset: (page - 1) * limit,
        limit,
      };

      if (company) {
        params.company = company;
      }

      if (email) {
        params.email = email;
      }

      if (name) {
        params.name = name;
      }

      if (accountType) {
        params.accountType = accountType;
      }

      if (roleType) {
        params.roleType = roleType;
      }

      if (status) {
        params.status = status;
      }

      return AccountRepository.getAccountList(params)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const accountListQuery = useQuery({
      queryKey: ['accountList', { page, accountType }],
      queryFn: getAccountList,
    });

    return {
      list: accountListQuery.data?.list || [],
      totalCount: accountListQuery.data?.totalCount || 0,
      refetch: accountListQuery.refetch,
    };
  };

  const useGetAccount = (code) => {
    const getAccount = () => {
      return AccountRepository.getAccount(code)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const accountQuery = useQuery({
      queryKey: ['account', code],
      queryFn: getAccount,
      // code가 있을 때만 쿼리 실행
      enabled: !!code,
    });

    return {
      accountInfo: accountQuery.data || null,
      refetch: accountQuery.refetch,
    };
  };

  return {
    useGetAccountList,
    useGetAccount,
  };
};

export default useAccount;
