import { useQuery } from '@tanstack/react-query';
import { CommonConstant } from 'constants/code.constant';
import CompanyEvaluationRepository from 'repositories/CompanyEvaluationRepository';
import CommonUtil from 'utils/common.util';

const useCompanyEvaluation = () => {
  const useGetCompanyEvaluationList = () => {
    const getCompanyEvaluationList = () => {
      return CompanyEvaluationRepository.getCompanyEvaluationList()
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const companyEvaluationListQuery = useQuery({
      queryKey: ['companyEvaluationList'],
      queryFn: getCompanyEvaluationList,
    });

    return {
      list: companyEvaluationListQuery.data?.list || [],
      refetch: companyEvaluationListQuery.refetch,
    };
  };

  return { useGetCompanyEvaluationList };
};

export default useCompanyEvaluation;
