// server api routes
const API_HOST = process.env.REACT_APP_API_URL;
export const API_AUTH_ROUTE = API_HOST + '/auth';
export const API_HELP_ROUTE = API_HOST + '/help';
export const API_ACCOUNT_ROUTE = API_HOST + '/account';
export const API_PROJECT_ROUTE = API_HOST + '/project';
export const API_PROJECT_BUDGET_ROUTE = API_HOST + '/project-budget';
export const API_PROJECT_CLIENT_SELECT_ROUTE = API_HOST + '/project-client-select';
export const API_PROJECT_ITEM_ROUTE = API_HOST + '/project-item';
export const API_CONSULTING_ASSIGN_ROUTE = API_HOST + '/consulting-assign';
export const API_EVENT_ASSIGN_ROUTE = API_HOST + '/event-assign';
export const API_RESERVATION_TIME_SLOT_ROUTE = API_HOST + '/reservation-time-slot';
export const API_RESERVATION_ROUTE = API_HOST + '/reservation';
export const API_CONSULTING_REPORT_ROUTE = API_HOST + '/consulting-report';
export const API_SCHEDULE_ROUTE = API_HOST + '/schedule';
export const API_IR_DECK_ROUTE = API_HOST + '/ir-deck';
export const API_COMPANY_EVALUATION_ROUTE = API_HOST + '/company-evaluation';
