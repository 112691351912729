import cookie from 'react-cookies';
import { TokenConstant } from 'constants/code.constant';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AuthRepository from 'repositories/AuthRepository';
import AppRoute from 'routes';
import useAuthStore from 'store/auth';
import ModalContainer from 'components/modals';

const App = () => {
  const location = useLocation();
  const { zstSetAccount, zstLogout } = useAuthStore();
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  // 로그인 사용자 정보 확인
  const checkSession = async () => {
    if (cookie.load(TokenConstant.ACCESS_TOKEN_COOKIE)) {
      const res = await AuthRepository.checkToken();
      zstSetAccount(res?.data);
    } else {
      zstLogout();
    }
    setIsAuthChecked(true);
  };

  useEffect(() => {
    checkSession();
  }, [location.pathname]);

  return (
    isAuthChecked && (
      <>
        <AppRoute />
        <ModalContainer />
      </>
    )
  );
};

export default App;
