import {
  AccountStatusConstant,
  CommonConstant,
  RegexConstant,
  RoleTypeConstant,
  RoleTypeNameConstant,
} from 'constants/code.constant';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AuthRepository from 'repositories/AuthRepository';
import CommonUtil from 'utils/common.util';
import { ErrorCodeConstant } from 'constants/errorCode.constant';

const selectRoleType = [
  {
    value: '',
    label: '선택',
  },
  {
    value: RoleTypeConstant.COMPANY,
    label: RoleTypeNameConstant[RoleTypeConstant.COMPANY],
  },
  {
    value: RoleTypeConstant.CAREGIVER,
    label: RoleTypeNameConstant[RoleTypeConstant.CAREGIVER],
  },
  {
    value: RoleTypeConstant.EXPERT,
    label: RoleTypeNameConstant[RoleTypeConstant.EXPERT],
  },
  {
    value: RoleTypeConstant.PARTNER,
    label: RoleTypeNameConstant[RoleTypeConstant.PARTNER],
  },
];

const Signup = () => {
  const navigation = useNavigate();

  const defaultValues = {
    company: '',
    email: '',
    name: '',
    phoneNumber: '',
    password: '',
    passwordConfirm: '',
    roleType: '',
    isPrivacyConsentAgreed: '',
    status: AccountStatusConstant.PENDING,
  };

  const schemeObject = {
    company: yup.string().trim().required('기업명 / 소속명을 입력해 주세요'),
    email: yup
      .string()
      .trim()
      .required('이메일을 입력해 주세요')
      .matches(RegexConstant.email, '이메일 형식이 올바르지 않습니다'),
    name: yup.string().trim().required('이름을 입력해 주세요'),
    phoneNumber: yup
      .string()
      .trim()
      .required('전화번호를 입력해 주세요.')
      .matches(RegexConstant.phone, `전화번호 형식이 올바르지 않습니다('-'포함)`),
    password: yup.string().trim().required('비밀번호를 입력해 주세요'),
    passwordConfirm: yup
      .string()
      .trim()
      .required('비밀번호 확인을 입력해 주세요')
      .oneOf([yup.ref('password'), null], '비밀번호가 일치하지 않습니다'),
    roleType: yup.string().required('권한 신청을 선택해 주세요'),
  };

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const submitSignup = (formData) => {
    if (!formData.isPrivacyConsentAgreed) {
      alert('개인정보 동의를 하지 않으면 가입하실 수 없습니다');
      return;
    }

    if (!window.confirm('가입하시겠습니까?')) {
      return;
    }

    AuthRepository.signup(formData)
      .then(async (res) => {
        if (res.result === CommonConstant.OK) {
          alert('회원가입이 완료되었습니다. 로그인은 관리자에게 승인을 받으신 이후부터 사용이 가능합니다');
          navigation('/login');
        }

        if (res.result === CommonConstant.ERROR) {
          // 이미 회원가입 신청된 기업/소속
          if (res.errorCode === ErrorCodeConstant.ERROR_CODE.SIGNUP_PENDING_STATUS) {
            alert('해당 기업/소속은 신청중인 상태입니다. 관리자에게 문의해 주세요');
          }

          // 기업 중복
          if (res.errorCode === ErrorCodeConstant.ERROR_CODE.DUPLICATE_COMPANY) {
            alert('해당 기업/소속은 가입된 상태입니다');
          }

          // 이메일 중복
          if (res.errorCode === ErrorCodeConstant.ERROR_CODE.DUPLICATE_USER) {
            alert('해당 이메일은 이미 존재합니다');
          }
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  return (
    <div className="login-wrap">
      <div className="login-container signup-container">
        <h2>회원가입</h2>
        <br />
        <form noValidate autoComplete="off" onSubmit={handleSubmit(submitSignup)}>
          <Controller
            name={'email'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <>
                <label>담당자 이메일(아이디)</label>
                <input
                  type="text"
                  placeholder="담당자 이메일(아이디)"
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                />
                {errors.email && <p className="error">{errors.email.message}</p>}
              </>
            )}
          />
          <Controller
            name={'company'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <>
                <label>기업명 / 소속명</label>
                <input
                  type="text"
                  placeholder="기업명 / 소속명"
                  maxLength={100}
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                />
                {errors.company && <p className="error">{errors.company.message}</p>}
              </>
            )}
          />
          <Controller
            name={'name'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <>
                <label>담당자</label>
                <input type="text" placeholder="담당자" onBlur={onBlur} value={value} onChange={onChange} />
                {errors.name && <p className="error">{errors.name.message}</p>}
              </>
            )}
          />
          <Controller
            name={'phoneNumber'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <>
                <label>담당자 전화번호</label>
                <input type="text" placeholder="전화번호 '-' 포함" onBlur={onBlur} value={value} onChange={onChange} />
                {errors.phoneNumber && <p className="error">{errors.phoneNumber.message}</p>}
              </>
            )}
          />
          <Controller
            name={'password'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <>
                <label>비밀번호</label>
                <input
                  type="password"
                  placeholder="비밀번호"
                  autoComplete="off"
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                />
                {errors.password && <p className="error">{errors.password.message}</p>}
              </>
            )}
          />
          <Controller
            name={'passwordConfirm'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <>
                <label>비밀번호 확인</label>
                <input
                  type="password"
                  placeholder="비밀번호 확인"
                  autoComplete="off"
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                />
                {errors.passwordConfirm && <p className="error">{errors.passwordConfirm.message}</p>}
              </>
            )}
          />
          <Controller
            name={'roleType'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <>
                <label>권한 신청</label>
                <select onBlur={onBlur} value={value} onChange={onChange}>
                  {selectRoleType.map((role, index) => (
                    <option key={index} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
                {errors.roleType && <p className="error">{errors.roleType.message}</p>}
              </>
            )}
          />
          <label>개인정보 동의</label>
          <div className="privacy-consent-wrap">
            <p>
              <strong>민감정보 제공 동의서 및 활용 동의</strong>
            </p>
            <br />
            <div className="privacy-consent">
              <p>
                「개인정보보호법」에 따라 주민등록번호 등 수집을 원칙적으로 금지하되, 법령에서 민감정보의 처리를
                요구하거나 허용하는 경우, 정보주체에게 동의를 받아 수집을 가능하게 하고 있습니다.
              </p>
              <br />
              <p>「국세기본법」 시행령 제68조(민감정보 및 고유식별정보의 처리)</p>
              <ol>
                <li>
                  ① 세무공무원은 법 및 세법에 따른 국세에 관한 사무를 수행하기 위하여 불가피한 경우 「개인정보보호법」
                  제 23조에 따른 건강에 관한 정보 또는 같은 법 시행령 제18조 제2호에 따른 법죄경력자료에 해당하는 정보나
                  같은 령 제19조에 따른 주민등록번호, 여권번호, 운전면허의 면허번호 또는 외국인등록번호가 포함된 자료를
                  처리할 수 있다.
                </li>
                <br />
                <li>
                  ② 세법에 따른 원천징수의무자는 원천징수 사무를 수행하기 위하여 불가피한 경우 제1항에 따른 개인정보가
                  포함된 자료를 처리할 수 있다.
                </li>
              </ol>
              <p>
                위 사항에 근거하여 민감정보를 수집하고 있으며, 세무처리(소득세 신고)를 위하여 불가피한 사항입니다. 해당
                동의서의 민감정보는 세무처리와 대금지급을 위해서만 사용되오니, 제공에 동의바랍니다. ※ 동의를 하지 않는
                경우, 세무처리가 불가능하기에 기타소득&middot;사업소득을 지급할 수 없습니다.
              </p>
            </div>
          </div>
          <br />
          <div className="privacy-consent-agree">
            <Controller
              name={'isPrivacyConsentAgreed'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <div className="display-flex" onClick={() => onChange(true)}>
                    <input type="radio" name="agree" readOnly checked={value} />
                    &nbsp;
                    <p>동의함{value}</p>
                  </div>
                  &nbsp; &nbsp;
                  <div className="display-flex" onClick={() => onChange(false)}>
                    <input type="radio" name="agree" readOnly checked={value === '' ? false : !value} />
                    &nbsp;
                    <p>동의하지 않음</p>
                  </div>
                </>
              )}
            />
          </div>
          <br />
          <button type="submit">회원가입</button>
        </form>
        <br />
        <br />
        <div className="bottom-wrap">
          <Link to={'/login'}>로그인</Link>
          <Link to={'/help/email-inquiry'}>이메일 찾기</Link>
          <Link to={'/help/pw-inquiry'}>비밀번호 찾기</Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
