import TableSortHeader from 'components/table/TableSortHeader';
import { AccountStatusConstant, AccountTypeConstant, RoleTypeNameConstant } from 'constants/code.constant';
import { format } from 'date-fns';
import useAccount from 'hooks/useAccount';
import useSortTable from 'hooks/useSortTable';
import MainLayout from 'layouts/MainLayout';
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';

const headCells = [
  { id: '', label: '#', width: '5%', isSort: false },
  { id: 'code', label: '고유코드', width: '10%', isSort: false },
  { id: 'company', label: '기업명', isSort: false },
  { id: 'ceoName', label: '대표자명', width: '10%', isSort: false },
  { id: 'email', label: '담당자 이메일', width: '15%', isSort: false },
  { id: 'name', label: '담당자명', width: '10%', isSort: false },
  { id: 'roleType', label: '권한', width: '10%', isSort: true },
  { id: 'createdAt', label: '등록일', width: '10%', isSort: true },
  { id: '', label: '비고', width: '10%' },
];

const AdminClientList = () => {
  const [page, setPage] = useState(1);
  const limit = 50;
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const { sortBy, sortOrder, handleRequestSort } = useSortTable();

  const { useGetAccountList } = useAccount();
  const {
    list: clients,
    totalCount,
    refetch,
  } = useGetAccountList({
    page,
    limit,
    sortBy,
    sortOrder,
    company,
    email,
    name,
    accountType: AccountTypeConstant.CLIENT,
    status: AccountStatusConstant.ACTIVE,
  });

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    refetch();
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  return (
    <MainLayout>
      <div className="page-header">기업 목록</div>
      <div className="page-card">
        <form>
          <div className="table-search-wrap">
            <div>
              <label>기업명</label>
              <input type="text" placeholder="기업명" maxLength={100} onChange={(e) => setCompany(e.target.value)} />
            </div>
            <div>
              <label>담당자 이메일</label>
              <input
                type="text"
                placeholder="담당자 이메일"
                maxLength={100}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label>담당자명</label>
              <input type="text" placeholder="담당자명" maxLength={100} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="btn">
              <button className="btn" onClick={handleSearch}>
                검색
              </button>
            </div>
          </div>
        </form>
      </div>
      <br />
      <div className="page-card">
        <div className="table-wrap">
          <p>
            조회된 수: <strong>{totalCount}</strong>개
          </p>
          <table>
            <TableSortHeader
              sortBy={sortBy}
              sortOrder={sortOrder}
              handleRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <tbody>
              {totalCount === 0 && (
                <tr>
                  <td colSpan={headCells.length}>검색 결과가 없습니다.</td>
                </tr>
              )}
              {clients.map((item, index) => (
                <tr key={index}>
                  <td>{totalCount - (page - 1) * limit - index}</td>
                  <td>{item.code}</td>
                  <td>{item.company}</td>
                  <td>{item.ceoName}</td>
                  <td>{item.email}</td>
                  <td>{item.name}</td>
                  <td>{RoleTypeNameConstant[item.roleType]}</td>
                  <td>{format(item.createdAt, 'yyyy-MM-dd')}</td>
                  <td>
                    <Link to={`/admin/admin/detail?code=${item.code}`}>
                      <button className="btn edit-btn">수정</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalCount > 0 && (
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default AdminClientList;
