import ProjectItemDetailConsultingForm from 'components/project-item/ConsultingForm';
import ProjectItemDetailForm from 'components/project-item/DetailForm';
import ProjectItemDetailEventForm from 'components/project-item/EventForm';
import { ProjectItemTypeConstant } from 'constants/code.constant';
import MainLayout from 'layouts/MainLayout';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const AdminProjectItemDetail = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const projectItemId = searchParams.get('projectItemId');

  const [projectItemType, setProjectItemType] = useState(null);

  const idProps = {
    projectId,
    projectItemId,
  };

  return (
    <MainLayout>
      <div className="page-header">과업 {projectItemId ? '수정' : '등록'}</div>
      <ProjectItemDetailForm {...idProps} setProjectItemType={setProjectItemType} />
      {projectItemId ? (
        <>
          {projectItemType === ProjectItemTypeConstant.CONSULTING && <ProjectItemDetailConsultingForm {...idProps} />}
          {projectItemType === ProjectItemTypeConstant.EVENT && <ProjectItemDetailEventForm {...idProps} />}
        </>
      ) : (
        <h4>세부 정보 수정은 과업 최초 등록 이후 표시됩니다</h4>
      )}
    </MainLayout>
  );
};

export default AdminProjectItemDetail;
