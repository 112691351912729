import {
  AccountStatusConstant,
  AccountTypeConstant,
  CommonConstant,
  RegexConstant,
  RoleTypeConstant,
} from 'constants/code.constant';
import { ErrorCodeConstant } from 'constants/errorCode.constant';
import useAccount from 'hooks/useAccount';
import MainLayout from 'layouts/MainLayout';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AccountRepository from 'repositories/AccountRepository';
import CommonUtil from 'utils/common.util';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const AdminDetail = () => {
  const navigation = useNavigate();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const { useGetAccount } = useAccount();
  const { accountInfo, refetch } = useGetAccount(code);

  const [isChangePassword, setIsChangePassword] = useState(0);

  const defaultValues = {
    email: '',
    name: '',
    accountType: AccountTypeConstant.ADMIN,
    roleType: RoleTypeConstant.ADMIN,
    status: AccountStatusConstant.ACTIVE,
    password: '',
    passwordConfirm: '',
  };

  let schemeObject = {
    email: yup
      .string()
      .trim()
      .required('이메일을 입력해 주세요')
      .matches(RegexConstant.email, '이메일 형식이 올바르지 않습니다'),
    name: yup.string().trim().required('이름을 입력해 주세요'),
  };

  const schemeObjectPw = {
    password: yup.string().required('비밀번호를 입력해 주세요'),
    passwordConfirm: yup
      .string()
      .required('비밀번호를 확인해 주세요')
      .oneOf([yup.ref('password'), null], '비밀번호가 일치하지 않습니다'),
  };

  if (!code || isChangePassword) {
    schemeObject = { ...schemeObject, ...schemeObjectPw };
  }

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const saveAccount = (formData) => {
    if (!window.confirm('저장하시겠습니까?')) {
      return;
    }

    AccountRepository.saveAccount(formData)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('저장하였습니다');
          if (code) {
            refetch();
          } else {
            navigation(`/admin/admin/detail?code=${res.data.code}`, { replace: true });
          }
        }

        if (res.result === CommonConstant.ERROR) {
          if (res.errorCode === ErrorCodeConstant.ERROR_CODE.DUPLICATE_USER) {
            setError('email', { message: '이미 등록된 이메일입니다' });
          } else {
            console.error(res);
            alert('알 수 없는 에러 코드');
          }
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  useEffect(() => {
    if (accountInfo) {
      reset({ ...accountInfo });
    }
  }, [code, accountInfo]);

  return (
    <MainLayout>
      <div className="page-header">관리자 {code ? '수정' : '등록'}</div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(saveAccount)}>
        <div className="page-card">
          <div className="table-search-wrap detail-form-wrap">
            <Controller
              name={'email'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onBlur, value, onChange } }) => (
                <div>
                  <label>이메일</label>
                  <input
                    type="text"
                    placeholder="이메일"
                    maxLength={100}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                  />
                  {errors.email && <p className="error">{errors.email.message}</p>}
                </div>
              )}
            />
            <Controller
              name={'name'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onBlur, value, onChange } }) => (
                <div>
                  <label>이름</label>
                  <input
                    type="text"
                    placeholder="이름"
                    maxLength={100}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                  />
                  {errors.name && <p className="error">{errors.name.message}</p>}
                </div>
              )}
            />
            {code && (
              <>
                <div>
                  <label>비밀번호 재설정</label>
                  <input
                    type="checkbox"
                    value={0}
                    onChange={(e) => {
                      setIsChangePassword(e.target.checked ? CommonConstant.YES_NUMBER : CommonConstant.NO_NUMBER);
                    }}
                  />
                </div>
                <div></div>
              </>
            )}
            {(!code || (code && isChangePassword === CommonConstant.YES_NUMBER)) && (
              <>
                <Controller
                  name={'password'}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <div>
                      <label>비밀번호</label>
                      <input
                        type="password"
                        placeholder="비밀번호"
                        autoComplete="off"
                        maxLength={100}
                        onBlur={onBlur}
                        value={value || ''}
                        onChange={onChange}
                      />
                      {errors.password && <p className="error">{errors.password.message}</p>}
                    </div>
                  )}
                />
                <Controller
                  name={'passwordConfirm'}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <div>
                      <label>비밀번호 확인</label>
                      <input
                        type="password"
                        placeholder="비밀번호 확인"
                        autoComplete="off"
                        maxLength={100}
                        onBlur={onBlur}
                        value={value || ''}
                        onChange={onChange}
                      />
                      {errors.passwordConfirm && <p className="error">{errors.passwordConfirm.message}</p>}
                    </div>
                  )}
                />
              </>
            )}
          </div>
        </div>
        <br />
        <div className="display-flex space-between">
          <div>
            <Link to={'/admin/admin'}>
              <button className="btn success-btn" type="button">
                관리자 목록
              </button>
            </Link>
          </div>
          <button className="btn add-btn" type="submit">
            저장
          </button>
        </div>
      </form>
    </MainLayout>
  );
};

export default AdminDetail;
