import { API_EVENT_ASSIGN_ROUTE } from 'constants/api.constant';
import AxiosUtil from 'utils/axios.util';

const EventAssignRepository = {
  getEventAssignList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_EVENT_ASSIGN_ROUTE}/list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  getMyEventList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_EVENT_ASSIGN_ROUTE}/my-list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  saveEventAssign(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_EVENT_ASSIGN_ROUTE}`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  removeEventAssign(eventAssignId) {
    try {
      return AxiosUtil.delete(
        { url: `${API_EVENT_ASSIGN_ROUTE}/${eventAssignId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default EventAssignRepository;
