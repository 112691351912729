import MainLayout from 'layouts/MainLayout';

const AdminDashboard = () => {
  return (
    <MainLayout>
      <div>관리자 대시보드</div>
    </MainLayout>
  );
};

export default AdminDashboard;
