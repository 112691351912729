import { API_PROJECT_CLIENT_SELECT_ROUTE } from 'constants/api.constant';
import AxiosUtil from 'utils/axios.util';

const ProjectClientSelectRepository = {
  getProjectClientSelectList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_PROJECT_CLIENT_SELECT_ROUTE}/list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  addProjectClientSelect(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_PROJECT_CLIENT_SELECT_ROUTE}`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  removeProjectClientSelect(projectClientSelectId) {
    try {
      return AxiosUtil.delete(
        { url: `${API_PROJECT_CLIENT_SELECT_ROUTE}/${projectClientSelectId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default ProjectClientSelectRepository;
