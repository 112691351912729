import { API_PROJECT_ROUTE } from 'constants/api.constant';
import AxiosUtil from 'utils/axios.util';

const ProjectRepository = {
  getProjectList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_PROJECT_ROUTE}/list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  getProject(projectId) {
    try {
      return AxiosUtil.get(
        { url: `${API_PROJECT_ROUTE}/${projectId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  saveProject(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_PROJECT_ROUTE}`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  removeProject(projectId) {
    try {
      return AxiosUtil.delete(
        { url: `${API_PROJECT_ROUTE}/${projectId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default ProjectRepository;
