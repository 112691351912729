import { useQuery } from '@tanstack/react-query';
import { CommonConstant } from 'constants/code.constant';
import ReservationRepository from 'repositories/ReservationRepository';
import CommonUtil from 'utils/common.util';

const useReservation = () => {
  const useGetReservationList = ({ page = 1, limit = 10, consultantAssignId, consultantClientAssignId }) => {
    const getReservationList = () => {
      const params = {
        offset: (page - 1) * limit,
        limit,
      };

      if (consultantAssignId) {
        params.consultantAssignId = consultantAssignId;
      }

      if (consultantClientAssignId) {
        params.consultantClientAssignId = consultantClientAssignId;
      }

      return ReservationRepository.getReservationList(params)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const reservationListQuery = useQuery({
      queryKey: ['reservationList', { consultantAssignId, consultantClientAssignId, page }],
      queryFn: getReservationList,
    });

    return {
      list: reservationListQuery.data?.list || [],
      totalCount: reservationListQuery.data?.totalCount || 0,
      refetch: reservationListQuery.refetch,
    };
  };

  const useGetReservation = (reservationId) => {
    const getReservation = () => {
      return ReservationRepository.getReservation(reservationId)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const reservationQuery = useQuery({
      queryKey: ['reservation', reservationId],
      queryFn: getReservation,
      enabled: !!reservationId,
    });

    return {
      reservationInfo: reservationQuery.data || null,
      refetch: reservationQuery.refetch,
    };
  };

  const changeReservationStatus = ({ reservationId, status, changeSuccessCallback }) => {
    ReservationRepository.changeReservationStatus({ reservationId, status })
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          changeSuccessCallback();
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  return {
    useGetReservationList,
    useGetReservation,
    changeReservationStatus,
  };
};

export default useReservation;
