import { FileUploadButton } from 'components/consulting-report';
import { CommonConstant, FileUploadKey } from 'constants/code.constant';
import useConsultingReport from 'hooks/useConsultingReport';
import useReservation from 'hooks/useReservation';
import MainLayout from 'layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CommonUtil from 'utils/common.util';
import ConsultingReportRepository from 'repositories/ConsultingReportRepository';
import parse from 'html-react-parser';

const selectOptionList = {
  isSubmit: [
    {
      value: CommonConstant.NO_NUMBER,
      label: '미제출',
    },
    {
      value: CommonConstant.YES_NUMBER,
      label: '제출',
    },
  ],
};

const AdminConsultingReportDetail = () => {
  const [searchParams] = useSearchParams();
  const reservationId = searchParams.get('reservationId');
  const consultingReportId = searchParams.get('consultingReportId');

  const { useGetReservation } = useReservation();
  const { reservationInfo } = useGetReservation(reservationId);

  const { useGetConsultingReport } = useConsultingReport();
  const { consultingReportInfo, refetch: refetchConsultingReport } = useGetConsultingReport(consultingReportId);

  // 이미지 파일 정보
  const [reportAttachmentImages, setReportAttachmentImages] = useState([]);

  // 첨부 파일 정보
  const [reportAttachmentFiles, setReportAttachmentFiles] = useState([]);

  const defaultValues = {
    [FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE]: [],
    [FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE]: [],
    isSubmit: CommonConstant.YES_NUMBER,
  };

  let schemeObject = {};

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const saveConsultingReport = () => {
    if (!window.confirm('저장하시겠습니까?')) {
      return;
    }

    const saveData = { ...getValues() };

    const formData = new FormData();

    for (let key in saveData) {
      formData.append(key, saveData[key]);
    }

    // 이미지 파일
    const reportAttachmentImages = saveData[FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE];

    for (let i = 0; i < reportAttachmentImages.length; i++) {
      formData.append([FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE], reportAttachmentImages[i]);
    }

    // 첨부 파일
    const reportAttachmentFiles = saveData[FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE];

    for (let i = 0; i < reportAttachmentFiles.length; i++) {
      formData.append([FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE], reportAttachmentFiles[i]);
    }

    ConsultingReportRepository.saveConsultingReport(formData)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('저장하였습니다');

          reset();
          refetchConsultingReport();
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  const removeConsultingReportAttachment = (consultingReportAttachmentId) => {
    if (!window.confirm('파일을 삭제하시겠습니까?')) {
      return false;
    }

    ConsultingReportRepository.removeConsultingReportAttachment(consultingReportAttachmentId)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          const filterImages = reportAttachmentImages.filter(
            (item) => item.consultingReportAttachmentId !== consultingReportAttachmentId,
          );
          setReportAttachmentImages(filterImages);

          const filterFiles = reportAttachmentFiles.filter(
            (item) => item.consultingReportAttachmentId !== consultingReportAttachmentId,
          );
          setReportAttachmentFiles(filterFiles);

          alert('파일을 삭제하였습니다');
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  useEffect(() => {
    if (reservationInfo) {
      reset({ ...getValues(), ...reservationInfo });
    }

    if (consultingReportInfo) {
      reset({ ...getValues(), ...consultingReportInfo });
      setReportAttachmentImages(consultingReportInfo.reportAttachmentImages);
      setReportAttachmentFiles(consultingReportInfo.reportAttachmentFiles);
    }
  }, [reservationId, reservationInfo, consultingReportId, consultingReportInfo]);

  return (
    <MainLayout>
      <div className="page-header">보고서 상세</div>
      {consultingReportInfo?.orgFileUrl && (
        <>
          <div>
            <button className="btn add-btn" onClick={() => window.open(consultingReportInfo.orgFileUrl, '_blank')}>
              보고서 파일 다운로드
            </button>
          </div>
          <br />
        </>
      )}
      <div className="page-card">
        <h3 className="text-center">{reservationInfo?.projectName}</h3>
        <br />
        <div className="table-search-wrap detail-form-wrap">
          <div>
            <label>기업 | 소속</label>
            <input type="text" value={reservationInfo?.clientCompany || ''} disabled />
          </div>
          <div>
            <label>이름</label>
            <input type="text" value={reservationInfo?.clientName || ''} disabled />
          </div>
          <div>
            <label>컨설팅 일자</label>
            <input type="text" value={consultingReportInfo?.date || ''} disabled />
          </div>
          <div>
            <label>컨설팅 시간</label>
            <input type="text" value={consultingReportInfo?.time || ''} disabled />
          </div>
          <div>
            <label>컨설팅 주제</label>
            <input type="text" value={consultingReportInfo?.subject || ''} disabled />
          </div>
          <div>
            <label>장소</label>
            <input type="text" value={consultingReportInfo?.place || ''} disabled />
          </div>
          <div>
            <label>상담자 | 소속</label>
            <input type="text" value={reservationInfo?.consultantCompany || ''} disabled />
          </div>
          <div>
            <label>이름</label>
            <input type="text" value={reservationInfo?.consultantName || ''} disabled />
          </div>
          <div />
          <Controller
            name={'isSubmit'}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <div>
                <label>제출 여부</label>
                <select value={value} onChange={onChange}>
                  {selectOptionList.isSubmit.map((role, index) => (
                    <option key={index} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
          />
        </div>
        <br />
        <h3 className="text-center">주요 컨설팅 내용</h3>
        <br />
        <div className="ql-editor" style={{ border: '1px solid #888888' }}>
          {parse(consultingReportInfo?.content || '')}
        </div>
      </div>
      <br />
      <br />
      <div>
        <FileUploadButton
          className={'success-btn'}
          label={'이미지 파일 업로드'}
          accept={'image/jpg, image/jpeg, image/png'}
          onChange={(file, url) => {
            if (reportAttachmentImages.length === 10) {
              return;
            }

            setValue(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE, [
              ...getValues(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE),
              file,
            ]);

            setReportAttachmentImages([
              ...reportAttachmentImages,
              {
                index: getValues(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE).length - 1,
                url,
              },
            ]);
          }}
        />
      </div>
      <br />
      <div className="page-card">
        <div className="image-upload-list-wrap">
          {reportAttachmentImages.map((item, index) => (
            <div key={index} className="item">
              <img
                className={`${item.consultingReportAttachmentId ? 'server-file' : ''}`}
                src={item.url}
                alt=""
                onClick={() => {
                  if (item.consultingReportAttachmentId) {
                    window.open(item.orgFileUrl, '_blank');
                  }
                }}
              />
              <p
                className="error"
                onClick={() => {
                  if (item.consultingReportAttachmentId) {
                    removeConsultingReportAttachment(item.consultingReportAttachmentId);
                  } else {
                    const filterImages = reportAttachmentImages.filter((filter) => filter.url !== item.url);
                    setReportAttachmentImages(filterImages);

                    const formImages = getValues(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE);
                    formImages.splice(item.index, 1);

                    setValue(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_IMAGE, formImages);
                  }
                }}
              >
                &times;
              </p>
            </div>
          ))}
        </div>
      </div>
      <br />
      <br />
      <div>
        <FileUploadButton
          className={'success-btn'}
          label={'첨부 파일 업로드'}
          onChange={(file) => {
            if (reportAttachmentFiles.length === 10) {
              return;
            }

            setValue(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE, [
              ...getValues(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE),
              file,
            ]);

            setReportAttachmentFiles([
              ...reportAttachmentFiles,
              {
                index: getValues(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE).length - 1,
                fileName: file.name,
              },
            ]);
          }}
        />
      </div>
      <br />
      <div className="page-card">
        <div className="file-upload-list-wrap">
          {reportAttachmentFiles.map((item, index) => (
            <div key={index}>
              <span
                className={`title ${item.consultingReportAttachmentId ? 'link' : ''}`}
                onClick={() => {
                  if (item.consultingReportAttachmentId) {
                    CommonUtil.fileDownload(item.orgFileUrl, item.fileName);
                  }
                }}
              >
                {item.fileName}
              </span>
              <span
                className="delete error"
                onClick={() => {
                  if (item.consultingReportAttachmentId) {
                    removeConsultingReportAttachment(item.consultingReportAttachmentId);
                  } else {
                    const filterFiles = reportAttachmentFiles.filter((filter) => filter.fileName !== item.fileName);
                    setReportAttachmentFiles(filterFiles);

                    const formFiles = getValues(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE);
                    formFiles.splice(item.index, 1);

                    setValue(FileUploadKey.CONSULTING_REPORT_ATTACHMENT_FILE, formFiles);
                  }
                }}
              >
                &times;
              </span>
            </div>
          ))}
        </div>
      </div>
      <br />
      <div className="display-flex space-between">
        <div />
        <button className="btn edit-btn" type="button" onClick={saveConsultingReport}>
          저장
        </button>
      </div>
    </MainLayout>
  );
};

export default AdminConsultingReportDetail;
