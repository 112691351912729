import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'pages/App';
import { BrowserRouter } from 'react-router-dom';
import 'assets/css/style.css';
import 'assets/css/font.css';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

// QueryClient 인스턴스 생성
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </QueryClientProvider>,
);
