import { API_SCHEDULE_ROUTE } from 'constants/api.constant';
import AxiosUtil from 'utils/axios.util';

const ScheduleRepository = {
  getScheduleList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_SCHEDULE_ROUTE}/list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  getMyScheduleList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_SCHEDULE_ROUTE}/my-list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  getSchedule(scheduleId) {
    try {
      return AxiosUtil.get(
        { url: `${API_SCHEDULE_ROUTE}/${scheduleId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  saveSchedule(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_SCHEDULE_ROUTE}`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  removeSchedule(scheduleId) {
    try {
      return AxiosUtil.delete(
        { url: `${API_SCHEDULE_ROUTE}/${scheduleId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default ScheduleRepository;
