import ConsultingReport from 'components/consulting-report';
import { useSearchParams } from 'react-router-dom';
const ConsultantConsultingReport = () => {
  const [searchParams] = useSearchParams();
  const reservationId = searchParams.get('reservationId');

  return <ConsultingReport reservationId={reservationId} />;
};

export default ConsultantConsultingReport;
