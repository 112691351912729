import TableSortHeader from 'components/table/TableSortHeader';
import { AccountTypeConstant, RoleTypeConstant } from 'constants/code.constant';
import useAccount from 'hooks/useAccount';
import useModal from 'hooks/useModal';
import useSortTable from 'hooks/useSortTable';
import { useState } from 'react';
import Pagination from 'react-js-pagination';

const headCells = [
  { label: '#', width: '5%' },
  { label: '이메일' },
  { label: '이름', width: '20%' },
  { label: '선택', width: '10%' },
];

const ProjectItemManagerSelectModal = ({ managerSelectCallback, selectedAccountId }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const limit = 10;

  const { closeModal } = useModal();
  const { sortBy, sortOrder, handleRequestSort } = useSortTable();
  const { useGetAccountList } = useAccount();

  const {
    list: accountList,
    totalCount,
    refetch,
  } = useGetAccountList({
    page,
    limit,
    sortBy,
    sortOrder,
    email,
    name,
    accountType: AccountTypeConstant.ADMIN,
    roleType: RoleTypeConstant.ADMIN,
  });

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    refetch();
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  return (
    <div className="project-item-manager-select-modal">
      <p className="title">담당자 선택</p>
      <form>
        <div className="table-search-wrap">
          <div>
            <label>이메일</label>
            <input type="text" placeholder="이메일" maxLength={100} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div>
            <label>이름</label>
            <input type="text" placeholder="이름" maxLength={100} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="btn text-left">
            <button className="btn" onClick={handleSearch}>
              검색
            </button>
          </div>
        </div>
      </form>
      <br />
      <div className="table-wrap">
        <p className="text-left">
          조회된 수: <strong>{totalCount}</strong>개
        </p>
        <table>
          <TableSortHeader
            sortBy={sortBy}
            sortOrder={sortOrder}
            handleRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <tbody>
            {totalCount === 0 && (
              <tr>
                <td colSpan={headCells.length}>검색 결과가 없습니다.</td>
              </tr>
            )}
            {accountList.map((item, index) => (
              <tr key={index}>
                <td>{totalCount - (page - 1) * limit - index}</td>
                <td>{item.email}</td>
                <td>{item.name}</td>
                <td>
                  {selectedAccountId === item.accountId ? (
                    <button
                      className="btn error-btn"
                      onClick={() => {
                        managerSelectCallback({});
                        closeModal();
                      }}
                    >
                      해제
                    </button>
                  ) : (
                    <button
                      className="btn add-btn"
                      onClick={() => {
                        managerSelectCallback(item);
                        closeModal();
                      }}
                    >
                      선택
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {totalCount > 0 && (
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={totalCount}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectItemManagerSelectModal;
