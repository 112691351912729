import { RouteConstant } from 'constants/route';
import { useMemo } from 'react';

/**
 * routes 목록에서 accountType와 일치하는 객체를 반환
 * @param {*} routes
 * @param {*} accountType
 * @returns
 */
const filterRoute = (routes, accountType) => {
  return routes.reduce((acc, route) => {
    const { auth, accountType: routeAccountType, children } = route;

    // 경로 포함 여부 결정
    const isPublic = !auth;

    // accountType 배열로 변환, accountType의 값이 하나라도 routeAccountType에 있는지 검사
    const isProtected =
      auth &&
      accountType &&
      accountType
        .split(', ')
        .map(Number)
        .some((item) => routeAccountType.includes(item));

    if (isPublic || isProtected) {
      const filteredRoute = { ...route };

      // 자식 경로가 있을 경우 재귀적으로 필터링
      if (children) {
        filteredRoute.children = filterRoute(children, accountType);
      }

      acc.push(filteredRoute);
    }

    return acc;
  }, []);
};

const useAccessibleRoute = (accountType) => {
  const accessibleRoutes = useMemo(() => {
    return filterRoute(RouteConstant, accountType);
  }, [accountType]);

  return accessibleRoutes;
};

export default useAccessibleRoute;
