import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CommonConstant, RegexConstant } from 'constants/code.constant';
import HelpRepository from 'repositories/HelpRepository';
import CommonUtil from 'utils/common.util';
import { ErrorCodeConstant } from 'constants/errorCode.constant';

const EmailInquiry = () => {
  const defaultValues = {
    company: '',
    phoneNumber: '',
  };

  const schemeObject = {
    company: yup.string().trim().required('기업명 / 소속명을 입력해 주세요'),
    phoneNumber: yup
      .string()
      .trim()
      .required('전화번호를 입력해 주세요.')
      .matches(RegexConstant.phone, `전화번호 형식이 올바르지 않습니다('-'포함)`),
  };

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const submitFindEmail = (formData) => {
    HelpRepository.findEmail(formData)
      .then(async (res) => {
        if (res.result === CommonConstant.OK) {
          alert(`이메일 정보: ${res.data.email}`);
        }

        if (res.result === CommonConstant.ERROR) {
          // 계정이 일치하지 않는 경우
          if (res.errorCode === ErrorCodeConstant.ERROR_CODE.NOT_USER) {
            alert('정보가 일치하지 않습니다');
          }
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  return (
    <div className="login-wrap">
      <div className="login-container">
        <h2>이메일 찾기</h2>
        <br />
        <form noValidate autoComplete="off" onSubmit={handleSubmit(submitFindEmail)}>
          <Controller
            name={'company'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <>
                <label>기업명 / 소속명</label>
                <input
                  type="text"
                  placeholder="기업명 / 소속명"
                  maxLength={100}
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                />
                {errors.company && <p className="error">{errors.company.message}</p>}
              </>
            )}
          />
          <Controller
            name={'phoneNumber'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <>
                <label>담당자 전화번호</label>
                <input type="text" placeholder="전화번호 '-' 포함" onBlur={onBlur} value={value} onChange={onChange} />
                {errors.phoneNumber && <p className="error">{errors.phoneNumber.message}</p>}
              </>
            )}
          />
          <br />
          <button type="submit">이메일 찾기</button>
        </form>
        <br />
        <br />
        <div className="bottom-wrap">
          <Link to={'/signup'}>회원가입</Link>
          <Link to={'/login'}>로그인</Link>
          <Link to={'/help/pw-inquiry'}>비밀번호 찾기</Link>
        </div>
      </div>
    </div>
  );
};

export default EmailInquiry;
