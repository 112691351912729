import MainLayout from 'layouts/MainLayout';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { ClipLoader } from 'react-spinners';
import IrRepository from 'repositories/IrRepository';
import CommonUtil from 'utils/common.util';
import { CommonConstant } from 'constants/code.constant';

const selectTemplateNum = [
  {
    value: 1,
    imgElement: <img src={require('assets/images/pptx_1_thumbnail.png')} alt="" />,
  },
  {
    value: 2,
    imgElement: <img src={require('assets/images/pptx_2_thumbnail.png')} alt="" />,
  },
];

const AdminIRDeckGenerate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [irDeckFileUrl, setIrDeckFileUrl] = useState(null);

  const defaultValues = {
    company: '',
    companyIntroduction: '',
    problem: '',
    solution: '',
    scaleUp: '',
    team: '',
    templateNum: 1,
  };

  let schemeObject = {
    company: yup.string().trim().required('기업명을 입력해 주세요'),
    companyIntroduction: yup.string().trim().required('기업 소개를 입력해 주세요'),
  };

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const handleIRSubmit = (formData) => {
    if (isLoading) {
      alert('생성 중입니다. 잠시만 기다려주세요');
      return;
    } else {
      if (!window.confirm('생성하시겠습니까?')) {
        return;
      }
    }

    setIsLoading(true);
    setIrDeckFileUrl(null);

    IrRepository.IrDeckGenerate(formData)
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          setIrDeckFileUrl(res.data.pptxUrl);
          alert('생성이 완료되었습니다');
        } else {
          console.error(res);
          alert('오류가 발생했습니다');
        }
      })
      .catch(CommonUtil.repoErrFunc)
      .finally(() => setIsLoading(false));
  };

  return (
    <MainLayout>
      <div className="page-header">IR 덱 생성</div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(handleIRSubmit)}>
        <div className="page-card">
          <div className="table-search-wrap detail-form-wrap">
            <Controller
              name={'company'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onBlur, value, onChange } }) => (
                <div>
                  <label>기업명</label>
                  <input
                    type="text"
                    placeholder="기업명"
                    maxLength={100}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    disabled={isLoading}
                  />
                  {errors.company && <p className="error">{errors.company.message}</p>}
                </div>
              )}
            />
            <Controller
              name={'companyIntroduction'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onBlur, value, onChange } }) => (
                <div>
                  <label>기업 소개</label>
                  <input
                    type="text"
                    placeholder="기업 소개"
                    maxLength={200}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    disabled={isLoading}
                  />
                  {errors.companyIntroduction && <p className="error">{errors.companyIntroduction.message}</p>}
                </div>
              )}
            />
            <Controller
              name={'problem'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <div>
                  <label>문제</label>
                  <TextareaAutosize placeholder="문제" value={value} onChange={onChange} disabled={isLoading} />
                </div>
              )}
            />
            <Controller
              name={'solution'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <div>
                  <label>솔루션</label>
                  <TextareaAutosize placeholder="솔루션" value={value} onChange={onChange} disabled={isLoading} />
                </div>
              )}
            />
            <Controller
              name={'scaleUp'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <div>
                  <label>스케일업</label>
                  <TextareaAutosize placeholder="스케일업" value={value} onChange={onChange} disabled={isLoading} />
                </div>
              )}
            />
            <Controller
              name={'team'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <div>
                  <label>팀</label>
                  <TextareaAutosize placeholder="팀" value={value} onChange={onChange} disabled={isLoading} />
                </div>
              )}
            />
          </div>
          <div className="ir-pptx-select">
            <Controller
              name={'templateNum'}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <>
                  <label>템플릿 선택</label>
                  <div className="img-list">
                    {selectTemplateNum.map((item, index) => (
                      <div key={index} className="item" onClick={() => onChange(item.value)}>
                        {item.imgElement}
                        {value === item.value && (
                          <>
                            <div className="blur-overlay" />
                            <div className="check" />
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              )}
            />
          </div>
        </div>
        <br />
        <div className="display-flex space-between">
          <div>
            <button className={`btn ${isLoading ? 'disabled-btn' : 'add-btn'}`} type="submit">
              생성
              {isLoading && '중'}
            </button>
            &nbsp;&nbsp;
            {irDeckFileUrl && (
              <button
                className="btn success-btn"
                type="button"
                onClick={() => CommonUtil.fileDownload(irDeckFileUrl, `${getValues('company')}.pptx`)}
              >
                파일 다운로드
              </button>
            )}
          </div>
        </div>
        <br />
        {isLoading && (
          <>
            <div className="loading">
              <ClipLoader />
            </div>
          </>
        )}
      </form>
    </MainLayout>
  );
};

export default AdminIRDeckGenerate;
