import { Route, Routes } from 'react-router-dom';
import useAccessibleRoute from 'hooks/useAccessibleRoute';
import useAuthStore from 'store/auth';

const AppRoute = () => {
  const { zstAccountInfo } = useAuthStore();
  const accessibleRoutes = useAccessibleRoute(zstAccountInfo.accountType);

  return (
    <Routes>
      {accessibleRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element}>
          {route.children &&
            route.children.map((childRoute, index) => (
              <Route key={index} path={`${route.path}${childRoute.path}`} element={childRoute.element} />
            ))}
        </Route>
      ))}
    </Routes>
  );
};

export default AppRoute;
