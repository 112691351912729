import useModalStore from 'store/modal';

const useModal = () => {
  const { zstOpenModal, zstCloseModal } = useModalStore();

  const openModal = (modalName, props = {}) => {
    zstOpenModal({
      modalName,
      props,
    });

    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    zstCloseModal();

    document.body.style.overflow = 'unset';
  };

  return { openModal, closeModal };
};

export default useModal;
