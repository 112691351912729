import { useQuery } from '@tanstack/react-query';
import { CommonConstant } from 'constants/code.constant';
import ConsultingReportRepository from 'repositories/ConsultingReportRepository';
import CommonUtil from 'utils/common.util';

const useConsultingReport = () => {
  const useGetConsultingReportList = ({
    page = 1,
    limit = 10,
    projectName,
    projectItemName,
    consultingName,
    consultantName,
    clientCompany,
  }) => {
    const getConsultingReportList = () => {
      const params = {
        offset: (page - 1) * limit,
        limit,
      };

      if (projectName) {
        params.projectName = projectName;
      }

      if (projectItemName) {
        params.projectItemName = projectItemName;
      }

      if (consultingName) {
        params.consultingName = consultingName;
      }

      if (consultantName) {
        params.consultantName = consultantName;
      }

      if (clientCompany) {
        params.clientCompany = clientCompany;
      }

      return ConsultingReportRepository.getConsultingReportList(params)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const consultingReportListQuery = useQuery({
      queryKey: ['consultingReportListQuery', { page }],
      queryFn: getConsultingReportList,
    });

    return {
      list: consultingReportListQuery.data?.list || [],
      totalCount: consultingReportListQuery.data?.totalCount || 0,
      refetch: consultingReportListQuery.refetch,
    };
  };

  const useGetConsultingReport = (consultingReportId) => {
    const getConsultingReport = () => {
      return ConsultingReportRepository.getConsultingReport(consultingReportId)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const consultingReportQuery = useQuery({
      queryKey: ['consultingReport', consultingReportId],
      queryFn: getConsultingReport,
      enabled: !!consultingReportId,
    });

    return {
      consultingReportInfo: consultingReportQuery.data || null,
      refetch: consultingReportQuery.refetch,
    };
  };

  return { useGetConsultingReportList, useGetConsultingReport };
};

export default useConsultingReport;
