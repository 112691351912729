import { CommonConstant } from 'constants/code.constant';
import { ErrorCodeConstant } from 'constants/errorCode.constant';
import React, { useEffect, useState } from 'react';
import ReservationRepository from 'repositories/ReservationRepository';
import CommonUtil from 'utils/common.util';

// 30분 단위로 시간대 생성
const generateHalfHourlyTimeSlotList = (startHour = 6, endHour = 24, clientReservationTimeSlots = []) => {
  const timeSlots = [];

  for (let hour = startHour; hour < endHour; hour++) {
    const startHourStr = hour.toString().padStart(2, '0');
    const nextHourStr = (hour + 1).toString().padStart(2, '0');

    // 30분 단위 시간대
    const slot1Start = `${startHourStr}:00`;
    const slot1End = `${startHourStr}:30`;
    const slot2Start = `${startHourStr}:30`;
    const slot2End = `${nextHourStr}:00`;

    // 예약된 시간대와 비교하여 비활성화 여부와 ID 결정
    const slot1Data = clientReservationTimeSlots.find(
      (slot) => slot.startTime === slot1Start && slot.endTime === slot1End,
    );
    const slot2Data = clientReservationTimeSlots.find(
      (slot) => slot.startTime === slot2Start && slot.endTime === slot2End,
    );

    // 새로운 배열에 시간대, 비활성화 여부, ID 포함
    timeSlots.push({
      startAt: slot1Start,
      endAt: slot1End,
      isDisabled: slot1Data && slot1Data.reservationTimeSlotId ? slot1Data.isDisabled : 1,
      reservationTimeSlotId: slot1Data ? slot1Data.reservationTimeSlotId : null,
    });

    timeSlots.push({
      startAt: slot2Start,
      endAt: slot2End,
      isDisabled: slot2Data && slot2Data.reservationTimeSlotId ? slot2Data.isDisabled : 1,
      reservationTimeSlotId: slot2Data ? slot2Data.reservationTimeSlotId : null,
    });
  }

  return timeSlots;
};

const ClientReservationTimeSlot = React.memo(
  ({
    selectedDate,
    clientReservationTimeSlots = [],
    consultantAssignId,
    consultantClientAssignId,
    successCallback,
  }) => {
    const timeSlots = generateHalfHourlyTimeSlotList(6, 24, clientReservationTimeSlots);

    const [selectedTimeSlotIds, setSelectedTimeSlotIds] = useState([]);

    // 시간대 선택/해제
    const toggleSlotSelection = (reservationTimeSlotId) => {
      setSelectedTimeSlotIds((prev) =>
        prev.includes(reservationTimeSlotId)
          ? prev.filter((id) => id !== reservationTimeSlotId)
          : [...prev, reservationTimeSlotId],
      );
    };

    const saveReservation = () => {
      if (selectedTimeSlotIds.length === 0) {
        alert('예약 시간을 선택해 주세요');
        return;
      }

      if (!window.confirm('예약 신청 하시겠습니까? 예약 확정은 컨설턴트가 완료해야 합니다')) {
        return;
      }

      const data = {
        selectedTimeSlotIds: selectedTimeSlotIds.sort((a, b) => {
          return a - b;
        }),
        consultantAssignId,
        consultantClientAssignId,
      };

      ReservationRepository.saveReservation(data)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            alert('신청이 완료되었습니다');
            successCallback();
            setSelectedTimeSlotIds([]);
            return;
          }

          if (res.errorCode === ErrorCodeConstant.ERROR_CODE.EXCEEDED_REQUIRED_SCHEDULE_COUNT) {
            alert('예약 신청 수를 초과 하였습니다 컨설턴트에게 문의하세요');
          } else {
            console.error(res);
            alert('알 수 없는 에러 코드');
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    useEffect(() => {
      setSelectedTimeSlotIds([]);
    }, [selectedDate]);

    return (
      <div className="reservation-time-slot-wrap unselectable">
        <div className="reservation-time-slot-header">
          <p>컨설팅 예약</p>
          <br />
          <p>{selectedDate}</p>
        </div>
        <div className="reservation-time-slot-body">
          {timeSlots.map((item, index) => (
            <div
              key={index}
              className={`item ${item.isDisabled ? 'disabled' : ''} ${selectedTimeSlotIds.includes(item.reservationTimeSlotId) ? 'selected' : ''}`}
              onClick={() => toggleSlotSelection(item.reservationTimeSlotId)}
            >
              {item.startAt} ~ {item.endAt}
            </div>
          ))}
        </div>
        <div className="reservation-time-slot-bottom">
          <button className="btn add-btn" onClick={saveReservation}>
            예약
          </button>
        </div>
      </div>
    );
  },
);

export default ClientReservationTimeSlot;
