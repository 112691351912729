import cookie from 'react-cookies';
import { IoLogOutOutline } from 'react-icons/io5';
import { RiAdminLine } from 'react-icons/ri';
import { FaRegUser } from 'react-icons/fa6';
import { FaUserTie } from 'react-icons/fa6';
import useAuthStore from 'store/auth';
import AuthRepository from 'repositories/AuthRepository';
import { AccountTypeConstant, CommonConstant, RoleTypeNameConstant, TokenConstant } from 'constants/code.constant';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const Header = React.memo(() => {
  const navigate = useNavigate();
  const { zstAccountInfo, zstLogout } = useAuthStore();

  const handleLogout = () => {
    AuthRepository.logout().then((res) => {
      if (res.result === CommonConstant.OK) {
        cookie.remove(TokenConstant.ACCESS_TOKEN_COOKIE, { path: '/' });
        cookie.remove(TokenConstant.REFRESH_TOKEN_COOKIE, { path: '/' });

        zstLogout();
        navigate('/login');
      } else {
        console.error(res);
      }
    });
  };

  return (
    <div className="header-wrap">
      <div className="left">
        {zstAccountInfo.accountType === AccountTypeConstant.ADMIN && <RiAdminLine size={'25'} />}
        {zstAccountInfo.accountType === AccountTypeConstant.CLIENT && <FaRegUser size={'25'} />}
        {zstAccountInfo.accountType === AccountTypeConstant.CONSULTANT && <FaUserTie size={'25'} />}
        <p>
          <strong>{zstAccountInfo.name}</strong> 님
          <br />
          권한 유형: {RoleTypeNameConstant[zstAccountInfo.roleType]}
        </p>
        <br />
      </div>
      <div className="right" onClick={handleLogout}>
        <IoLogOutOutline size={'25'} />
        <p>로그아웃</p>
      </div>
    </div>
  );
});

export default Header;
