import { AccountTypeConstant } from 'constants/code.constant';

import Error404 from 'pages/404';

import Login from 'pages/login';
import Signup from 'pages/signup';

import EmailInquiry from 'pages/help/emailInquiry';
import PwInquiry from 'pages/help/pwInquiry';
import { AdminRoute } from './admin.route';
import { ConsultantRoute } from './consultant.route';
import { ClientRoute } from './client.route';

export const RouteConstant = [
  {
    path: '/',
    element: <Login />,
    auth: false,
  },
  // 404 페이지
  {
    path: '*',
    element: <Error404 />,
    auth: false,
  },
  {
    path: '/login',
    element: <Login />,
    auth: false,
  },
  {
    path: '/signup',
    element: <Signup />,
    auth: false,
  },
  {
    path: '/help',
    auth: false,
    children: [
      {
        path: '/email-inquiry',
        element: <EmailInquiry />,
      },
      {
        path: '/pw-inquiry',
        element: <PwInquiry />,
      },
    ],
  },
  {
    path: '/admin',
    auth: true,
    accountType: [AccountTypeConstant.ADMIN],
    children: AdminRoute,
  },
  {
    path: '/consultant',
    auth: true,
    accountType: [AccountTypeConstant.CONSULTANT],
    children: ConsultantRoute,
  },
  {
    path: '/client',
    auth: true,
    accountType: [AccountTypeConstant.CLIENT],
    children: ClientRoute,
  },
];
