import TableSortHeader from 'components/table/TableSortHeader';
import useEventAssign from 'hooks/useEventAssign';
import useSortTable from 'hooks/useSortTable';
import MainLayout from 'layouts/MainLayout';
import { useState } from 'react';
import Pagination from 'react-js-pagination';

const headCells = [
  { label: '#', width: '10%' },
  { label: '행사명' },
  { label: '장소', width: '15%' },
  { label: '기간', width: '30%' },
];

const ClientEvent = () => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const [eventName, setEventName] = useState('');
  const [place, setPlace] = useState('');

  const { sortBy, sortOrder, handleRequestSort } = useSortTable();

  const { useGetMyEventList } = useEventAssign();
  const {
    list: myEvents,
    totalCount,
    refetch,
  } = useGetMyEventList({
    page,
    limit,
    eventName,
    place,
  });

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    refetch();
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  return (
    <MainLayout>
      <div className="page-header">행사 목록</div>
      <div className="page-card">
        <form>
          <div className="table-search-wrap">
            <div>
              <label>행사명</label>
              <input
                type="text"
                placeholder="행사명"
                maxLength={100}
                onChange={(e) => setEventName(e.target.value)}
              />
            </div>
            <div>
              <label>장소</label>
              <input type="text" placeholder="장소" maxLength={100} onChange={(e) => setPlace(e.target.value)} />
            </div>
            <div className="btn">
              <button className="btn" onClick={handleSearch}>
                검색
              </button>
            </div>
          </div>
        </form>
      </div>
      <br />
      <div className="page-card">
        <div className="table-wrap">
          <p>
            조회된 수: <strong>{totalCount}</strong>개
          </p>
          <table>
            <TableSortHeader
              sortBy={sortBy}
              sortOrder={sortOrder}
              handleRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <tbody>
              {totalCount === 0 && (
                <tr>
                  <td colSpan={headCells.length}>검색 결과가 없습니다.</td>
                </tr>
              )}
              {myEvents.map((item, index) => (
                <tr key={index}>
                  <td>{totalCount - (page - 1) * limit - index}</td>
                  <td>{item.eventName}</td>
                  <td>{item.place}</td>
                  <td>{item.period}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalCount > 0 && (
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default ClientEvent;
