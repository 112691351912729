import { create } from 'zustand';

const useModalStore = create((set) => ({
  zstIsOpen: false,
  zstModalName: null,
  zstProps: {},
  zstOpenModal: ({ modalName, props }) => set({ zstIsOpen: true, zstModalName: modalName, zstProps: props }),
  zstCloseModal: () => set({ zstIsOpen: false, zstModalName: null, props: {} }),
}));

export default useModalStore;
