import { Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import HelpRepository from 'repositories/HelpRepository';
import { CommonConstant } from 'constants/code.constant';
import { ErrorCodeConstant } from 'constants/errorCode.constant';
import CommonUtil from 'utils/common.util';

const CodeTimer = React.memo(({ setIsCodeTimer }) => {
  const [time, setTime] = useState(180);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const tick = setInterval(() => {
      setTime((time) => time - 1);
    }, 1000);

    if (time === 0) {
      clearInterval(tick);
      setIsCodeTimer(false);
    }
    return () => clearInterval(tick);
  }, [time]);

  return <label>{formatTime(time)}</label>;
});

const ResetPasswordForm = React.memo(({ email }) => {
  const navigation = useNavigate();

  const defaultValues = {
    password: '',
    passwordConfirm: '',
  };

  let schemeObject = {
    password: yup.string().required('비밀번호를 입력해 주세요'),
    passwordConfirm: yup
      .string()
      .required('비밀번호를 확인해 주세요')
      .oneOf([yup.ref('password'), null], '비밀번호가 일치하지 않습니다'),
  };

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const resetPassword = ({ password }) => {
    if (!window.confirm('변경하시겠습니까?')) {
      return;
    }

    HelpRepository.resetPassword({ email, password })
      .then(async (res) => {
        if (res.result === CommonConstant.OK) {
          alert('변경되었습니다');
          navigation('/login');
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(resetPassword)}>
      <h2>비밀번호 변경</h2>
      <br />
      <Controller
        name={'password'}
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, value, onChange } }) => (
          <>
            <label>비밀번호</label>
            <input
              type="password"
              placeholder="비밀번호"
              autoComplete="off"
              onBlur={onBlur}
              value={value}
              onChange={onChange}
            />
            {errors.password && <p className="error">{errors.password.message}</p>}
          </>
        )}
      />
      <Controller
        name={'passwordConfirm'}
        control={control}
        rules={{ required: true }}
        render={({ field: { onBlur, value, onChange } }) => (
          <>
            <label>비밀번호 확인</label>
            <input
              type="password"
              placeholder="비밀번호 확인"
              autoComplete="off"
              onBlur={onBlur}
              value={value}
              onChange={onChange}
            />
            {errors.passwordConfirm && <p className="error">{errors.passwordConfirm.message}</p>}
          </>
        )}
      />
      <button type="submit">비밀번호 변경</button>
    </form>
  );
});

const PwInquiry = () => {
  // 인증 코드 전송 여부
  const [isSendingCode, setIsSendingCode] = useState(false);

  // 타이머 실행 여부
  const [isCodeTimer, setIsCodeTimer] = useState(false);

  // 인증 완료 여부
  const [isVerifyCode, setIsVerifyCode] = useState(false);

  const defaultValues = {
    email: '',
    authCode: '',
  };

  const schemeObject = {
    email: yup.string().trim().required('담당자 이메일을 입력해 주세요'),
  };

  const schema = yup.object().shape(schemeObject);

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const sendResetPasswordCode = ({ email }) => {
    if (isVerifyCode) {
      return;
    }

    if (isSendingCode) {
      return false;
    }

    setIsSendingCode(true);

    setIsCodeTimer(false);

    HelpRepository.sendResetPasswordCode({ email })
      .then(async (res) => {
        if (res.result === CommonConstant.OK) {
          alert('인증코드 6자리를 전송하였습니다');
          setIsCodeTimer(true);
        }

        if (res.result === CommonConstant.ERROR) {
          // 계정이 일치하지 않는 경우
          if (res.errorCode === ErrorCodeConstant.ERROR_CODE.NOT_USER) {
            setError('email', { message: '해당 이메일로 가입한 정보가 없습니다' });
          }
        }
      })
      .catch(CommonUtil.repoErrFunc)
      .finally(() => setIsSendingCode(false));
  };

  const verifyResetPasswordCode = ({ email, authCode }) => {
    if (isVerifyCode) {
      return;
    }

    if (!isCodeTimer) {
      setError('authCode', { message: '인증시간이 만료되었습니다' });
      return;
    }

    if (!authCode) {
      setError('authCode', { message: ' 인증코드를 입력해 주세요' });
      return;
    }

    HelpRepository.verifyResetPasswordCode({ email, authCode })
      .then(async (res) => {
        if (res.result === CommonConstant.OK) {
          setIsCodeTimer(false);
          setIsVerifyCode(true);
        }

        if (res.result === CommonConstant.ERROR) {
          // 인증코드가 유효하지 않음
          if (res.errorCode === ErrorCodeConstant.ERROR_CODE.RESET_PASSWORD_EMAIL_CODE_INVALID) {
            setError('authCode', { message: '인증코드가 유효하지 않습니다' });
          }
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  return (
    <div className="login-wrap">
      <div className="login-container">
        <h2>이메일 인증</h2>
        <br />
        <form noValidate autoComplete="off" onSubmit={handleSubmit(sendResetPasswordCode)}>
          <Controller
            name={'email'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <>
                <label>담당자 이메일</label>
                <div className="box">
                  <input
                    placeholder="담당자 이메일"
                    maxLength={100}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    disabled={isVerifyCode}
                  />
                  {isSendingCode ? (
                    <button type="button">메일 전송중...</button>
                  ) : (
                    <button type="submit">인증코드 전송</button>
                  )}
                </div>
                {errors.email && <p className="error">{errors.email.message}</p>}
              </>
            )}
          />
        </form>
        {isCodeTimer && <CodeTimer setIsCodeTimer={setIsCodeTimer} />}
        <form noValidate autoComplete="off" onSubmit={handleSubmit(verifyResetPasswordCode)}>
          <Controller
            name={'authCode'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <>
                <div className="box">
                  <input
                    placeholder="인증코드 6자리 입력"
                    maxLength={6}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    disabled={!isCodeTimer}
                  />
                  <button className="check" type="submit">
                    인증코드 확인
                  </button>
                </div>
                {errors.authCode && <p className="error">{errors.authCode.message}</p>}
              </>
            )}
          />
        </form>
        <br />
        {isVerifyCode && (
          <>
            <ResetPasswordForm email={getValues('email')} />
            <br />
            <br />
          </>
        )}
        <div className="bottom-wrap">
          <Link to={'/signup'}>회원가입</Link>
          <Link to={'/help/email-inquiry'}>이메일 찾기</Link>
          <Link to={'/login'}>로그인</Link>
        </div>
      </div>
    </div>
  );
};

export default PwInquiry;
