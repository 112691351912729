import { useQuery } from '@tanstack/react-query';
import { CommonConstant } from 'constants/code.constant';
import ScheduleRepository from 'repositories/ScheduleRepository';
import CommonUtil from 'utils/common.util';

const useSchedule = () => {
  const useGetScheduleList = ({ selectedDate }) => {
    const getScheduleList = () => {
      return ScheduleRepository.getScheduleList({ selectedDate })
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const scheduleListListQuery = useQuery({
      queryKey: ['scheduleList', selectedDate],
      queryFn: getScheduleList,
      enabled: !!selectedDate,
    });

    return {
      list: scheduleListListQuery.data?.list || [],
      totalCount: scheduleListListQuery.data?.totalCount || 0,
      refetch: scheduleListListQuery.refetch,
    };
  };

  const useGetMyScheduleList = ({ page = 1, limit = 10, selectedDate }) => {
    const getMyScheduleList = () => {
      const params = {
        offset: (page - 1) * limit,
        limit,
      };

      if (selectedDate) {
        params.selectedDate = selectedDate;
      }

      return ScheduleRepository.getMyScheduleList(params)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const myScheduleListQuery = useQuery({
      queryKey: ['myScheduleList', { page }],
      queryFn: getMyScheduleList,
    });

    return {
      list: myScheduleListQuery.data?.list || [],
      totalCount: myScheduleListQuery.data?.totalCount || 0,
      refetch: myScheduleListQuery.refetch,
    };
  };

  const useGetSchedule = (scheduleId) => {
    const getSchedule = () => {
      return ScheduleRepository.getSchedule(scheduleId)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const scheduleQuery = useQuery({
      queryKey: ['schedule', scheduleId],
      queryFn: getSchedule,
      enabled: !!scheduleId,
    });

    return {
      scheduleInfo: scheduleQuery.data || null,
      refetch: scheduleQuery.refetch,
    };
  };

  return { useGetScheduleList, useGetMyScheduleList, useGetSchedule };
};

export default useSchedule;
