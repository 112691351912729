import TableSortHeader from 'components/table/TableSortHeader';
import { ReservationStatusConstant, ReservationStatusNameConstant } from 'constants/code.constant';
import useConsultingAssign from 'hooks/useConsultingAssign';
import useReservation from 'hooks/useReservation';
import useSortTable from 'hooks/useSortTable';
import MainLayout from 'layouts/MainLayout';
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import { useNavigate, useSearchParams } from 'react-router-dom';

const headCells = [
  { label: '#', width: '5%' },
  { label: '기업명' },
  { label: '담당자명', width: '15%' },
  { label: '예약 일자', width: '15%' },
  { label: '예약 시간', width: '15%' },
  { label: '총 시간', width: '10%' },
  { label: '상태', width: '10%' },
  { label: '비고', width: '10%' },
];

const selectOptionList = {
  status: [
    {
      value: ReservationStatusConstant.PENDING,
      label: ReservationStatusNameConstant[ReservationStatusConstant.PENDING],
    },
    {
      value: ReservationStatusConstant.APPROVED,
      label: ReservationStatusNameConstant[ReservationStatusConstant.APPROVED],
    },
    {
      value: ReservationStatusConstant.FINISHED,
      label: ReservationStatusNameConstant[ReservationStatusConstant.FINISHED],
    },
    {
      value: ReservationStatusConstant.CANCELLED,
      label: ReservationStatusNameConstant[ReservationStatusConstant.CANCELLED],
    },
  ],
};

const ConsultantConsultingDetail = () => {
  const navigation = useNavigate();

  const [searchParams] = useSearchParams();
  const consultantAssignId = searchParams.get('consultantAssignId');

  const [page, setPage] = useState(1);
  const limit = 10;

  const { sortBy, sortOrder, handleRequestSort } = useSortTable();

  const { useGetConsultingAssign } = useConsultingAssign();
  const { consultingAssignInfo } = useGetConsultingAssign(consultantAssignId);

  const { useGetReservationList, changeReservationStatus } = useReservation();
  const { list: reservations, totalCount, refetch } = useGetReservationList({ page, limit, consultantAssignId });

  const handlePageChange = (value) => {
    setPage(value);
  };

  const onChangeReservationStatus = (reservationId, status) => {
    if (!window.confirm('상태를 변경하시겠습니까?')) {
      return;
    }

    changeReservationStatus({
      reservationId,
      status,
      changeSuccessCallback: () => {
        refetch();
        alert('상태가 변경되었습니다');
      },
    });
  };

  const handleReportLink = (reservationId, consultingReportId, status) => {
    if (status !== ReservationStatusConstant.FINISHED) {
      alert('컨설팅 예약 상태가 완료인 경우에만 작성이 가능합니다');
      return;
    }

    if (consultingReportId) {
      navigation(
        `/consultant/consulting/detail/report?reservationId=${reservationId}&consultingReportId=${consultingReportId}`,
      );
    } else {
      navigation(`/consultant/consulting/detail/report?reservationId=${reservationId}`);
    }
  };

  return (
    <MainLayout>
      <div className="page-header">컨설팅 정보</div>
      <div className="page-card">
        <div className="table-search-wrap detail-form-wrap">
          <div>
            <label>컨설팅명</label>
            <input type="text" value={consultingAssignInfo?.consultingName || ''} disabled />
          </div>
          <div>
            <label>최대 컨설팅 가능 횟수</label>
            <input type="text" value={`${consultingAssignInfo?.requiredScheduleCount}번` || ''} disabled />
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="page-header">컨설팅 예약 목록</div>
      <div className="page-card">
        <div className="table-wrap">
          <p>
            조회된 수: <strong>{totalCount}</strong>개
          </p>
          <table>
            <TableSortHeader
              sortBy={sortBy}
              sortOrder={sortOrder}
              handleRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <tbody>
              {totalCount === 0 && (
                <tr>
                  <td colSpan={headCells.length}>검색 결과가 없습니다.</td>
                </tr>
              )}
              {reservations.map((item, index) => (
                <tr key={index}>
                  <td>{totalCount - (page - 1) * limit - index}</td>
                  <td>{item.company}</td>
                  <td>{item.name}</td>
                  <td>{item.date}</td>
                  <td>{item.time}</td>
                  <td>{item.totalTime}</td>
                  <td className="table-select">
                    <select
                      value={item.status}
                      onChange={(e) => onChangeReservationStatus(item.reservationId, e.target.value)}
                    >
                      {selectOptionList.status.map((status, index) => (
                        <option key={index} value={status.value}>
                          {status.label}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <button
                      className={`btn ${item.status === ReservationStatusConstant.FINISHED ? 'edit-btn' : 'disabled-btn'}`}
                      onClick={() => handleReportLink(item.reservationId, item.consultingReportId, item.status)}
                    >
                      보고서 작성
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalCount > 0 && (
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default ConsultantConsultingDetail;
