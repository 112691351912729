import CalendarModal from 'components/modals/CalendarModal';
import ProjectBudgetEditModal from 'components/modals/ProjectBudgetEditModal';
import ProjectClientSelectModal from 'components/modals/ProjectClientSelectModal';
import ProjectItemClientSelectModal from 'components/modals/ProjectItemClientSelectModal';
import ProjectItemConsultantSelectModal from 'components/modals/ProjectItemConsultantSelectModal';
import ProjectItemManagerSelectModal from 'components/modals/ProjectItemManagerSelectModal';

export const ModalNameConstant = {
  PROJECT_BUDGET_EDIT: 'project-budget-edit',
  PROJECT_CLIENT_SELECT: 'project-client-select',
  PROJECT_ITEM_MANAGER_SELECT: 'project-item-manager-select',
  PROJECT_ITEM_CONSULTANT_SELECT: 'project-item-consultant-select',
  PROJECT_ITEM_CLIENT_SELECT: 'project-item-client-select',
  CALENDAR: 'calendar',
};

export const ModalConstant = [
  {
    modalName: ModalNameConstant.PROJECT_BUDGET_EDIT,
    element: <ProjectBudgetEditModal />,
  },
  {
    modalName: ModalNameConstant.PROJECT_CLIENT_SELECT,
    element: <ProjectClientSelectModal />,
  },
  {
    modalName: ModalNameConstant.PROJECT_ITEM_MANAGER_SELECT,
    element: <ProjectItemManagerSelectModal />,
  },
  {
    modalName: ModalNameConstant.PROJECT_ITEM_CONSULTANT_SELECT,
    element: <ProjectItemConsultantSelectModal />,
  },
  {
    modalName: ModalNameConstant.PROJECT_ITEM_CLIENT_SELECT,
    element: <ProjectItemClientSelectModal />,
  },
  {
    modalName: ModalNameConstant.CALENDAR,
    element: <CalendarModal />,
  },
];
