import ConsultantConsultingList from 'pages/consultant/consulting';
import ConsultantConsultingSchedule from 'pages/consultant/consulting-schedule';
import ConsultantConsultingDetail from 'pages/consultant/consulting/detail';
import ConsultantConsultingReport from 'pages/consultant/consulting/report';
import ConsultantDashboard from 'pages/consultant/dashboard';

export const ConsultantRoute = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    element: <ConsultantDashboard />,
  },
  {
    sectionTitle: '컨설팅',
  },
  {
    path: '/schedule',
    title: '컨설팅 일정',
    element: <ConsultantConsultingSchedule />,
  },
  {
    path: '/consulting',
    title: '컨설팅 목록',
    element: <ConsultantConsultingList />,
  },
  {
    path: '/consulting/detail',
    element: <ConsultantConsultingDetail />,
  },
  {
    path: '/consulting/detail/report',
    element: <ConsultantConsultingReport />,
  },
];
