import { CommonConstant } from 'constants/code.constant';
import React, { useEffect, useState } from 'react';
import ReservationTimeSlotRepository from 'repositories/ReservationTimeSlotRepository';
import CommonUtil from 'utils/common.util';

// 30분 단위로 시간대 생성
const generateHalfHourlyTimeSlotList = (startHour = 6, endHour = 24) => {
  const timeSlots = [];

  for (let hour = startHour; hour < endHour; hour++) {
    const startHourStr = hour.toString().padStart(2, '0');
    const nextHourStr = (hour + 1).toString().padStart(2, '0');

    // 30분 단위로 추가
    timeSlots.push(`${startHourStr}:00 ~ ${startHourStr}:30`);
    timeSlots.push(`${startHourStr}:30 ~ ${nextHourStr}:00`);
  }

  return timeSlots;
};

const ConsultantReservationTimeSlot = React.memo(({ selectedDate, existSelectedTimeSlots = [], refetchTimeSlots }) => {
  const timeSlots = generateHalfHourlyTimeSlotList();

  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [startIndex, setStartIndex] = useState(null);

  // 시간대 선택/해제
  const toggleSlotSelection = (time) => {
    const findSlot = existSelectedTimeSlots.find((item) => item.fullTime === time);

    if (findSlot?.isReservation === CommonConstant.YES_NUMBER) {
      alert(`${time} 시간대는 이미 컨설팅 예약이 존재합니다`);
      return;
    }

    setSelectedTimeSlots((prev) => (prev.includes(time) ? prev.filter((slot) => slot !== time) : [...prev, time]));
  };

  // 드래그 시작
  const handleMouseDown = (e, index, time) => {
    e.preventDefault();
    setIsDragging(true);
    setStartIndex(index);
    toggleSlotSelection(time);
  };

  // 드래그 중
  const handleMouseEnter = (time) => {
    if (isDragging && startIndex !== null) {
      toggleSlotSelection(time);
    }
  };

  // 드래그 종료
  const handleMouseUp = () => {
    setIsDragging(false);
    setStartIndex(null);
  };

  const saveReservationTimeSlot = () => {
    if (!window.confirm('저장하시겠습니까?')) {
      return;
    }

    ReservationTimeSlotRepository.saveReservationTimeSlot({ selectedDate, selectedTimeSlots })
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          alert('저장하였습니다');
          refetchTimeSlots();
        } else {
          console.error(res);
          alert('알 수 없는 에러 코드');
        }
      })
      .catch(CommonUtil.repoErrFunc);
  };

  useEffect(() => {
    setSelectedTimeSlots(existSelectedTimeSlots.map((item) => item.fullTime));
  }, [existSelectedTimeSlots]);

  return (
    <div className="reservation-time-slot-wrap unselectable" onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
      <div className="reservation-time-slot-header">
        <p>컨설팅 시간대 등록</p>
        <br />
        <p>{selectedDate}</p>
      </div>
      <div className="reservation-time-slot-body">
        {timeSlots.map((time, index) => (
          <div
            key={time}
            className={`item ${selectedTimeSlots.includes(time) ? 'selected' : ''}`}
            onMouseDown={(e) => handleMouseDown(e, index, time)}
            onMouseEnter={() => handleMouseEnter(time)}
          >
            {time}
          </div>
        ))}
      </div>
      <div className="reservation-time-slot-bottom">
        <button className="btn add-btn" onClick={saveReservationTimeSlot}>
          저장
        </button>
      </div>
    </div>
  );
});

export default ConsultantReservationTimeSlot;
