import Calendar from 'components/calendar';

const CalendarModal = (props) => {
  return (
    <div className="calendar-modal">
      <Calendar {...props} />
    </div>
  );
};

export default CalendarModal;
