import { API_CONSULTING_ASSIGN_ROUTE } from 'constants/api.constant';
import AxiosUtil from 'utils/axios.util';

const ConsultingAssignRepository = {
  getConsultingAssignList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_CONSULTING_ASSIGN_ROUTE}/list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  getClientConsultingAssignList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_CONSULTING_ASSIGN_ROUTE}/client/list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  getConsultantConsultingAssignList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_CONSULTING_ASSIGN_ROUTE}/consultant/list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  getConsultingAssign(consultantAssignId) {
    try {
      return AxiosUtil.get(
        { url: `${API_CONSULTING_ASSIGN_ROUTE}/${consultantAssignId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  saveConsultingAssign(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_CONSULTING_ASSIGN_ROUTE}`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  removeConsultingAssign(consultantAssignId) {
    try {
      return AxiosUtil.delete(
        { url: `${API_CONSULTING_ASSIGN_ROUTE}/${consultantAssignId}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default ConsultingAssignRepository;
