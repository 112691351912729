import Calendar from 'components/calendar';
import ConsultantReservationTimeSlot from 'components/reservation/ConsultantReservationTimeSlot';
import { format } from 'date-fns';
import useReservationTimeSlot from 'hooks/useReservationTimeSlot';
import MainLayout from 'layouts/MainLayout';
import { useState } from 'react';

const ConsultantConsultingSchedule = () => {
  const nowDate = format(new Date(), 'yyyy-MM-dd');

  const [selectedDate, setSelectedDate] = useState(nowDate);

  const handleSelectDateCallback = (date) => {
    setSelectedDate(date.join());
  };

  const { useGetReservationTimeSlotList } = useReservationTimeSlot();
  const {
    list: existSelectedTimeSlots,
    schedules,
    refetch: refetchTimeSlots,
  } = useGetReservationTimeSlotList({
    selectedDate,
  });

  return (
    <MainLayout>
      <div className="page-header">컨설팅 일정</div>
      <div className="page-card">
        <div className="consultant-schedule-wrap">
          <div
            style={{
              width: '65%',
            }}
          >
            <Calendar
              selectDateCallback={handleSelectDateCallback}
              selectedCalendarDates={[selectedDate]}
              schedules={schedules}
            />
          </div>
          <ConsultantReservationTimeSlot
            selectedDate={selectedDate}
            existSelectedTimeSlots={existSelectedTimeSlots}
            refetchTimeSlots={refetchTimeSlots}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default ConsultantConsultingSchedule;
