import React, { useMemo } from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

const CustomEditor = React.memo(({ value, onChange }) => {
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: '1' }],
          [{ font: [] }],
          [{ size: [] }],
          ['bold'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          // 글꼴 색상 및 배경 색상
          [{ color: [] }, { background: [] }],
          // 텍스트 정렬
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          // 서식 지우기,
          ['clean'],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    [],
  );

  const formats = ['header', 'font', 'size', 'bold', 'list', 'color', 'background', 'align'];

  return <ReactQuill theme="snow" value={value} onChange={onChange} modules={modules} formats={formats} />;
});

export default CustomEditor;
