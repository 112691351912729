import { addDays } from 'date-fns';
import cookie from 'react-cookies';
import { API_AUTH_ROUTE } from 'constants/api.constant';
import { CommonConstant, TokenConstant } from 'constants/code.constant';
import AxiosUtil from 'utils/axios.util';

const AuthRepository = {
  /**
   * 회원가입
   * @param {Object} data
   * @returns
   */
  signup(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_AUTH_ROUTE}/signup`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  /**
   * 로그인
   * @param {Object} data
   * @returns
   */
  login(data) {
    try {
      return AxiosUtil.post(
        {
          url: `${API_AUTH_ROUTE}/login`,
          submitData: data,
        },
        (res) => {
          if (res.result === CommonConstant.OK) {
            cookie.save(TokenConstant.ACCESS_TOKEN_COOKIE, res.data.accessToken, {
              path: '/',
              expires: addDays(new Date(), 2),
            });
            cookie.save(TokenConstant.REFRESH_TOKEN_COOKIE, res.data.refreshToken, {
              path: '/',
              expires: addDays(new Date(), 20),
            });
          }
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  /**
   * 로그아웃
   * @returns
   */
  logout() {
    try {
      return AxiosUtil.post(
        {
          url: `${API_AUTH_ROUTE}/logout`,
          submitData: {},
        },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  /**
   * 토큰 확인
   * @returns
   */
  checkToken() {
    try {
      return AxiosUtil.get(
        { url: `${API_AUTH_ROUTE}/token`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err?.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  /**
   * 엑세스 토큰 재발급
   * @returns
   */
  renewRefreshToken() {
    try {
      return AxiosUtil.put(
        { url: `${API_AUTH_ROUTE}/token`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
        'renew',
      );
      // escape infinite loop for renew token
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default AuthRepository;
