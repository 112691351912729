export const ErrorCodeConstant = {
  ERROR_CODE: {
    DUPLICATE_USER: 40001,
    NOT_USER: 40002,
    TOKEN_EXPIRED: 40005,
    INVALID_TOKEN: 40005,
    SIGNUP_PENDING_STATUS: 40008,
    DUPLICATE_COMPANY: 40009,
    EXCEEDED_REQUIRED_SCHEDULE_COUNT: 40010,
    RESET_PASSWORD_EMAIL_CODE_INVALID: 40011,
    CANNOT_DELETE: 20012,
  },
};
