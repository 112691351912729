import React from 'react';
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';

const SortLabel = ({ active, direction, onClick, children }) => {
  return (
    <div className="sort-box" onClick={onClick}>
      {children}
      {active && <TiArrowSortedUp className={`arrow ${direction === 'asc' ? 'up' : 'down'}`} size={'20'} />}
      {!active && <TiArrowSortedDown opacity={0.3} size={'20'} />}
    </div>
  );
};

const TableSortHeader = ({ sortBy, sortOrder, handleRequestSort, headCells }) => {
  const createSortHandler = (property) => () => {
    handleRequestSort(property);
  };

  return (
    <thead>
      <tr>
        {headCells.map((cell, index) => (
          <td key={index} style={{ width: cell.width, textAlign: cell.align }}>
            {cell.isSort ? (
              <SortLabel
                active={sortBy === cell.id}
                direction={sortBy === cell.id ? sortOrder : 'asc'}
                onClick={createSortHandler(cell.id)}
              >
                {cell.label}
              </SortLabel>
            ) : (
              cell.label
            )}
          </td>
        ))}
      </tr>
    </thead>
  );
};

export default TableSortHeader;
