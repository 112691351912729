import ClientConsultingList from 'pages/client/consulting';
import ClientConsultingDetail from 'pages/client/consulting/detail';
import ClientDashboard from 'pages/client/dashboard';
import ClientEvent from 'pages/client/event';

export const ClientRoute = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    element: <ClientDashboard />,
  },
  {
    sectionTitle: '컨설텅',
  },
  {
    path: '/consulting',
    title: '컨설팅 목록',
    element: <ClientConsultingList />,
  },
  {
    path: '/consulting/detail',
    element: <ClientConsultingDetail />,
  },
  {
    sectionTitle: '행사',
  },
  {
    path: '/event',
    title: '행사 목록',
    element: <ClientEvent />,
  },
];
